import { ApiHelper } from './apiHelper';

export class PaymentApi {

    validateDirectDebit(directDebitDetails, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = '/api/payment/directdebit';
        new ApiHelper().postDataWithResponse(dataUrl, directDebitDetails, callback, failureCallback, headers);
    }

    singlePayment(policy, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var url = '/api/payment/singlepayment';
        new ApiHelper().postDataWithResponse(url, policy, callback, failureCallback, headers);
    }

    getSinglePaymentDetails(sagePayTransactionId, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = `/api/payment/singlepayments/${sagePayTransactionId}`;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }
}