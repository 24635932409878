import React from 'react'
import PropTypes from 'prop-types';
import { Button } from '../../common/button/button';
import { CheckBoxInput } from '../../common/checkBoxInput/checkBoxInput';
import { TextInput } from '../../common/textInput/textInput';
import { SortCodeInput } from '../../common/sortCodeInput';
import DirectDebitLogo from '../../../images/directdebitlogoSm.png'
import { NumberInput } from '../../common/numberInput/numberInput';
import { SelectInput } from '../../common/selectInput/selectInput';
import './purchasePage.scss';

const DirectDebitForm = ({ brandAdminText, statementCompany, loading, onSubmitDirectDebit, onChangeDetail, directDebitDetails, directDebitErrors, onPaymentDayChange, selectedPreferredPaymentDay, lookUpDays, preferedPaymentDay }) => {

    const onToggleAuthorisation = (checkState) => {
        const changeEvent = { "target": { "value": checkState } };
        onChangeDetail(changeEvent, 'isAuthorised');
    }

    const paymentDefault = lookUpDays.preferedPaymentDay;

    return (
        <div className="card card-direct-debit mb-2">
            <div className="card-header card-header-direct-debit">
                Please provide your bank details
            </div>
            <div className="card-body">
                <div className="d-flex flex-row">
                    <div>
                        <div className="direct-debit-logo-container">
                            <img src={DirectDebitLogo} alt="direct debit logo" />
                            <span className="small flt-r">Originator Number 830453</span>
                        </div>
                        <p className="card-text">
                            All the normal Direct Debit safeguards and guarantees apply. No changes in the amount, date or frequency to be debited can be made
                            without notifying you at least 10 working days in advance of your account being debited.
                            In the event of any error, you are entitled to an immediate refund from your Bank or Building Society.
                            You have the right to cancel a Direct Debit Instruction at any time simply by writing to your Bank or Building Society and sending a copy to
                            <span className="ml-1">{brandAdminText}</span>.
                        </p>
                    </div>
                </div>
                <hr />
                <form>
                    <div className="form-row">
                        <div className="col">
                            <CheckBoxInput
                                name='authorised-check'
                                valueName='authorised-check'
                                haslabel
                                label="Please confirm that you are fully authorised to setup a Direct Debit on this account"
                                horizontalLayout
                                onCheckboxChange={(checkState) => onToggleAuthorisation(checkState)}
                                value={directDebitDetails.isAuthorised}
                                error={directDebitErrors.isAuthorised}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col">
                            <TextInput
                                haslabel
                                name="account-holder-name"
                                label="Account holder name"
                                onChange={(e) => onChangeDetail(e, 'accountHolderName')}
                                value={directDebitDetails.accountHolderName}
                                error={directDebitErrors.accountHolderName}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col">
                            <TextInput
                                haslabel
                                name="bank-name"
                                label="Bank or building society name"
                                onChange={(e) => onChangeDetail(e, 'bankName')}
                                value={directDebitDetails.bankName}
                                error={directDebitErrors.bankName}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col">
                            <SortCodeInput
                                label='Sort Code'
                                value1={directDebitDetails.sortCode1}
                                value2={directDebitDetails.sortCode2}
                                value3={directDebitDetails.sortCode3}
                                error={directDebitErrors.sortCode}
                                onChange={onChangeDetail}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col">
                            <NumberInput
                                haslabel
                                name="account-number"
                                label="Account number"
                                onChange={(e) => onChangeDetail(e, 'accountNumber')}
                                value={directDebitDetails.accountNumber}
                                error={directDebitErrors.accountNumber}
                                decimalPlaces={0}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col">
                            <SelectInput label="Preferred payment day"
                                haslabel
                                name="days"
                                loadingText='Select Preferred Payment Day'
                                value={selectedPreferredPaymentDay}
                                options={lookUpDays}
                                onChange={onPaymentDayChange}
                                forceDefault
                                defaultIfOneOption
                                addDefaultOption
                                defaultText={paymentDefault}
                                error={directDebitErrors.preferredPaymentDay}
                            />
                        <p className="text-explanation">This will be the preferred day, but the payment could be collected later if it is not a working day of the payment month.</p>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col d-flex justify-content-end">
                            <Button
                                onClick={onSubmitDirectDebit}
                                text='Submit'
                                name='direct-debit-button'
                                className='btn button-theme'
                                waitValue={loading}
                                waitText='Please wait...'
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="card-footer">
                <span className="flt-n clr-n">
                    Please pay <span>{brandAdminText}</span> from the account detailed in this instruction subject to the safeguards assured by the Direct Debit Guarantee. I understand that this instruction may remain with
                    <span className="ml-1">
                        {brandAdminText}</span> and if so, details will be passed electronically to my Bank/Building Society. Confirmation of your Direct Debit setup will be sent to you by post within 3 working days (and no later than 10 working days before the first collection). The company name that will appear against the Direct Debit on the account holder's bank statement will be </span><span>{statementCompany}</span><span>.
                </span>
            </div>
        </div >
    );
}

DirectDebitForm.propTypes = {
    brandAdminText: PropTypes.string.isRequired,
    statementCompany: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmitDirectDebit: PropTypes.func.isRequired,
    onChangeDetail: PropTypes.func.isRequired,
    directDebitDetails: PropTypes.object.isRequired,
    directDebitErrors: PropTypes.object.isRequired
}

export default DirectDebitForm;
