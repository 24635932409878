import React, { useReducer, useEffect } from 'react';
import { ClientDateHandler } from '../../../classes/clientDateHandler';
import { CommonValidation } from '../../common/commonValidation/commonValidation';
import { SelectInput } from '../../common/selectInput/selectInput'
import { Currency } from '../../common/enums';
import { vehicleLookup } from '../../../classes/enums';
import { VehicleApi } from '../../../api/vehicleApi';
import VehicleDetails from './vehicleDetails';
import VehicleDetailsReducer from './vehicleDetailsReducer';
import { useBasketState } from '../../../context/basketProvider';

const initialState = {
    vehicleDetailErrors: {},
    FoundVehicleOnSearch: true,
    finding: false,
    vehicleTypes: [],
    vehicleMakes: [],
    vehicleModels: [],
    vehicleFuelTypes: [],
    vehicleEngineSizes: [],
    vehicleModelTrims: [],
    manualQuerySelections: {
        firstRegistrationDate: '',
        vehicleTypeId: SelectInput.NullValue(),
        vehicleMakeId: SelectInput.NullValue(),
        vehicleModelId: SelectInput.NullValue(),
        vehicleFuelTypeId: SelectInput.NullValue(),
        vehicleEngineSize: SelectInput.NullValue(),
        vehicleModelTrimId: SelectInput.NullValue()
    }
}

const ManageVehicleDetails = ({ vehicleDetails, onVehicleDetailsChange, nextPage, showAmendVehicleWarning, onBulkVehicleDetailChange }) => {

    const { bearerToken } = useBasketState();
    const [state, dispatch] = useReducer(VehicleDetailsReducer, initialState)
    const { manualQuerySelections, vehicleDetailErrors, finding, vehicleTypes, vehicleMakes, vehicleModels, vehicleFuelTypes,
        vehicleEngineSizes, vehicleModelTrims, FoundVehicleOnSearch } = state;

    //Cleanup due to empty look ups
    useEffect(() => {
        if (vehicleDetails.isManualMode) {
            const detailsToReset = [
                { value: SelectInput.NullValue(), key: 'engineSize' },
                { value: SelectInput.NullValue(), key: 'fuelType' },
                { value: SelectInput.NullValue(), key: 'make' },
                { value: SelectInput.NullValue(), key: 'model' },
                { value: SelectInput.NullValue(), key: 'modelType' },
                { value: SelectInput.NullValue(), key: 'vehicleType' },
                { value: false, key: 'isManualMode' },
                { value: false, key: 'showVehicleControls' },
                { value: '', key: 'registration' },
                { value: null, key: 'firstRegistrationDate' },
                { value: null, key: 'purchaseDate' },
                { value: '', key: 'currentMileage' },
            ];

            onBulkVehicleDetailChange(detailsToReset);
        }
    }, []);

    //Refactor into CDH
    const mapDateForServer = (dateJson) => {
        var cdh = new ClientDateHandler();
        cdh.parseJSON(dateJson);

        var localDate = cdh.getLocalDate();
        return localDate;
    }

    //Refactor into a data superclass
    const getClientDateString = (jsonDate) => {

        if (CommonValidation.isEmpty(jsonDate))
            return '';

        if (CommonValidation.isDateNull(jsonDate, true))
            return '';

        const clientDate = mapDateForServer(jsonDate).toISOString();

        return clientDate;
    }

    const onChangeDetail = (e, key) => {
        const value = e.target.value;
        onVehicleDetailsChange(value, key);
    }

    const onChangeDetailWithLookup = (e, key, lookupValue) => {
        let vehicleValue = e.target.value;
        resetVehicleDetailErrors(vehicleDetailErrors.registration);

        switch (lookupValue) {
            case vehicleLookup.TYPES:
                const dateValue = getClientDateString(e.target.value);
                if (dateValue != '' && dateValue != null) {
                    dispatch({ type: 'update-manual-query', property: 'firstRegistrationDate', value: dateValue });

                    new VehicleApi().getVehicleTypes(dateValue, bearerToken, successCallbackVehicleTypes, failureCallbackVehicleInfo);
                }
                break;
            case vehicleLookup.MAKES:
                const vehicleTypeId = e.target.value;
                if (vehicleTypeId >= 1 && !SelectInput.isNullValue(vehicleTypeId)) {
                    if (vehicleTypeId != manualQuerySelections.vehicleTypeId) {
                        const detailsToReset = [
                            { value: SelectInput.NullValue(), key: 'engineSize' },
                            { value: SelectInput.NullValue(), key: 'fuelType' },
                            { value: SelectInput.NullValue(), key: 'make' },
                            { value: SelectInput.NullValue(), key: 'model' },
                            { value: SelectInput.NullValue(), key: 'modelType' },
                            { value: SelectInput.NullValue(), key: 'vehicleType' }
                        ];

                        onBulkVehicleDetailChange(detailsToReset);

                        dispatch({ type: 'reset-type-neutral' });
                    }

                    new VehicleApi().getVehicleMakes(manualQuerySelections.firstRegistrationDate, vehicleTypeId, bearerToken,
                        successCallbackVehicleMakes, failureCallbackVehicleInfo);
                }
                dispatch({ type: 'update-manual-query', property: 'vehicleTypeId', value: vehicleTypeId });

                break;
            case vehicleLookup.MODEL:
                let vehicleMakeId = e.target.value;
                if (!SelectInput.isNullValue(vehicleMakeId)) {
                    vehicleValue = e.target.options[e.target.selectedIndex].text;

                    if (vehicleMakeId != manualQuerySelections.vehicleMakeId) {
                        const detailsToReset = [
                            { value: SelectInput.NullValue(), key: 'engineSize' },
                            { value: SelectInput.NullValue(), key: 'fuelType' },
                            { value: SelectInput.NullValue(), key: 'make' },
                            { value: SelectInput.NullValue(), key: 'model' },
                            { value: SelectInput.NullValue(), key: 'modelType' },
                        ];

                        onBulkVehicleDetailChange(detailsToReset);
                        dispatch({ type: 'reset-make-neutral' });
                    }

                    new VehicleApi().getVehicleModels(manualQuerySelections.firstRegistrationDate, manualQuerySelections.vehicleTypeId, vehicleMakeId,
                        bearerToken, successCallbackVehicleModels, failureCallbackVehicleInfo);
                }
                dispatch({ type: 'update-manual-query', property: 'vehicleMakeId', value: vehicleMakeId });

                break;
            case vehicleLookup.FUELTYPE:
                let vehicleModelId = e.target.value;
                if (!SelectInput.isNullValue(vehicleModelId)) {
                    vehicleValue = e.target.options[e.target.selectedIndex].text;

                    if (vehicleModelId != manualQuerySelections.vehicleModelId) {
                        const detailsToReset = [
                            { value: SelectInput.NullValue(), key: 'engineSize' },
                            { value: SelectInput.NullValue(), key: 'fuelType' },
                            { value: SelectInput.NullValue(), key: 'model' },
                            { value: SelectInput.NullValue(), key: 'modelType' },
                        ];

                        onBulkVehicleDetailChange(detailsToReset);
                        dispatch({ type: 'reset-model-neutral' });
                    }

                    new VehicleApi().getFuelTypes(manualQuerySelections.firstRegistrationDate, manualQuerySelections.vehicleTypeId,
                        manualQuerySelections.vehicleMakeId, vehicleModelId, bearerToken, successCallbackFuelTypes, failureCallbackVehicleInfo);
                }
                dispatch({ type: 'update-manual-query', property: 'vehicleModelId', value: vehicleModelId });

                break;
            case vehicleLookup.ENGINESIZE:
                const fuelTypeId = e.target.value;

                if (!SelectInput.isNullValue(fuelTypeId)) {
                    vehicleValue = e.target.options[e.target.selectedIndex].text;

                    if (fuelTypeId != manualQuerySelections.fuelTypeId) {
                        const detailsToReset = [
                            { value: SelectInput.NullValue(), key: 'engineSize' },
                            { value: SelectInput.NullValue(), key: 'fuelType' },
                            { value: SelectInput.NullValue(), key: 'modelType' },
                        ];

                        onBulkVehicleDetailChange(detailsToReset);
                        dispatch({ type: 'reset-fuel-type-neutral' });
                    }

                    new VehicleApi().getEngineSizes(manualQuerySelections.firstRegistrationDate, manualQuerySelections.vehicleTypeId,
                        manualQuerySelections.vehicleMakeId, manualQuerySelections.vehicleModelId, fuelTypeId, bearerToken,
                        successCallbackEngineSizes, failureCallbackVehicleInfo);
                }
                dispatch({ type: 'update-manual-query', property: 'vehicleFuelTypeId', value: fuelTypeId });

                break;
            case vehicleLookup.MODELTRIM:
                const engineSize = e.target.value;
                if (!SelectInput.isNullValue(engineSize)) {

                    if (engineSize != manualQuerySelections.engineSize) {
                        const detailsToReset = [
                            { value: SelectInput.NullValue(), key: 'engineSize' },
                            { value: SelectInput.NullValue(), key: 'modelType' },
                        ];

                        onBulkVehicleDetailChange(detailsToReset);
                        dispatch({ type: 'reset-engine-size-neutral' });
                    }

                    new VehicleApi().getModelTrims(manualQuerySelections.firstRegistrationDate, manualQuerySelections.vehicleTypeId,
                        manualQuerySelections.vehicleMakeId, manualQuerySelections.vehicleModelId, manualQuerySelections.vehicleFuelTypeId,
                        engineSize, bearerToken, successCallbackModelTrims, failureCallbackVehicleInfo);
                }
                dispatch({ type: 'update-manual-query', property: 'vehicleEngineSize', value: engineSize });

                break;
            case vehicleLookup.FINISHED:
                const modelTypeTrimId = e.target.value;

                if (!SelectInput.isNullValue(modelTypeTrimId)) {
                    const glassModelId = modelTypeTrimId.substring(0, modelTypeTrimId.length - 3)
                    onChangeDetail({ target: { value: glassModelId } }, 'glassModelID');

                    vehicleValue = e.target.options[e.target.selectedIndex].text;

                    if (modelTypeTrimId != manualQuerySelections.vehicleModelTrimId) {
                        onChangeDetail({ target: { value: SelectInput.NullValue() } }, 'modelType');
                        dispatch({ type: 'reset-model-type-neutral' });
                    }
                }
                dispatch({ type: 'update-manual-query', property: 'vehicleModelTrimId', value: modelTypeTrimId });

                break;
            default:
                break;
        }

        onVehicleDetailsChange(vehicleValue, key);
    }

    const successCallbackVehicleTypes = (data) => {
        const mappedVehicleTypes = mapVehicleSection(data);
        dispatch({ type: 'set-vehicle-types', vehicleTypes: mappedVehicleTypes });
    }

    const successCallbackVehicleMakes = (data) => {
        const mappedVehicleMakes = mapVehicleSection(data);
        dispatch({ type: 'set-makes', vehicleMakes: mappedVehicleMakes });
    }

    const successCallbackVehicleModels = (data) => {
        const mappedVehicleModels = mapVehicleSection(data);
        dispatch({ type: 'set-models', vehicleModels: mappedVehicleModels });
    }

    const successCallbackFuelTypes = (data) => {
        const mappedFuelTypes = mapVehicleSection(data);
        dispatch({ type: 'set-fuel-types', vehicleFuelTypes: mappedFuelTypes });
    }

    const successCallbackEngineSizes = (data) => {
        const mappedEngineSizes = mapVehicleSection(data);
        dispatch({ type: 'set-engine-sizes', vehicleEngineSizes: mappedEngineSizes });
    }

    const successCallbackModelTrims = (data) => {
        const mappedModelTrims = mapVehicleSection(data);
        dispatch({ type: 'set-model-trims', vehicleModelTrims: mappedModelTrims });
    }

    const failureCallbackVehicleInfo = (err) => {
        console.log("Failed to get with: ", err);
    }

    const mapVehicleSection = (vehicleInformation) => {
        let mappedVehicleOptions = [];
        for (let info of vehicleInformation) {
            const option = {
                "id": info.id,
                "text": info.text,
                "value": info.value,
                "disabled": false,
            }
            mappedVehicleOptions.push(option);
        }
        return mappedVehicleOptions;
    }

    const onToggleVehicleReg = (checkState) => {
        onChangeDetail({ target: { value: checkState } }, 'isManualMode');
        onChangeDetail({ target: { value: false } }, 'vehicleDetailsFound');
        dispatch({ type: 'update-finding', finding: false });

        resetVehicleDetails();
        resetVehicleDetailErrors();

        if (checkState) {
            onChangeDetail({ target: { value: true } }, 'showVehicleControls');
            onChangeDetail({ target: { value: false } }, 'vehicleDetailsFound');
        } else {
            onChangeDetail({ target: { value: false } }, 'showVehicleControls');
            dispatch({ type: 'vehicle-found', FoundVehicleOnSearch: true });

            dispatch({ type: 'clear-manual-lookups' });
            dispatch({ type: 'reset-type-neutral' })
        }
    }

    const validateManualDetails = () => {
        let isValid = true;
        let detailErrors = {};

        isValid = isValid && validateRegistration(detailErrors) && areSharedDetailsValid(detailErrors);

        if (SelectInput.isNullValue(vehicleDetails.vehicleType)) {
            detailErrors.vehicleType = "Vehicle type is required";
            isValid = false;
        }

        if (SelectInput.isNullValue(vehicleDetails.make)) {
            detailErrors.make = "Vehicle make is required";
            isValid = false;
        }

        if (SelectInput.isNullValue(vehicleDetails.fuelType)) {
            detailErrors.fuelType = "Fuel type is required";
            isValid = false;
        }

        if (SelectInput.isNullValue(vehicleDetails.engineSize)) {
            detailErrors.engineSize = "Engine size is required";
            isValid = false;
        }

        if (SelectInput.isNullValue(vehicleDetails.modelType)) {
            detailErrors.modelType = "Model type is required";
            isValid = false;
        }

        if (SelectInput.isNullValue(vehicleDetails.model)) {
            detailErrors.model = "Vehicle model is required";
            isValid = false;
        }

        dispatch({ type: 'update-errors', vehicleDetailErrors: detailErrors });

        return isValid;
    }


    const validateDetails = () => {
        let isValid = true;
        let detailErrors = {};

        isValid = isValid && validateRegistration(detailErrors) && areSharedDetailsValid(detailErrors);

        if (CommonValidation.isEmpty(vehicleDetails.make)) {
            detailErrors.make = "Vehicle make is required";
            isValid = false;
        }

        if (CommonValidation.isEmpty(vehicleDetails.fuelType)) {
            detailErrors.fuelType = "Fuel type is required";
            isValid = false;
        }

        if (CommonValidation.isEmpty(vehicleDetails.modelType)) {
            detailErrors.modelType = "Model type is required";
            isValid = false;
        }

        if (CommonValidation.isEmpty(vehicleDetails.model)) {
            detailErrors.model = "Vehicle model is required";
            isValid = false;
        }

        dispatch({ type: 'update-errors', vehicleDetailErrors: detailErrors });

        return isValid;
    }

    const areSharedDetailsValid = (errors) => {
        let isValid = true;

        const mileageLimit = 999999;

        if (!CommonValidation.isEmpty(vehicleDetails.currentMileage)) {          
            if (vehicleDetails.currentMileage > mileageLimit) {
                errors.currentMileage = `Current mileage should not be greater than ${mileageLimit}`;
                isValid = false;
            }
        } else {
            errors.currentMileage = "Current mileage is required";
            isValid = false;
        }

        if (CommonValidation.isDateNull(vehicleDetails.firstRegistrationDate, true) || CommonValidation.isEmpty(vehicleDetails.firstRegistrationDate)) {
            errors.firstRegistrationDate = "First registration date is required"
            isValid = false;
        } else if (!CommonValidation.isValidDate(vehicleDetails.firstRegistrationDate, true)) {
            errors.firstRegistrationDate = "First registration date is out of range";
            isValid = false;
        }

        if (CommonValidation.isDateNull(vehicleDetails.purchaseDate, true)) {
            errors.purchaseDate = "Vehicle purchase date is required";
            isValid = false;
        } else if (!CommonValidation.isValidDate(vehicleDetails.purchaseDate, true)) {
            errors.purchaseDate = "Vehicle purchase date is out of range";
            isValid = false;
        }

        return isValid;
    }

    const validateRegistration = (errors) => {
        let isValid = true;
        const registrationCharLimit = 20;

        if (!CommonValidation.isEmpty(vehicleDetails.registration)) {
            if (!CommonValidation.isValidAlphaNumeric(vehicleDetails.registration)) {
                errors.registration = "Vehicle registration is invalid";
                isValid = false;
            } else if (!CommonValidation.isValidLength(vehicleDetails.registration, registrationCharLimit)) {
                errors.registration = `Too many characters. Max length (${registrationCharLimit})`;
                isValid = false;
            }
        } else {
            errors.registration = "Vehicle registration is required";
            isValid = false;
        }
        return isValid;
    }

    const onSubmitVehicleDetails = () => {
        if (vehicleDetails.isManualMode) {
            if (validateManualDetails()) {
                nextPage();
            }
        } else {
            if (validateDetails()) {
                nextPage();
            }
        }
    }

    const handleFind = () => {

        const isValid = validateRegistration(vehicleDetailErrors);
        const vehicleErrors = { ...vehicleDetailErrors };
        dispatch({ type: 'update-errors', vehicleDetailErrors: vehicleErrors });

        if (isValid) {
            dispatch({ type: 'update-finding', finding: true });

            new VehicleApi().loadVehicle(vehicleDetails.registration, bearerToken, vehicleLookupSuccess, vehicleLookupFailed);
        }
    }

    const resetVehicleDetails = () => {
        const detailsToReset = [
            { value: '', key: 'engineSize' },
            { value: '', key: 'fuelType' },
            { value: '', key: 'make' },
            { value: '', key: 'model' },
            { value: '', key: 'modelType' },
            { value: '', key: 'vehicleType' },
            { value: '', key: 'registration' },
            { value: null, key: 'firstRegistrationDate' },
        ];

        onBulkVehicleDetailChange(detailsToReset);
    }

    const resetVehicleDetailErrors = (registrationError = '') => {
        const vehicleErrors = {
            ...vehicleDetailErrors,
            registration: registrationError,
            firstRegistrationDate: null,
            engineSize: '',
            fuelType: '',
            make: '',
            model: '',
            modelType: '',
            vehicleType: '',
            purchaseDate: '',
            currentMileage: '',
        };

        dispatch({ type: 'update-errors', vehicleDetailErrors: vehicleErrors });
    }

    const vehicleLookupSuccess = (foundVehicle) => {

        if (!foundVehicle) {
            dispatch({ type: 'vehicle-found', FoundVehicleOnSearch: false });
            onToggleVehicleReg(true);
            vehicleLookupFailed("null vehicle found");
            return;
        }

        const elementsNeeded = ['engineSize', 'fuelType', 'glassModelID', 'make', 'model', 'make', 'modelGroup', 'registrationDate'];
        let partOfVehicleMissing = false;

        for (const property in foundVehicle) {
            const value = foundVehicle[property];
            const foundElementToCheck = elementsNeeded.find(elm => elm === property);
            if (CommonValidation.isEmpty(value) && foundElementToCheck !== undefined) {
                partOfVehicleMissing = true;
                break;
            }
        }

        if (partOfVehicleMissing) {
            dispatch({ type: 'vehicle-found', FoundVehicleOnSearch: false });
            onToggleVehicleReg(true);
            return;
        }

        dispatch({ type: 'vehicle-found', FoundVehicleOnSearch: true });

        if (CommonValidation.isEmpty(foundVehicle.errorMessage)) {

            onChangeDetail({ target: { value: mapDateTextToJson(foundVehicle.registrationDate) } }, 'firstRegistrationDate');
            onChangeDetail({ target: { value: foundVehicle.engineSize } }, 'engineSize');
            onChangeDetail({ target: { value: foundVehicle.fuelType } }, 'fuelType');
            onChangeDetail({ target: { value: foundVehicle.make } }, 'make');
            onChangeDetail({ target: { value: foundVehicle.modelGroup } }, 'model');
            onChangeDetail({ target: { value: foundVehicle.model } }, 'modelType');
            onChangeDetail({ target: { value: foundVehicle.vin } }, 'vin');
            onChangeDetail({ target: { value: foundVehicle.glassQualID } }, 'glassQualID');
            onChangeDetail({ target: { value: foundVehicle.glassModelID } }, 'glassModelID');

            resetVehicleDetailErrors();
        }
        else {
            resetVehicleDetails();
            resetVehicleDetailErrors(foundVehicle.errorMessage);
        }

        dispatch({ type: 'update-finding', finding: false });
        onChangeDetail({ target: { value: true } }, 'showVehicleControls');
        onChangeDetail({ target: { value: true } }, 'vehicleDetailsFound');

    }

    const vehicleLookupFailed = (error) => {
        console.log(`Find vehicle error: ${error}`);

        const manualEnabledDueToError = { target: { value: true } };
        dispatch({ type: 'vehicle-found', FoundVehicleOnSearch: false });
        onToggleVehicleReg(manualEnabledDueToError);

        dispatch({ type: 'update-finding', finding: false });
    }

    const mapDateTextToJson = (dateText) => {
        var cdh = new ClientDateHandler();
        var dateValue = new Date(dateText)
        cdh.setDateValue(dateValue, false);
        var dateJson = cdh.getJSON();
        return dateJson;
    };

    return (
        <VehicleDetails
            vehicleDetails={vehicleDetails}
            vehicleDetailErrors={vehicleDetailErrors}
            onChangeDetail={onChangeDetail}
            onChangeDetailWithLookup={onChangeDetailWithLookup}
            continueClicked={onSubmitVehicleDetails}
            currency={Currency.Pound}
            finding={finding}
            onFindClick={handleFind}
            onToggleVehicleReg={onToggleVehicleReg}
            showAmendVehicleWarning={showAmendVehicleWarning}
            vehicleTypes={vehicleTypes}
            vehicleMakes={vehicleMakes}
            vehicleModels={vehicleModels}
            vehicleFuelTypes={vehicleFuelTypes}
            vehicleEngineSizes={vehicleEngineSizes}
            vehicleModelTrims={vehicleModelTrims}
            FoundVehicleOnSearch={FoundVehicleOnSearch}
            manualQuerySelections={manualQuerySelections}
        />
    );
}
export default ManageVehicleDetails;