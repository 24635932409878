import React from 'react';
import ReactDOM from 'react-dom';
import { useBasketDispatch, setBasketExpanded } from "../../../context/basketProvider";
import './dismissBackdrop.scss';

const DismissBackdrop = () => {
    const basketDispatch = useBasketDispatch();

    return ReactDOM.createPortal(
        <div className="backdrop" onClick={() => setBasketExpanded(basketDispatch, false)}></div>,
        document.getElementById("dismissBackdropRoot")
    )
}

export default DismissBackdrop;