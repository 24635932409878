import React, { useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CommonValidation } from '../../common/commonValidation/commonValidation';
import CustomerDetails from './customerDetails';
import CustomerReducer from './customerReducer';
import { CustomerApi } from '../../../api/customerApi';
import { SelectInput } from '../../common/selectInput/selectInput';
import { useBasketState } from "../../../context/basketProvider";
import { ThemeContext } from '../../../context/themeContext';
import { ClientDateHandler } from '../../../classes/clientDateHandler';

const initialState = {
    customerDetailErrors: {},
    searchPostcode: '',
    addressList: [],
    selectedAddress: SelectInput.NullValue(),
    showAddressList: false,
    showAddressForm: false,
    finding: false
}

const ManageCustomerDetails = ({ customerDetails, onCustomerDetailsChange, nextPage, previousPage, onAddressFound }) => {

    const basketState = useBasketState();
    const [state, dispatch] = useReducer(CustomerReducer, initialState)
    const { customerDetailErrors, addressList, selectedAddress, showAddressList, showAddressForm, finding, lastSearchedPostcode } = state;

    const { customerPageCustomisations } = useContext(ThemeContext);
    const { startDateLimit, startDateLimitNote, policyStartDateYearOffset, manualGapIntroductionDate } = customerPageCustomisations;


    const onChangeDetail = (e, key) => {

        onCustomerDetailsChange(e, key);
    }

    const onChangeSearchPostcode = (e) => {
        const updatedValue = e.target.value;

        if (lastSearchedPostcode !== updatedValue && showAddressList) {
            dispatch({ type: 'set-addresses', addressList: [] });
            dispatch({ type: 'show-address-list', show: false });
            dispatch({ type: 'update-last-searched-postcode', lastSearchedPostcode: '' });
        }
        onChangeDetail(e, 'searchPostcode');
        dispatch({ type: 'update-search-postcode', searchPostcode: customerDetails.searchPostcode });
    }

    const onContinue = () => {
        if (validate()) {
            nextPage();
        }
    }

    const validate = () => {
        var isValid = true;
        var isValidAddress = true;
        var errors = {};

        const titleCharLimit = 20;
        const firstNameCharLimit = 100;
        const surNameCharLimit = 100;
        const addressLine1CharLimit = 250;
        const addressLine2CharLimit = 250;
        const postCodeCharLimit = 20;
        const townOrCityCharLimit = 100;
        const countryCharLimit = 100;
        const telephoneNumberCharLimit = 20;
        const emailAddressCharLimit = 100;
        
        if (CommonValidation.isEmpty(customerDetails.title)) {
            errors.title = "Title is required";
            isValid = false;
        } else {
            if (!CommonValidation.isValidLength(customerDetails.title, titleCharLimit)) {
                isValid = false;
                errors.title = `Too many characters. Max length (${titleCharLimit})`;
            }
        }

        if (CommonValidation.isEmpty(customerDetails.firstName)) {
            errors.firstName = "First name is required";
            isValid = false;
        }
        else {
            if (!CommonValidation.isValidLength(customerDetails.firstName, firstNameCharLimit)) {
                isValid = false;
                errors.firstName = `Too many characters. Max length (${firstNameCharLimit})`;
            }
        }

        if (CommonValidation.isEmpty(customerDetails.surName)) {
            errors.surName = "Surname is required";
            isValid = false;
        } else {
            if (!CommonValidation.isValidLength(customerDetails.surName, surNameCharLimit)) {
                isValid = false;
                errors.surName = `Too many characters. Max length (${surNameCharLimit})`;
            }
        }

        if (CommonValidation.isEmpty(customerDetails.addressLine1)) {
            errors.addressLine1 = "Address line 1 is required";
            isValid = false;
            isValidAddress = false;
        } else {
            if (!CommonValidation.isValidLength(customerDetails.addressLine1, addressLine1CharLimit)) {
                isValid = false;
                isValidAddress = false;
                errors.addressLine1 = `Too many characters. Max length (${addressLine1CharLimit})`;
            }
        }

        if (!CommonValidation.isEmpty(customerDetails.addressLine2) && !CommonValidation.isValidLength(customerDetails.addressLine2, addressLine2CharLimit)) {
            isValid = false;
            isValidAddress = false;
            errors.addressLine2 = `Too many characters. Max length (${addressLine2CharLimit})`;
        }

        if (CommonValidation.isEmpty(customerDetails.postCode)) {
            errors.postCode = "Postcode is required";
            isValid = false;
            isValidAddress = false;
        } else {
            if (!CommonValidation.isValidLength(customerDetails.postCode, postCodeCharLimit)) {
                isValid = false;
                isValidAddress = false;
                errors.postCode = `Too many characters. Max length (${postCodeCharLimit})`;
            }
        }

        if (CommonValidation.isEmpty(customerDetails.townOrCity)) {
            errors.townOrCity = "Town/City is required";
            isValid = false;
            isValidAddress = false;
        } else {
            if (!CommonValidation.isValidLength(customerDetails.townOrCity, townOrCityCharLimit)) {
                isValid = false;
                isValidAddress = false;
                errors.townOrCity = `Too many characters. Max length (${townOrCityCharLimit})`;
            }
        }

        if (CommonValidation.isEmpty(customerDetails.country)) {
            errors.country = "Country is required";
            isValid = false;
            isValidAddress = false;
        } else {
            if (!CommonValidation.isValidLength(customerDetails.country, countryCharLimit)) {
                isValid = false;
                isValidAddress = false;
                errors.country = `Too many characters. Max length (${countryCharLimit})`;
            }
        }

        if (CommonValidation.isEmpty(customerDetails.telephoneNumber)) {
            errors.telephoneNumber = "Telephone number is required";
            isValid = false;
        } else {
            if (!CommonValidation.isValidLength(customerDetails.telephoneNumber, telephoneNumberCharLimit)) {
                isValid = false;
                errors.telephoneNumber = `Too many characters. Max length (${telephoneNumberCharLimit})`;
            }
        }

        if (!CommonValidation.isEmpty(customerDetails.emailAddress)) {
            if (!CommonValidation.isValidEmail(customerDetails.emailAddress)) {
                errors.emailAddress = "Email address is invalid";
                isValid = false;
            } else {
                if (!CommonValidation.isValidLength(customerDetails.emailAddress, emailAddressCharLimit)) {
                    isValid = false;
                    errors.emailAddress = `Too many characters. Max length (${emailAddressCharLimit})`;
                }
            }
        } else {
            errors.emailAddress = "Email address is required";
            isValid = false;
        }

        if (!CommonValidation.isEmpty(customerDetails.emailAddressConfirm)) {
            if (customerDetails.emailAddress !== customerDetails.emailAddressConfirm) {
                isValid = false;
                errors.emailAddressConfirm = "Email addresses do not match";
            }
        } else {
            errors.emailAddressConfirm = "Matching email is required";
            isValid = false;
        }

        let todaysDate = new Date();
        todaysDate.setHours(0, 0, 0, 0);

        let policyStartDateFutureLimit = new Date(todaysDate);
        policyStartDateFutureLimit.setDate(policyStartDateFutureLimit.getDate() + startDateLimit);

        //For validation so times don't matter, day only for validation checks
        const jsonPolicyStartDate = CommonValidation.getJsonDate(customerDetails.policyStartDate);
        const policyDateFormat = new Date(jsonPolicyStartDate);
        policyDateFormat.setHours(0, 0, 0, 0);

        if (CommonValidation.isDateNull(customerDetails.policyStartDate, true)) {
            errors.policyStartDate = "Policy start date is required";
            isValid = false;
        } else if (!CommonValidation.isValidDate(customerDetails.policyStartDate, true)) {
            errors.policyStartDate = "Policy start date is out of range";
            isValid = false;
        } else if (CommonValidation.isStartDateEqualOrPriorToEndDate(jsonPolicyStartDate, todaysDate, true)) {
            errors.policyStartDate = "Policy start date cannot be in the past";
            isValid = false;
        } else if (policyDateFormat > policyStartDateFutureLimit) {
            errors.policyStartDate = `Policy start date must not exceed ${startDateLimit} days from today's date`;
            isValid = false;
        }

        if (!showAddressForm && !isValidAddress) {
            errors.searchPostcode = "Address is required";
        }

        if (manualGapIntroductionDate) {
            if (CommonValidation.isDateNull(customerDetails.introductionDate, true)) {
                errors.introductionDate = "Gap introduction date is required";
                isValid = false;
            } else if (!CommonValidation.isValidDate(customerDetails.introductionDate, true)) {
                errors.introductionDate = "Gap introduction date is out of range";
                isValid = false;
            }
        }

        dispatch({ type: 'update-errors', customerDetailErrors: errors });

        return isValid;
    }

    const handleFind = () => {
        const isValid = validatePostcode(customerDetails.searchPostcode);

        if (isValid) {
            dispatch({ type: 'update-last-searched-postcode', lastSearchedPostcode: customerDetails.searchPostcode });
            dispatch({ type: 'update-finding', finding: true });
            new CustomerApi().loadAddresses(customerDetails.searchPostcode, basketState.bearerToken, loadAddressesSuccess, loadAddressesFailed);
        }
    }

    const validatePostcode = (postcode) => {
        var isValid = true;
        const postCodeCharLimit = 20;

        customerDetailErrors.searchPostcode = '';
        customerDetailErrors.addressList = '';
        customerDetailErrors.addressLine1 = '';
        customerDetailErrors.addressLine2 = '';
        customerDetailErrors.postCode = '';
        customerDetailErrors.townOrCity = '';
        customerDetailErrors.country = '';

        if (lastSearchedPostcode === postcode) {
            return false;
        }

        if (CommonValidation.isEmpty(postcode)) {
            customerDetailErrors.searchPostcode = "Postcode is required for finding your address";
            isValid = false;
        } else {
            if (!CommonValidation.isValidLength(postcode, postCodeCharLimit)) {
                isValid = false;
                customerDetailErrors.searchPostcode = `Too many characters. Max length (${postCodeCharLimit})`;
            }
        }

        dispatch({ type: 'update-errors', customerDetailErrors: customerDetailErrors });

        return isValid;
    }

    const truncate = (stringToTruncate, maxLength) => {
        return (stringToTruncate.length > maxLength) ? stringToTruncate.substr(0, maxLength - 1) + '...' : stringToTruncate;
    }

    const getLookupItemsAsOptions = (lookupItems, includeSelect) => {
        let items = [];

        if (includeSelect)
            items.push({ id: 0, text: 'Select...', value: SelectInput.NullValue() });

        const maxAddressLength = 35;

        for (var index = 0; index < lookupItems.length; index++) {
            var lookupOption =
            {
                "id": index + 1,
                "text": truncate(lookupItems[index].text, maxAddressLength),
                "value": lookupItems[index].value,
                "disabled": false
            };
            items.push(lookupOption);
        }
        return items;
    }

    const loadAddressesSuccess = (addresses) => {
        let addressOptions = getLookupItemsAsOptions(addresses, true);
        dispatch({ type: 'set-addresses', addressList: addressOptions });
        dispatch({ type: 'show-address-list', show: true });
        dispatch({ type: 'update-finding', finding: false });
    }

    const loadAddressesFailed = (error) => {
        console.log(`Find address error: ${error}`);
        customerDetailErrors.searchPostcode = 'Your address could not be found using this postcode. Please check the postcode and try again or enter your address manually';
        dispatch({ type: 'update-errors', customerDetailErrors: customerDetailErrors });
        dispatch({ type: 'set-addresses', addressList: [] });
        dispatch({ type: 'show-address-list', show: false });
        dispatch({ type: 'update-selected-address', selectedAddress: SelectInput.NullValue() });
        dispatch({ type: 'update-finding', finding: false });
        //this function does not reset the address textbox values in case the user has typed them and clicked the find address by mistake
    }

    const setAddress = (e) => {
        const selectedAddressMoniker = e.target.value;
        dispatch({ type: 'update-selected-address', selectedAddress: selectedAddressMoniker });

        if (SelectInput.isNullValue(selectedAddressMoniker)) {
            //clicing Select... in the address list will clear the address textbox values
            resetAddress();
            onAddressFound(customerDetails);
            setAddressFormVisible();
        }
        else {
            new CustomerApi().loadAddress(selectedAddressMoniker, basketState.bearerToken, setAddressSuccess, setAddressFailed);
        }
    }

    const resetAddress = () => {
        customerDetails.addressLine1 = '';
        customerDetails.addressLine2 = '';
        customerDetails.townOrCity = '';
        customerDetails.region = '';
        customerDetails.country = '';
        customerDetails.postCode = '';
    }

    const setAddressSuccess = (foundAddress) => {
        dispatch({ type: 'update-errors', customerDetailErrors: customerDetailErrors });

        if (foundAddress) {
            customerDetails.addressLine1 = foundAddress.addressLine1;
            customerDetails.addressLine2 = foundAddress.addressLine2;
            customerDetails.townOrCity = foundAddress.townOrCity;
            customerDetails.region = foundAddress.region;
            customerDetails.country = foundAddress.countryIso3Code === 'GBR' ? 'United Kingdom' : ''; //get the user to type the country if not GBR
            customerDetails.postCode = foundAddress.postcode;

            //reset any set address error message from previous atempts.
            customerDetailErrors.addressList = '';

            dispatch({ type: 'update-errors', customerDetailErrors: customerDetailErrors });
            onAddressFound(customerDetails);
            setAddressFormVisible();
        }
        else {
            setAddressFailedRoutine();

        }
    }

    const setAddressFailed = (error) => {
        console.log(`Find address error: ${error}`);
        setAddressFailedRoutine();
    }

    const setAddressFailedRoutine = () => {
        resetAddress();
        customerDetailErrors.addressList = 'Sorry there has been a problem retrieving this address. Please enter your address manually.';
        onAddressFound(customerDetails);
        setAddressFormVisible();
    }

    const setAddressFormVisible = () => {
        dispatch({ type: 'show-address-form', show: true });
    }

    const isFinalYearALeapYear = (year) => {
        return !((year % 4) && (year % 100) || !(year % 400));
    }

    let adjustedPolicyStartDate = new Date();
    if (policyStartDateYearOffset > 0) {
        adjustedPolicyStartDate.setFullYear(adjustedPolicyStartDate.getFullYear() + policyStartDateYearOffset);

        const daysToAdd = isFinalYearALeapYear(adjustedPolicyStartDate.getFullYear()) ? 1 : 0;
        adjustedPolicyStartDate.setDate(adjustedPolicyStartDate.getDate() + daysToAdd);
    }

    useEffect(() => {
        if ((customerDetails.policyStartDate === null ||
            JSON.parse(customerDetails.policyStartDate).value === '!null') && policyStartDateYearOffset !== 0) {
            var cdh = new ClientDateHandler();
            cdh.setDateValue(adjustedPolicyStartDate, false);

            var jsonDate = cdh.getJSON();
            onChangeDetail({ "target": { "value": jsonDate } }, 'policyStartDate')
        }
    }, []);

    return (
        <CustomerDetails
            customerDetails={customerDetails}
            customerDetailErrors={customerDetailErrors}
            minimumPolicyStartDate={adjustedPolicyStartDate}
            startDateLimitNote={startDateLimitNote}
            onChangeDetail={onChangeDetail}
            continueClicked={onContinue}
            backClicked={previousPage}
            manualGapIntroductionDate={manualGapIntroductionDate}
            finding={finding}
            onFindClick={handleFind}
            addressList={addressList}
            onSelectAddress={setAddress}
            selectedAddress={selectedAddress}
            showAddressList={showAddressList}
            showAddressForm={showAddressForm}
            onChangeSearchPostcode={onChangeSearchPostcode}
            onShowAddressFormClick={setAddressFormVisible}
        />
    );
}

ManageCustomerDetails.propTypes = {
    customerDetails: PropTypes.object.isRequired,
    onCustomerDetailsChange: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    onAddressFound: PropTypes.func.isRequired
}

export default ManageCustomerDetails;