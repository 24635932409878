import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { SelectInput } from '../../common/selectInput/selectInput';
import ReactPlayer from 'react-player';
import { PriceInput } from '../../common/priceInput/priceInput';
import { Currency } from '../../common/enums';
import './productPage.scss';
import { productType as productTypeEnum } from "../../../classes/enums";
import { Button } from '../../common/button/button';
import { CommonValidation } from '../../common/commonValidation/commonValidation';
import ContinueBackNavigation from '../navigation/continueBackNavigation';
import PriceDisplay from '../../common/priceDisplay/priceDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ProductPage = ({ productNotAvailable, pageDisplayDetails, durationLookup, claimLimitLookup, gapTypeLookup, durationSelected,
    claimLimitSelected, gapTypeSelected, productType, invoiceValue, priceDetails, defaqtoImage, productImageClass, loading, productLoadedViaInvoiceValue, errors,
    onChangeDuration, onChangeClaimLimit, onChangeGapType, showGapTypeDropDown, onChangeInvoiceValue, onFindProductClick, addToBasket, continueClicked, backClicked, defactoVisible }) => {
    const MVP_HideSeeMore = true;

    const mapBulletPoints = (bulletPoints) => {
        let panel = (
            bulletPoints.map((bullet, i) => <li key={i}>{bullet}</li>)
        );

        return panel;
    }

    const renderDurationLookup = () => {
        if ((productType != productTypeEnum.GAP) || (productType === productTypeEnum.GAP && productLoadedViaInvoiceValue)) {

            return (
                <div className="row justify-content-center product-lookup">
                    <SelectInput
                        name="duration"
                        loadingText='Select Duration'
                        value={durationSelected}
                        options={durationLookup}
                        onChange={(e) => onChangeDuration(e)}
                        forceDefault
                        defaultIfOneOption
                        addDefaultOption
                        defaultText='Select Duration'
                        error={errors.duration}
                    />
                </div>
            );
        }
    }

    const renderClaimLimitLookup = () => {

        if (CommonValidation.isNotEmpty(claimLimitLookup) && claimLimitLookup.length > 0) {
            if ((productType != productTypeEnum.GAP) || (productType === productTypeEnum.GAP && productLoadedViaInvoiceValue)) {
                return (
                    <div className="row justify-content-center product-lookup">
                        <SelectInput
                            name="claimLimit"
                            loadingText='Select claim limit'
                            value={claimLimitSelected}
                            options={claimLimitLookup}
                            onChange={(e) => onChangeClaimLimit(e)}
                            forceDefault
                            addDefaultOption
                            defaultText='Select claim limit'
                            error={errors.claimLimit}
                        />
                    </div>
                );
            }
        }
    }

    const renderIvoiceValueControl = () => {
        if (productType === productTypeEnum.GAP) {
            return (
                <Fragment>
                    <div className="product-invoice-value">
                        <PriceInput
                            name="invoice-value"
                            value={invoiceValue}
                            label="Enter invoice value"
                            onChange={(e) => onChangeInvoiceValue(e)}
                            onLeave={(e) => onChangeInvoiceValue(e)}
                            error={errors.invoiceValue}
                            currency={Currency.Pound}
                        />
                    </div>

                    <Button className="btn add-to-basket-button"
                        onClick={onFindProductClick}
                        waitValue={loading}
                        waitText='LOADING PRODUCTS...'
                        text='FIND PRODUCTS' >
                    </Button>

                </Fragment>
            );
        }
    }

    const renderGapTypeDropdown = () => {
        if (productType === productTypeEnum.GAP && productLoadedViaInvoiceValue && showGapTypeDropDown()) {

            return (
                <div className="row justify-content-center product-lookup">
                    <SelectInput
                        name="gapTypeDropdown"
                        loadingText='Select Gap product'
                        value={gapTypeSelected}
                        options={gapTypeLookup}
                        onChange={(e) => onChangeGapType(e)}
                        forceDefault
                        addDefaultOption
                        defaultText='Select Gap product'
                        error={errors.gapType}
                    />
                </div>
            );
        }
    }

    const renderPrice = () => {
        if (((productType != productTypeEnum.GAP) || (productType === productTypeEnum.GAP && productLoadedViaInvoiceValue))
            && CommonValidation.isNotEmpty(priceDetails)) {

            return (
                <ul className="card-bullet-list">
                    <PriceDisplay
                        priceDetails={priceDetails} />
                </ul>
            );
        }
    }

    const renderAddToShoppingBasket = () => {

        if ((productType != productTypeEnum.GAP) || (productType === productTypeEnum.GAP && productLoadedViaInvoiceValue)) {

            return (<button className="btn add-to-basket-button" onClick={addToBasket} >ADD TO BASKET</button>);
        }
    }

    const renderVideoOrImage = () => {

        if (pageDisplayDetails.showProductImage) {
            return (<div className={`product-image-container ${productImageClass}`} />);
        }

        return (<div className="product-video-details player-wrapper">
            <ReactPlayer
                className='react-player'
                controls
                url={pageDisplayDetails.videoUrl}
                width='100%'
                height='100%'
            />
        </div>);
    }

    const renderDocument = (productDocument, i) => {
        return (
            <tr key={i}>
                <td className="upper-case"><b>{productDocument.documentName}</b></td>
                <td className="product-icon product-right-chevron-link-color">
                    <a href={productDocument.documentPath} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </a>
                </td>
            </tr>
        );
    }

    const bulletPointsTwo = () => {
        let panel = '';

        if (pageDisplayDetails.straplineTwo != null) {
            panel = (
                <Fragment>
                    <p className="card-text card-text-product">{pageDisplayDetails.straplineTwo}</p>
                    <ul className="card-bullet-list card-bullet-list-product">
                        {mapBulletPoints(pageDisplayDetails.bulletPointsTwo)}
                    </ul>
                </Fragment>
            );
        }

        return panel;
    }

if (productNotAvailable) {
    return (

        <div>
            <div className="row justify-content-center">
                <p className="no-product"><strong>Sorry there are no products that match your vehicle details</strong></p>
            </div>
            <ContinueBackNavigation
                onContinueClick={continueClicked}
                onBackClick={backClicked}
                displayMinWidth={420}
            />
        </div>
    );
}
else {
    return (
        <div className="product-panel">
            <div className="row">
                <div className="col-xs-12 col-lg-8">
                    <div className="product-video-panel">
                        {renderVideoOrImage()}
                    </div>
                </div>
                <div className="col-xs-12 col-lg-4">
                    <div className="card card-product">
                        <div className="card-header card-header-product">
                            <div className="title">
                                {pageDisplayDetails.title}
                            </div>
                            <div className="sub-title">
                                {pageDisplayDetails.subTitle}
                            </div>
                        </div>
                        <div className="card-body card-body-product">
                            <p className="card-text card-text-product">{pageDisplayDetails.strapline}</p>
                            <ul>
                                {mapBulletPoints(pageDisplayDetails.bulletPoints)}
                            </ul>
                            {bulletPointsTwo()}
                            <p className="card-text card-text-product">{pageDisplayDetails.straplineThree}</p>
                            <div className="defacto-container">
                                {defactoVisible && <img className="defacto-image" src={defaqtoImage} alt="defacto rating" />}
                            </div>

                            {renderIvoiceValueControl()}
                            {renderGapTypeDropdown()}
                            {renderDurationLookup()}
                            {renderClaimLimitLookup()}
                            {renderPrice()}
                        </div>
                        <div className="card-footer card-footer-product">
                            {renderAddToShoppingBasket()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-lg-8">
                    <div className="product-see-more-panel">
                        <div className="accordion" id="accordionSeeMore">
                            <div id="headingOne" className="product-accordian-header">
                                <table>
                                    <tbody>
                                        {!MVP_HideSeeMore && <tr>
                                            <td>
                                                <b>SEE MORE</b>
                                            </td>
                                            <td className="product-icon">
                                                <a className="btn" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                </a>
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <div id="collapseOne" className="collapse product-see-more-details" aria-labelledby="headingOne" data-parent="#accordionSeeMore">
                                More details...
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-lg-4">
                    <div className="product-policy-document-panel">
                        <div className="accordion" id="accordionPolicyDocument">
                            <div id="headingTwo" className="product-accordian-header">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <b>POLICY DOCUMENTS</b>
                                            </td>
                                            <td className="product-icon">
                                                <a className="btn" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div id="collapseTwo" className="collapse product-policy-document-details" aria-labelledby="headingTwo" data-parent="#accordionPolicyDocument">
                                <table>
                                    <tbody>
                                        {pageDisplayDetails.documents.map((document, i) => renderDocument(document, i))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContinueBackNavigation
                onContinueClick={continueClicked}
                onBackClick={backClicked}
                displayMinWidth={420}
            />
        </div>
    );
}
}

ProductPage.propTypes = {
    productNotAvailable: PropTypes.bool.isRequired,
    pageDisplayDetails: PropTypes.object.isRequired,
    durationLookup: PropTypes.array.isRequired,
    claimLimitLookup: PropTypes.array.isRequired,
    gapTypeLookup: PropTypes.array.isRequired,
    durationSelected: PropTypes.string.isRequired,
    claimLimitSelected: PropTypes.string.isRequired,
    gapTypeSelected: PropTypes.string.isRequired,
    productType: PropTypes.number.isRequired,
    invoiceValue: PropTypes.string.isRequired,
    priceDetails: PropTypes.object.isRequired,
    defaqtoImage: PropTypes.string.isRequired,
    productImageClass: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    productLoadedViaInvoiceValue: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    onChangeDuration: PropTypes.func.isRequired,
    onChangeClaimLimit: PropTypes.func.isRequired,
    onChangeGapType: PropTypes.func.isRequired,
    showGapTypeDropDown: PropTypes.func.isRequired,
    onChangeInvoiceValue: PropTypes.func.isRequired,
    onFindProductClick: PropTypes.func.isRequired,
    addToBasket: PropTypes.func.isRequired,
    continueClicked: PropTypes.func.isRequired,
    backClicked: PropTypes.func.isRequired,
    defactoVisible: PropTypes.bool.isRequired
}

export default ProductPage;