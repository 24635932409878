import React from 'react'
import { AlertType } from './AlertType'
import BaseAlert from './BaseAlert'

export const DangerAlert = (props) => {

    return (
        <BaseAlert
            alertType={AlertType.Danger}
            includeImage={props.includeImage}
            customImage={props.customImage}
            includeCloseButton={props.includeCloseButton}
            animated={props.animated}
            strong={props.strong}
            message={props.message}
        />
    );
}
