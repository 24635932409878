import React, { useReducer, useEffect, useContext } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { showErrorToast, showCustomToast } from '../../classes/toast';
import { productType, paymentMethod } from '../../classes/enums';
import { ClientDateHandler } from '../../classes/clientDateHandler';
import { CustomerJourneyApi } from '../../api/customerJourneyApi';
import { ProductApi } from '../../api/productApi';
import { CommonValidation } from '../common/commonValidation/commonValidation';
import MainPage from './mainPage';
import MainPageReducer from './mainPageReducer';
import _ from 'lodash';
import { pages } from '../../classes/enums'
import { ThemeContext } from '../../context/themeContext';
import { GoogleAnalytics } from '../../classes/googleAnalytics';
import { useBasketState, useBasketDispatch, addProductSuccess, setBasketExpanded, clearProducts } from "../../context/basketProvider";

const initialState = {
    loading: false,
    page: pages.VEHICLE,
    vehicleDetails: {
        registration: '',
        make: '',
        model: '',
        modelType: '',
        fuelType: '',
        engineSize: '',
        vehicleType: '',
        firstRegistrationDate: null,
        purchaseDate: null,
        purchasePrice: '',
        currentMileage: '',
        showVehicleControls: false,
        isManualMode: false,
        glassQualID: '',
        glassModelID: '',
        vin: ''
    },
    customerDetails: {
        title: '',
        firstName: '',
        surName: '',
        addressLine1: '',
        addressLine2: '',
        postCode: '',
        townOrCity: '',
        country: '',
        telephoneNumber: '',
        emailAddress: '',
        policyStartDate: null,
        introductionDate: null
    },
    productTypes: ['GAP', 'TYRE', 'WHEEL', 'COSMETIC'],
    products: undefined,
    purchasePageLinks: [
        { page: pages.VEHICLE, order: 1, text: "VEHICLE DETAILS", active: true, disabled: false, completed: false, visible: true },
        { page: pages.ALLOY, order: 2, text: "ALLOY WHEEL INSURANCE", active: false, disabled: true, completed: false, visible: true },
        { page: pages.COSMETIC, order: 3, text: "COSMETIC REPAIR INSURANCE", active: false, disabled: true, completed: false, visible: true },
        { page: pages.GAP, order: 4, text: "GAP INSURANCE", active: false, disabled: true, completed: false, visible: true },
        { page: pages.TYRE, order: 5, text: "TYRE INSURANCE", active: false, disabled: true, completed: false, visible: true },
        { page: pages.WARRANTY, order: 6, text: "WARRANTY", active: false, disabled: true, completed: false, visible: true },
        { page: pages.PERSONAL, order: 7, text: "PERSONAL DETAILS", active: false, disabled: true, completed: false, visible: true },
        { page: pages.PURCHASE, order: 8, text: "PURCHASE", active: false, disabled: true, completed: false, visible: true }
    ],
    showAmendVehicleWarning: false,
    policies: []
}


const ManageMainPage = () => {
    const history = useHistory();
    const basketDispatch = useBasketDispatch();
    const basketState = useBasketState();
    const basketProductsDistinct = basketState.productsDistinct;
    const basketProductsAll = basketState.productsAll;

    const { productPageCustomisations, theme, dealerCode, brandName, businessPartnerId, businessPartnerName, googleAnalyticsEnable, iconVisible } = useContext(ThemeContext);

    const [state, dispatch] = useReducer(MainPageReducer, initialState);

    const { loading, page, vehicleDetails, customerDetails, products, purchasePageLinks, showAmendVehicleWarning, policies } = state;

    const location = useLocation();

    useEffect(() => {
        const containers = document.getElementsByClassName('container')
        if (containers.length > 0) {
            containers[0].scrollIntoView();
        }

    }, [page])

    const getPageOrder = (pageType) => {
        const firstProductPage = location.state.firstProductPage;

        switch (pageType) {
            case pages.VEHICLE:
                return 1;
            case pages.ALLOY:
                switch (firstProductPage) {
                    case pages.ALLOY:
                        return 2;
                    case pages.COSMETIC:
                        return 3;
                    case pages.GAP:
                        return 3;
                    case pages.TYRE:
                        return 3;
                    case pages.WARRANTY:
                        return 3;
                    default:
                        return 2;
                }
            case pages.COSMETIC:
                switch (firstProductPage) {
                    case pages.ALLOY:
                        return 3;
                    case pages.COSMETIC:
                        return 2;
                    case pages.GAP:
                        return 4;
                    case pages.TYRE:
                        return 4;
                    case pages.WARRANTY:
                        return 4;
                    default:
                        return 3;
                }
            case pages.GAP:
                switch (firstProductPage) {
                    case pages.ALLOY:
                        return 4;
                    case pages.COSMETIC:
                        return 4;
                    case pages.GAP:
                        return 2;
                    case pages.TYRE:
                        return 5;
                    case pages.WARRANTY:
                        return 5;
                    default:
                        return 4;
                }
            case pages.TYRE:
                switch (firstProductPage) {
                    case pages.ALLOY:
                        return 5;
                    case pages.COSMETIC:
                        return 5;
                    case pages.GAP:
                        return 5;
                    case pages.TYRE:
                        return 2;
                    case pages.WARRANTY:
                        return 6;
                    default:
                        return 5;
                }
            case pages.WARRANTY:
                switch (firstProductPage) {
                    case pages.ALLOY:
                        return 6;
                    case pages.COSMETIC:
                        return 6;
                    case pages.GAP:
                        return 6;
                    case pages.TYRE:
                        return 6;
                    case pages.WARRANTY:
                        return 2;
                    default:
                        return 6;
                }
            case pages.PERSONAL:
                return 7;
            case pages.PURCHASE:
                return 8;
            default:
                return -1;
        }
    }

    const getProductVisibility = (pageType) => {
        switch (pageType) {
            case pages.ALLOY:
                return _.some(productPageCustomisations.products, { type: productType.ALLOY });
            case pages.COSMETIC:
                return _.some(productPageCustomisations.products, { type: productType.COSMETIC });
            case pages.GAP:
                return _.some(productPageCustomisations.products, { type: productType.GAP });
            case pages.TYRE:
                return _.some(productPageCustomisations.products, { type: productType.TYRE });
            case pages.WARRANTY:
                return _.some(productPageCustomisations.products, { type: productType.WARRANTY });
            default:
                return true;
        }
    };

    const getValidProductTitle = (pageType) => {
        let product;

        switch (pageType) {
            case pages.ALLOY:
                product = productPageCustomisations.products.find(prod => prod.type == productType.ALLOY);
                return product ? product.title : "ALLOY WHEEL INSURANCE";
            case pages.COSMETIC:
                product = productPageCustomisations.products.find(prod => prod.type == productType.COSMETIC);
                return product ? product.title : "COSMETIC REPAIR INSURANCE";
            case pages.GAP:
                product = productPageCustomisations.products.find(prod => prod.type == productType.GAP);
                return product ? product.title : "GAP INSURANCE";
            case pages.TYRE:
                product = productPageCustomisations.products.find(prod => prod.type == productType.TYRE);
                return product ? product.title : "TYRE INSURANCE";
            case pages.WARRANTY:
                product = productPageCustomisations.products.find(prod => prod.type == productType.WARRANTY);
                return product ? product.title : "WARRANTY INSURANCE";
            default:
                return null;
        }
    }

    useEffect(() => {

        if (!theme || theme === 'theme-null' || productPageCustomisations === undefined) {
            history.push('/error');
            return;
        }

        //set the order of the purchasePageLinks
        let updatedLinks = purchasePageLinks.map(link => ({
            ...link,
            order: getPageOrder(link.page),
            visible: getProductVisibility(link.page),
            text: (getValidProductTitle(link.page) || link.text).toUpperCase()
        }));

        dispatch({ type: 'update-navigation', items: updatedLinks });

        GoogleAnalytics.trackPageViewGoogleAnalytics(pages.VEHICLE, googleAnalyticsEnable, brandName);
    }, [location])

    useEffect(() => {
        updateCustomerJourney();
        window.scrollTo(0, 0);
    }, [page])

    useEffect(() => {
        updateCustomerJourney();
    }, [basketProductsDistinct])

    useEffect(() => {
        updateCustomerJourney();
    }, [policies])

    const closeBasketOnPageChange = async () => {
        await setBasketExpanded(basketDispatch, false);
    }

    const onVehicleDetailsChange = (value, key) => {
        dispatch({ type: 'update-vehicle-detail', property: key, value: value });

        if (products != undefined)
            dispatch({ type: 'update-products', products: undefined });

    }

    const onBulkVehicleDetailChange = (values) => {
        for (let value of values) {
            dispatch({ type: 'update-vehicle-detail', property: value.key, value: value.value });
        }

        if (products != undefined)
            dispatch({ type: 'update-products', products: undefined });

    }

    const onCustomerDetailsChange = (e, key) => {
        const val = e.target.value;
        dispatch({ type: 'update-customer-detail', property: key, value: val });
    }

    const updateCustomerDetails = (customerInfo) => {
        dispatch({ type: 'update-customer-details', customerDetails: customerInfo });
    }

    const updateProducts = (newProducts, invoiceValue) => {
        dispatch({ type: 'update-products', products: newProducts });
        dispatch({ type: 'update-vehicle-detail', property: 'purchasePrice', value: invoiceValue });
    }

    const addProductToBasket = (newProducts) => {

        const addedProduct = purchasePageLinks.find(link => link.page == page);
        const productName = addedProduct ? addedProduct.text : undefined;

        if (!addProductSuccess(basketDispatch, basketProductsDistinct, newProducts, productName)) {
            showErrorToast("You already have a similar product in your basket!", "Duplicate Product", 5000);
        } else {
            showCustomToast("Product added to basket", theme, productName, 5000)
        }
    };

    const loadProducts = (invoiceValue, successCallback) => {

        if (invoiceValue === null)
            dispatch({ type: 'update-loading', loading: true });

        var failureCallback = (error) => {
            dispatch({ type: 'update-loading', loading: false });
            showErrorToast(error, 'Failed to load products', 3000);
        };

        var productFilter = {
            dealerCode: dealerCode,
            firstRegistrationDate: mapJsonDateToServer(vehicleDetails.firstRegistrationDate),
            vehiclePurchaseDate: mapJsonDateToServer(vehicleDetails.purchaseDate),
            vehicleValue: parseFloat(invoiceValue),
            currentMileage: parseInt(vehicleDetails.currentMileage),
            glassModelID: vehicleDetails.glassModelID,
            glassQualID: vehicleDetails.glassQualID
        };

        new ProductApi().loadProducts(productFilter, basketState.bearerToken, successCallback, failureCallback);
    };

    var productSuccessCallback = (data) => {
        dispatch({ type: 'update-products', products: data });
        dispatch({ type: 'update-loading', loading: false });
    };

    const getPreviousPage = (currentPage) => {
        let currentPageOrder = _.find(purchasePageLinks, { page: currentPage }).order;
        let previousPageLink = null;

        while (currentPageOrder > 0 && !previousPageLink) {
            currentPageOrder -= 1;
            previousPageLink = _.find(purchasePageLinks, { order: currentPageOrder, visible: true });
        }

        if (previousPageLink) {
            return previousPageLink.page;
        }
        return -1;
    }

    const getNextPage = (currentPage) => {
        let currentPageOrder = _.find(purchasePageLinks, { page: currentPage}).order;
        let nextPageLink = null;

        while (currentPageOrder <= purchasePageLinks.length && !nextPageLink) {
            currentPageOrder += 1;
            nextPageLink = _.find(purchasePageLinks, { order: currentPageOrder, visible: true });
        }

        if (nextPageLink) {
            return nextPageLink.page;
        }
        return -1;
    }

    const setPage = (selectedPage) => {

        if (page == selectedPage) return;

        closeBasketOnPageChange();
        GoogleAnalytics.trackPageViewGoogleAnalytics(selectedPage, googleAnalyticsEnable, brandName);

        dispatch({ type: 'update-page', page: selectedPage });

        if (!vehicleDetails.isManualMode)
            dispatch({ type: 'update-vehicle-warning', showAmendVehicleWarning: (selectedPage == pages.VEHICLE) });

        switch (selectedPage) {
            case pages.ALLOY:
            case pages.COSMETIC:
            case pages.GAP:
            case pages.TYRE:
            case pages.WARRANTY:
                if (products == undefined) {
                    clearProducts(basketDispatch);
                    loadProducts(null, productSuccessCallback);
                }
        }

        updateNavigation(selectedPage);
    }



    const setPreviousPage = () => {
        const previousPage = getPreviousPage(page);
        setPage(previousPage);
    }

    const setNextPage = () => {
        const nextPage = getNextPage(page);
        setPage(nextPage);
    }

    const oneWayNavigationUpdate = (selectedPageIndex) => {
        const activePageOrderNumber = _.find(purchasePageLinks, { page: selectedPageIndex}).order;

        let updatedLinks = purchasePageLinks.map(link => ({
            ...link,
            active: (link.order === activePageOrderNumber),
            disabled: (link.order > activePageOrderNumber)
        }));

        return updatedLinks;
    }

    const updateNavigation = (sentPage) => {
        let updatedNavigationItems = oneWayNavigationUpdate(parseInt(sentPage, 10));
        dispatch({ type: 'update-navigation', items: updatedNavigationItems });
    }

    const updateCustomerJourney = () => {

        if (basketState.bearerToken == null) {
            return;
        }

        const policiesExist = policies.length > 0;

        let variantCodesToSave;
        variantCodesToSave = policiesExist ? _.join(policies.map(policy => policy.variantCode)) :
            _.join(basketProductsAll.map(product => product.warrantyVariantId));

        let payMethod = "";

        if (policiesExist) {
            const registeredProduct = basketProductsAll.find(product => product.warrantyVariantId == policies[0].variantCode);
            payMethod = registeredProduct.paymentMethods[0] == "DD Installments" ? paymentMethod.DIRECTDEBIT : paymentMethod.CREDITCARD;
        }

        let purchaseProgress = {
            id: basketState.customerJourneyId,
            businessPartnerId: businessPartnerId,
            vehicleRegistration: vehicleDetails.registration,
            vehicleMake: vehicleDetails.make,
            vehicleModel: vehicleDetails.model,
            vehicleModelType: vehicleDetails.modelType,
            fuelType: vehicleDetails.fuelType,
            engineSize: vehicleDetails.engineSize,
            firstRegistrationDate: mapJsonDateToServer(vehicleDetails.firstRegistrationDate),
            vehiclePurchaseDate: mapJsonDateToServer(vehicleDetails.purchaseDate),
            vehiclePurchasePrice: parseFloat(vehicleDetails.purchasePrice),
            currentMileage: parseInt(vehicleDetails.currentMileage),

            title: customerDetails.title,
            firstName: customerDetails.firstName,
            surName: customerDetails.surName,
            addressLine1: customerDetails.addressLine1,
            addressLine2: customerDetails.addressLine2,
            postCode: customerDetails.postCode,
            townOrCity: customerDetails.townOrCity,
            country: customerDetails.country,
            telephoneNumber: customerDetails.telephoneNumber,
            emailAddress: customerDetails.emailAddress,
            policyStartDate: CommonValidation.isNotEmpty(customerDetails.policyStartDate)
                ? mapJsonDateToServer(customerDetails.policyStartDate) : null,
            selectedProducts: variantCodesToSave,
            policyNumbers: _.join(policies.map(policy => policy.number)) || '',
            lastPageViewed: page,
            paymentMethod: payMethod,
        };

        var failureCallback = (error) => {
            console.error(`Save customer journey failed: ${JSON.stringify(error)}`);
        };

        new CustomerJourneyApi().save(purchaseProgress, basketState.bearerToken, null, failureCallback);
    }

    const mapJsonDateToServer = (dateJson) => {

        var cdh = new ClientDateHandler();
        cdh.parseJSON(dateJson);

        var localDate = cdh.getLocalDate();
        return localDate;
    };

    const updatePolicies = (updatedPolicies) => {
        dispatch({ type: 'update-policy-numbers', policies: updatedPolicies });
    }

    return (
        <MainPage
            vehicleDetails={vehicleDetails}
            products={products}
            customerDetails={customerDetails}
            page={page}
            loading={loading}
            onVehicleDetailsChange={onVehicleDetailsChange}
            onBulkVehicleDetailChange={onBulkVehicleDetailChange}
            addProductToBasket={addProductToBasket}
            onCustomerDetailsChange={onCustomerDetailsChange}
            nextPage={setNextPage}
            previousPage={setPreviousPage}
            purchasePageLinks={purchasePageLinks}
            updateNavigation={setPage}
            onAddressFound={updateCustomerDetails}
            showAmendVehicleWarning={showAmendVehicleWarning}
            basketProductsDistinct={basketProductsDistinct}
            basketProductsAll={basketProductsAll}
            businessPartnerName={businessPartnerName}
            brandName={brandName}
            loadProducts={loadProducts}
            updateProducts={updateProducts}
            hideNavBar={basketState.finishedJourney}
            updatePolicies={updatePolicies}
            iconVisible={iconVisible}
        />
    );
}

export default ManageMainPage;