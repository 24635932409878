import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const PriceDisplay = ({ priceDetails, singlePaymentPrice, listStyle }) => {
    const customListStyle = listStyle || '';

    const renderPriceDirectDebit = () => {
        if (priceDetails.hasEqualInstallments) {
            return (
                <li className={customListStyle}><b>£{priceDetails.installmentAmount}</b> per month over <b>{priceDetails.noOfInstallments}</b> months</li>
            );
        }

        return (
            <Fragment>
                <li className={customListStyle}>
                    <li>Instalments to be paid per month over <b>{priceDetails.noOfInstallments}</b> months</li>
                    <li>First payment: <b>£{priceDetails.firstInstallmentAmount}</b></li>
                    <li>Subsequent payments: <b>£{priceDetails.installmentAmount}</b> per month</li>
                </li>
            </Fragment>
        );
    }

    const renderPriceSinglePayment = () => {
        const cost = priceDetails.singlePaymentAmount || singlePaymentPrice;
        return (
            <li className={customListStyle}><b>{`£${cost.toFixed(2)}`}</b> as a single payment</li>
        );
    }

    const directDebitDisplay = priceDetails.totalAmount > 0 ? renderPriceDirectDebit() : <Fragment />;
    const singlePaymentDisplay = priceDetails.singlePaymentAmount > 0 || singlePaymentPrice != undefined ? renderPriceSinglePayment() : <Fragment />;

    return (
        <Fragment>
            {directDebitDisplay}
            {singlePaymentDisplay}
        </Fragment>
    );
}

export default PriceDisplay;

PriceDisplay.propTypes = {
    priceDetails: PropTypes.object.isRequired,
    singlePaymentPrice: PropTypes.number,
    listStyle: PropTypes.string
}
