import React from 'react';
import PropTypes from 'prop-types'
import './errorPage.scss';

const ErrorPage = ({ errorMessage }) => {
    const error = errorMessage ? errorMessage : 'An unexpected error occured';
    return <div className="generic-container">{`404 Error. ${error}. Please enter your original URL and try again`}</div>
}

export default ErrorPage;

ErrorPage.propTypes = {
    errorMessage: PropTypes.string
}