import React, { useContext, useEffect} from 'react';
import ProductCard from "./productCard";
import { useHistory } from "react-router-dom";
import './landingPage.scss';
import { pages, productType as productTypeEnum } from '../../classes/enums';
import { ThemeContext } from "../../context/themeContext";
import { useBasketDispatch, showBasket, clearProducts } from "../../context/basketProvider";
import _ from 'lodash';
import { pageNames } from '../../classes/enums';
import { GoogleAnalytics } from '../../classes/googleAnalytics';
import { CommonValidation } from '../common/commonValidation/commonValidation';

const LandingPage = () => {
    const history = useHistory();

    const { landingPageCustomisations, theme, themeRaw, defactoVisible, googleAnalyticsEnable, iconVisible, brandName } = useContext(ThemeContext);
    const basketDispatch = useBasketDispatch();

    useEffect(() => {
        if (!theme || theme === 'theme-null') {
            history.push('/error');
        }
        else {
            GoogleAnalytics.setPageViewGoogleAnalytics(pageNames.LANDING, googleAnalyticsEnable, brandName);  
            showBasket(basketDispatch);
        }

    }, [theme])

    const goToMainSite = (productType) => {
        let page = pages.ALLOY;
        clearProducts(basketDispatch);

        switch (productType) {
            case productTypeEnum.ALLOY:
                page = pages.ALLOY;
                break;
            case productTypeEnum.GAP:
                page = pages.GAP;
                break;
            case productTypeEnum.TYRE:
                page = pages.TYRE;
                break;
            case productTypeEnum.COSMETIC:
                page = pages.COSMETIC;
                break;
            case productTypeEnum.WARRANTY:
                page = pages.WARRANTY;
                break;
        }

        history.push({
            pathname: `/${themeRaw}/main`,
            state: { firstProductPage: page }
        })
    }

    let mapProducts;
    let title = '';
    let subtitle = '';
    let footerSource = '';

    if (landingPageCustomisations !== undefined) {

        mapProducts = landingPageCustomisations.products.map(product => {
            return <ProductCard key={product.type} product={product} onFindOutMore={goToMainSite} defactoVisible={defactoVisible} iconVisible={iconVisible} />
        });

        title = landingPageCustomisations.title;
        subtitle = landingPageCustomisations.subtitle;

        if (CommonValidation.isNotEmpty(landingPageCustomisations.footerNote)) {
            footerSource = <div className="credit__footnote">{landingPageCustomisations.footerNote}</div>;
        }
    }

    return (
        <div className="landingPage-container">
            <header>
                <div className="header-logo-container">
                    <div className="header-logo"></div> 
                </div> 
            </header>
            <main>
                <div className="landing-page-info">
                    <div className="welcome">
                        <div className='title'>{title}</div>
                        <div className='sub-title'>
                            <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
                        </div>
                    </div> 

                    <div className="card-group-product">
                        {mapProducts}
                    </div>
                </div>
            </main>
            <footer>
                <a href="http://www.view-privacy-policy.co.uk/Privacy-Policy" target="_blank" rel="noopener noreferrer">Privacy policy</a>
                <a href="../html/termsAndConditions.html" target="_blank" rel="noopener noreferrer" >Terms & conditions</a>
                {footerSource}
            </footer>
        </div>
    );
}

export default LandingPage;


