import React from 'react'
import PropTypes from 'prop-types';
import { AlertType } from './AlertType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const BaseAlert = (props) => {

    BaseAlert.propTypes = {
        alertType: PropTypes.oneOf(Object.values(AlertType)).isRequired
    };

    let rootClasses = 'alert ';
    if (props.alertType == null) {
        rootClasses += ' alert-primary'
    } else {
        rootClasses += props.alertType.typeClass;
    }

    let closeButton = null;
    if (props.includeCloseButton) {
        rootClasses += ' alert-dismissible'
        closeButton = (
            <button type="button" className="close" data-dismiss="alert">
                <FontAwesomeIcon icon={faTimes} />
            </button>
        );
    }

    if (props.animated) {
        rootClasses += ' fade show'
    }

    let imageItem = null;
    let messageLineClasses = '';
    if (props.includeImage) {
        if (props.customImage == null) {
            imageItem = <i id="alert" className={props.alertType.iconClass} />
        }
    } else {
        imageItem = <i id="alert" className={props.customImage} />
    }

    messageLineClasses = 'pl-2';

    if (props.strong) {
        messageLineClasses += ' font-weight-bold'
    }

    return (
        <div className={rootClasses} role="alert">
            {imageItem}
            <span className={messageLineClasses}>{props.message}</span>
            {closeButton}
        </div>
    );
}

export default BaseAlert;