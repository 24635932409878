import _ from 'lodash';

const MainPageReducer = (state, action) => {

    switch (action.type) {
        case 'update-loading':
            return {
                ...state,
                loading: action.loading
            };
        case 'update-page':
            return {
                ...state,
                page: action.page
            };
        case 'update-vehicle-detail':
            return {
                ...state,
                vehicleDetails: {
                    ...state.vehicleDetails,
                    [action.property]: action.value,
                    isValid: false
                }
            };
        case 'update-customer-detail':
            return {
                ...state,
                customerDetails: {
                    ...state.customerDetails,
                    [action.property]: action.value,
                }
            };
        case 'update-customer-details':
            return {
                ...state,
                customerDetails: action.customerDetails
            };
        case 'update-products':
            return {
                ...state,
                products: action.products
            };
        case 'update-navigation':
            return {
                ...state,
                purchasePageLinks: action.items
            };
        case 'update-vehicle-warning':
            return {
                ...state,
                showAmendVehicleWarning: action.showAmendVehicleWarning
            };
        case 'update-policy-numbers':
            return {
                ...state,
                policies: action.policies
            };
        default:
            return state;
    }

}

export default MainPageReducer;
