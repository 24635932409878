import React, { useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProductReducer from './productReducer';
import _ from 'lodash';
import ProductPage from './productPage';
import { CommonValidation } from '../../common/commonValidation/commonValidation';
import { SelectInput } from '../../common/selectInput/selectInput';
import { productType as productTypeEnum, paymentMethod, gapTypes } from "../../../classes/enums";
import { ThemeContext } from "../../../context/themeContext";
import { PriceHelper } from '../../../classes/priceHelper';
import { GoogleAnalytics } from '../../../classes/googleAnalytics';
import DefaqtoAlloy from '../../../images/Defaqto_Alloy.png';
import DefaqtoDamage from '../../../images/Defaqto_Damage.png';
import DefaqtoGap from '../../../images/Defaqto_Gap.png';
import DefaqtoTyre from '../../../images/Defaqto_Tyre.png';

const initialState = {
    durationLookup: [],
    claimLimitLookup: [],
    gapTypeLookup:[],
    claimLimitSelected: SelectInput.NullValue(),
    durationSelected: SelectInput.NullValue(),
    gapTypeSelected: SelectInput.NullValue(),
    productNotAvailable: false,
    invoiceValue: '',
    productLoadedViaInvoiceValue: false,
    priceDetails: {
        totalAmount: 0,
        noOfInstallments: 0,
        installmentAmount: 0,
        firstInstallmentAmount: 0,
        hasEqualInstallments: false,
        singlePaymentAmount:0
    },
    loading: false,
    errors: {}
}

const ManageProductPage = ({ productType, productsAll, addProductToBasket, nextPage, previousPage, loadProducts, updateProducts }) => {

    const [state, dispatch] = useReducer(ProductReducer, initialState)

    const { durationLookup, claimLimitLookup, gapTypeLookup,
        durationSelected, claimLimitSelected, gapTypeSelected,
        productNotAvailable, invoiceValue, productLoadedViaInvoiceValue,
        priceDetails, loading, errors } = state;

    const { productPageCustomisations, defactoVisible, googleAnalyticsEnable, brandName } = useContext(ThemeContext);

    let pageDisplayDetails = '';
    let defaqtoImage = '';
    let productImageClass = '';
    let products = [];

    const initialise = () => {

        if (productPageCustomisations !== undefined) {
            pageDisplayDetails = _.filter(productPageCustomisations.products, p => p.type === productType)[0];
        }

        products = _.filter(productsAll, p => p.productTypeId === productType);

        initialiseImages();
    }

    useEffect(() => {

        reset();
        dispatch({ type: 'update-product-loaded-via-invoice-value', productLoadedViaInvoiceValue: false });
        dispatch({ type: 'update-invoice-value', invoiceValue: '' });
        dispatch({ type: 'update-product-not-available', productNotAvailable: (products.length < 1) && (productType != productTypeEnum.GAP)});
        dispatch({ type: 'update-duration-lookup', durationLookup: getDurationLookup(products) });

    }, [productType])

    const reset = () => {
        dispatch({ type: 'update-errors', errors: {} });

        dispatch({ type: 'update-duration-selected', durationSelected: SelectInput.NullValue() });
        dispatch({ type: 'update-claim-limit-selected', claimLimitSelected: SelectInput.NullValue() });
        dispatch({ type: 'update-gap-type-selected', gapTypeSelected: SelectInput.NullValue() });

        dispatch({ type: 'update-claim-limit-lookup', claimLimitLookup: [] });
        dispatch({ type: 'update-duration-lookup', durationLookup: [] });
        dispatch({ type: 'update-gap-type-lookup', gapTypeLookup: [] });      

        dispatch({ type: 'update-price-details', priceDetails: {} });
    }

    const initialiseImages = () => {
        switch (productType) {
            case productTypeEnum.ALLOY:
                defaqtoImage = DefaqtoAlloy;
                productImageClass = 'product-image-alloy';
                break;
            case productTypeEnum.COSMETIC:
                defaqtoImage = DefaqtoDamage;
                productImageClass = 'product-image-cosmetic';
                break;
            case productTypeEnum.GAP:
                defaqtoImage = DefaqtoGap;
                productImageClass = 'product-image-gap';
                break;
            case productTypeEnum.TYRE:
                defaqtoImage = DefaqtoTyre;
                productImageClass = 'product-image-tyre';
                break;
        }
    }

    const getDurationLookup = (products) => {
        let lookupItemsDuration = _.map(_.uniqBy(products, i => i.duration), ui => ui.duration);
        return getLookupItemsAsOptions(lookupItemsDuration, 'months');
    }

    const getLookupItemsAsOptions = (lookupItems, text) => {

        let items = [];

        if (lookupItems === undefined || lookupItems === null) return items;

        lookupItems.sort();

        for (var index = 0; index < lookupItems.length; index++) {
            var lookupOption =
            {
                "id": lookupItems[index],
                "text": lookupItems[index] + ' ' + text,
                "value": lookupItems[index],
                "disabled": false
            };
            items.push(lookupOption);
        }
        return items;
    }

    const onChangeDuration = (e) => {

        dispatch({ type: 'update-errors', errors: {} });
        dispatch({ type: 'update-duration-selected', durationSelected: e.target.value });
        dispatch({ type: 'update-claim-limit-selected', claimLimitSelected: SelectInput.NullValue() });

        setClaimLimitLookUp(e.target.value);
    }

    const onChangeClaimLimit = (e) => {
        dispatch({ type: 'update-errors', errors: {} });
        dispatch({ type: 'update-claim-limit-selected', claimLimitSelected: e.target.value });
    }

    const onChangeInvoiceValue = (e) => {
        dispatch({ type: 'update-invoice-value', invoiceValue: e.target.value });
    }

    const setClaimLimitLookUp = (duration) => {

        let lookupItemsClaimLimit = _.filter(products, i => i.duration === parseInt(duration)
            && i.singleClaimLimit > 0);

        let lookupItemsClaimLimitUnique = _.map(_.uniqBy(lookupItemsClaimLimit, i => i.singleClaimLimit),
            ui => ui.singleClaimLimit);

        lookupItemsClaimLimitUnique = lookupItemsClaimLimitUnique.length > 1 ? lookupItemsClaimLimitUnique : [];

        dispatch({ type: 'update-claim-limit-lookup', claimLimitLookup: getLookupItemsAsOptions(lookupItemsClaimLimitUnique, '') });
    }

    const onFindProductClick = () => {

        if (!validateInvoiceValue())
            return;

        dispatch({ type: 'update-loading', loading: true });

        reset();

        loadProducts(invoiceValue, updateGapProducts);
    }

    const updateGapProducts = (productsWithInvoiceValueFilter) => {

        let gapProductsWithInvoiceFilter = _.filter(productsWithInvoiceValueFilter, p => p.productTypeId === productTypeEnum.GAP);
        let nonGapProductsWithoutInvoiceFilter = _.filter(productsAll, p => p.productTypeId != productTypeEnum.GAP);
        let updatedWithGapProducts = _.concat(gapProductsWithInvoiceFilter, nonGapProductsWithoutInvoiceFilter);

        updateProducts(updatedWithGapProducts, invoiceValue);

        dispatch({ type: 'update-loading', loading: false });
        dispatch({ type: 'update-product-loaded-via-invoice-value', productLoadedViaInvoiceValue: true });

        products = _.filter(updatedWithGapProducts, p => p.productTypeId === productType);

        if (showGapTypeDropDown()) {
            dispatch({ type: 'update-gap-type-lookup', gapTypeLookup: getGapTypeLookup() });
        }
        else {
            dispatch({ type: 'update-duration-lookup', durationLookup: getDurationLookup(products) });
        }
    }

    const showGapTypeDropDown = () => {
        let hasGapPlus = _.some(products, (p => p.isGapPlus));
        let hasGap = _.some(products, (p => !p.isGapPlus));
        let showGapType = hasGapPlus && hasGap;

        return showGapType;
    }

    const getGapTypeLookup = () => {
        let lookupItemsGapType = [gapTypes.GAP, gapTypes.GAPPLUS];
        return getLookupItemsAsOptions(lookupItemsGapType, '');
    }

    const onChangeGapType = (e) => {
        dispatch({ type: 'update-errors', errors: {} });

        dispatch({ type: 'update-gap-type-selected', gapTypeSelected: e.target.value });
        dispatch({ type: 'update-duration-selected', durationSelected: SelectInput.NullValue() });
        dispatch({ type: 'update-claim-limit-selected', claimLimitSelected: SelectInput.NullValue() });   

        let productsFiltered = SelectInput.isNullValue(e.target.value)
            ? []
            : _.filter(products, p => p.isGapPlus == isGapPlus(e.target.value));

        dispatch({ type: 'update-duration-lookup', durationLookup: getDurationLookup(productsFiltered) });
    }

    const isGapPlus = (value) => {
        let isGapPlus = value === gapTypes.GAPPLUS;

        return isGapPlus;
    }

    const validateInvoiceValue = () => {
        let isValid = true;
        let invoiceErrors = {};

        if (CommonValidation.isEmpty(invoiceValue)) {
            isValid = false;
            invoiceErrors.invoiceValue = 'Invoice value is mandatory';
        }

        if (!CommonValidation.isValidDecimal(invoiceValue)) {
            isValid = false;
            invoiceErrors.invoiceValue = 'Kindly provide a valid invoice value';
        }

        dispatch({ type: 'update-errors', errors: invoiceErrors });
        return isValid;
    }

    const validate = () => {

        let isValid = true;
        let productErrors = {};

        if (showGapTypeDropDown() && (CommonValidation.isEmpty(gapTypeSelected) || SelectInput.isNullValue(gapTypeSelected))) {
            isValid = false;
            productErrors.gapType = 'Gap product is mandatory';
        }

        if (CommonValidation.isEmpty(durationSelected) || SelectInput.isNullValue(durationSelected)) {
            isValid = false;
            productErrors.duration = 'Duration is mandatory';
        }

        if (claimLimitLookup.length > 0 && (CommonValidation.isEmpty(claimLimitSelected) || SelectInput.isNullValue(claimLimitSelected))) {
            isValid = false;
            productErrors.claimLimit = 'Claim limit is mandatory'
        }

        dispatch({ type: 'update-errors', errors: productErrors });

        return isValid;
    }

    const getSelectedProducts = () => {

        let productsSelected = _.filter(products, i => i.duration === parseInt(durationSelected));

        if (showGapTypeDropDown()) {
            productsSelected = _.filter(productsSelected, i => i.isGapPlus === isGapPlus(gapTypeSelected));
        }

        if (claimLimitLookup.length > 0) {
            productsSelected = _.filter(productsSelected, i => i.singleClaimLimit === parseInt(claimLimitSelected));
        }

        return productsSelected;
    }

    useEffect(() => {

        updatePrice();

    }, [durationSelected, claimLimitSelected, gapTypeSelected])

    const updatePrice = () => {

        if (CommonValidation.isEmpty(durationSelected) || SelectInput.isNullValue(durationSelected)
            ||
            (claimLimitLookup.length > 0 && (CommonValidation.isEmpty(claimLimitSelected) || SelectInput.isNullValue(claimLimitSelected)))) {

            dispatch({ type: 'update-price-details', priceDetails: {} });
        }

        let productsSelected = getSelectedProducts();

        let directDebitProduct = productsSelected.filter(p => CommonValidation.isNotEmpty(p.paymentMethods)
            && p.paymentMethods.includes(paymentMethod.DIRECTDEBIT))[0];

        let singlePaymentProduct = productsSelected.filter(p => CommonValidation.isNotEmpty(p.paymentMethods)
            && p.paymentMethods.includes(paymentMethod.CREDITCARD))[0];

        let priceInfo = {
            totalAmount: 0,
            noOfInstallments: 0,
            installmentAmount: 0,
            firstInstallmentAmount: 0,
            hasEqualInstallments: false,
            singlePaymentAmount:0
        };

        if (CommonValidation.isNotEmpty(directDebitProduct)) {
            console.log('Direct debit variant Id: ' + directDebitProduct.warrantyVariantId + ' Price: ' + directDebitProduct.price)

            let priceHelper = new PriceHelper();
            priceInfo = priceHelper.getPriceDetailsDirectDebit(directDebitProduct.price, directDebitProduct.noOfInstallments)
        }

        if (CommonValidation.isNotEmpty(singlePaymentProduct)) {
            console.log('Single payment variant Id: ' + singlePaymentProduct.warrantyVariantId + ' Price: ' + singlePaymentProduct.price)

            priceInfo.singlePaymentAmount = singlePaymentProduct.price;
        }

        dispatch({ type: 'update-price-details', priceDetails: priceInfo });
    }

    const addToBasket = () => {

        if (validate()) {

            GoogleAnalytics.trackEventsGoogleAnalytics(
                "Button click",
                'Added product to shopping basket',
                `${brandName} - add item to shopping basket`,               
                googleAnalyticsEnable);

            let productsSelected = getSelectedProducts();

            //Multiple payment methods/prices come back for each type of cover. This allows a condensed object to be mapped later.
            let bundledProducts = [];
            for (var index = 0; index < productsSelected.length; index++) {
                bundledProducts.push(productsSelected[index]);
            }

            addProductToBasket(bundledProducts);
        }
    }

    initialise();

    return (
        <ProductPage
            productNotAvailable={productNotAvailable}
            pageDisplayDetails={pageDisplayDetails}
            durationLookup={durationLookup}
            gapTypeLookup={gapTypeLookup}
            claimLimitLookup={claimLimitLookup}
            durationSelected={durationSelected}
            claimLimitSelected={claimLimitSelected}
            gapTypeSelected={gapTypeSelected}
            productType={productType}
            invoiceValue={invoiceValue}
            priceDetails={priceDetails}
            loading={loading}
            productLoadedViaInvoiceValue={productLoadedViaInvoiceValue}
            defaqtoImage={defaqtoImage}
            productImageClass={productImageClass}
            errors={errors}
            onChangeDuration={onChangeDuration}
            onChangeClaimLimit={onChangeClaimLimit}
            onChangeGapType={onChangeGapType}
            showGapTypeDropDown={showGapTypeDropDown}
            onChangeInvoiceValue={onChangeInvoiceValue}
            onFindProductClick={onFindProductClick}
            addToBasket={addToBasket}
            continueClicked={nextPage}
            backClicked={previousPage}
            defactoVisible={defactoVisible}
        />    
    );
}

ManageProductPage.propTypes = {
    productType: PropTypes.number.isRequired,
    productsAll: PropTypes.array,
    addProductToBasket: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    loadProducts: PropTypes.func.isRequired
}

export default ManageProductPage;