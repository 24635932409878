import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { SelectInput } from '../../common/selectInput/selectInput';
import { ClientDateHandler } from '../../../classes/clientDateHandler';
import { TextInput } from '../../common/textInput/textInput';
import { DateInput } from '../../common/dateInput';
import { CommonValidation } from '../../common/commonValidation/commonValidation';
import { tabletResponsiveResolutionWidth } from '../../../classes/constants';
import { vehicleLookup } from '../../../classes/enums';
import ReCaptchaBlurb from './reCaptchaBlurb';
import Disclaimer from './disclaimer';

const VehicleSelectForm = ({ vehicleDetails, vehicleDetailErrors, onChangeDetail, onChangeDetailWithLookup, vehicleTypes, vehicleMakes, vehicleModels,
    vehicleFuelTypes, vehicleEngineSizes, vehicleModelTrims, onLeaveVehicleReg, manualQuerySelections, bearerToken }) => {

    const [formattedFirstRegDate, setFormattedFirstRegDate] = useState(null);

    const mapDateForServer = (dateJson) => {
        var cdh = new ClientDateHandler();
        cdh.parseJSON(dateJson);

        var localDate = cdh.getLocalDate();
        return localDate;
    }

    useEffect(() => {
        setFormattedFirstRegDate(mapDateForServer(vehicleDetails.firstRegistrationDate));
    }, [vehicleDetails.firstRegistrationDate]);

    const showVehicleMake = !SelectInput.isNullValue(vehicleDetails.vehicleType) && !CommonValidation.isEmpty(vehicleDetails.vehicleType);
    const showVehicleModel = showVehicleMake && !SelectInput.isNullValue(vehicleDetails.make) && !CommonValidation.isEmpty(vehicleDetails.make);
    const showFuelType = showVehicleModel && !SelectInput.isNullValue(vehicleDetails.model) && !CommonValidation.isEmpty(vehicleDetails.model);
    const showEngineSize = showFuelType && !SelectInput.isNullValue(vehicleDetails.fuelType) && !CommonValidation.isEmpty(vehicleDetails.fuelType);
    const showModelType = showEngineSize && !SelectInput.isNullValue(vehicleDetails.engineSize) && !CommonValidation.isEmpty(vehicleDetails.engineSize);


    return (
        <Fragment>
            <div className="row">
                <div className="col-auto">
                    <TextInput
                        className="registration"
                        haslabel
                        name="vehicle-registration-manual"
                        label="Vehicle registration"
                        error={vehicleDetailErrors.registration}
                        value={vehicleDetails.registration}
                        onChange={(e) => onChangeDetail(e, 'registration')}
                        onLeave={onLeaveVehicleReg}
                    />
                    <ReCaptchaBlurb />
                    <Disclaimer />
                </div>
                {!CommonValidation.isEmpty(bearerToken) && <div className="col-auto">
                    <DateInput
                        haslabel
                        label="First registration date"
                        name="first-registration-date"
                        placeholder="Select Date..."
                        forceTimeReset
                        useJson
                        error={vehicleDetailErrors.firstRegistrationDate}
                        value={vehicleDetails.firstRegistrationDate}
                        displayMinWidth={tabletResponsiveResolutionWidth}
                        onDateChange={(e) => { onChangeDetailWithLookup(e, 'firstRegistrationDate', vehicleLookup.TYPES) }}
                    />
                </div>}
            </div>
            <div className="row">
                {formattedFirstRegDate != null &&
                    <div className="col-auto">
                        <SelectInput
                            name="vehicle_type"
                            label="Vehicle type"
                            haslabel
                            value={manualQuerySelections.vehicleTypeId}
                            options={vehicleTypes}
                            onChange={(e) => { onChangeDetailWithLookup(e, 'vehicleType', vehicleLookup.MAKES) }}
                            defaultText='Select Vehicle Type...'
                            forceDefault
                            addDefaultOption
                            error={vehicleDetailErrors.vehicleType}
                        />
                    </div>}

                {showVehicleMake &&
                    <div className="col-auto">
                        <SelectInput
                            name="vehicle_make"
                            label="Vehicle make"
                            haslabel
                            value={manualQuerySelections.vehicleMakeId}
                            options={vehicleMakes}
                            onChange={(e) => { onChangeDetailWithLookup(e, 'make', vehicleLookup.MODEL) }}
                            defaultText='Select Vehicle Make...'
                            forceDefault
                            addDefaultOption
                            error={vehicleDetailErrors.make}
                        />
                    </div>}
            </div>
            <div className="row">
                {showVehicleModel &&
                    <div className="col-auto">
                        <SelectInput
                            name="vehicle-model"
                            label="Vehicle model"
                            haslabel
                            value={manualQuerySelections.vehicleModelId}
                            options={vehicleModels}
                            onChange={(e) => { onChangeDetailWithLookup(e, 'model', vehicleLookup.FUELTYPE) }}
                            defaultText='Select Vehicle Model...'
                            forceDefault
                            addDefaultOption
                            error={vehicleDetailErrors.model}
                        />
                    </div>}
                {showFuelType &&
                    <div className="col-auto">
                        <SelectInput
                            name="fuel-type"
                            label="Fuel type"
                            haslabel
                            value={manualQuerySelections.vehicleFuelTypeId}
                            options={vehicleFuelTypes}
                            onChange={(e) => { onChangeDetailWithLookup(e, 'fuelType', vehicleLookup.ENGINESIZE) }}
                            defaultText='Select Fuel Type...'
                            forceDefault
                            addDefaultOption
                            error={vehicleDetailErrors.fuelType}
                        />
                    </div>}
            </div>
            <div className="row">
                {showEngineSize &&
                    <div className="col-auto">
                        <SelectInput
                            name="engine-size"
                            label="Engine size"
                            haslabel
                            value={manualQuerySelections.vehicleEngineSize}
                            options={vehicleEngineSizes}
                            onChange={(e) => { onChangeDetailWithLookup(e, 'engineSize', vehicleLookup.MODELTRIM) }}
                            defaultText='Select Engine Size...'
                            forceDefault
                            addDefaultOption
                            error={vehicleDetailErrors.engineSize}
                        />
                    </div>}
                {showModelType &&
                    <div className="col-auto">
                        <SelectInput
                            name="model-type"
                            label="Model type"
                            haslabel
                            value={manualQuerySelections.vehicleModelTrimId}
                            options={vehicleModelTrims}
                            onChange={(e) => { onChangeDetailWithLookup(e, 'modelType', vehicleLookup.FINISHED) }}
                            defaultText='Select Model Type...'
                            forceDefault
                            addDefaultOption
                            error={vehicleDetailErrors.modelType}
                        />
                    </div>}
            </div>
        </Fragment>


    );
}

export default VehicleSelectForm;

VehicleSelectForm.propTypes = {
    vehicleDetails: PropTypes.object.isRequired,
    vehicleDetailErrors: PropTypes.object,
    onChangeDetail: PropTypes.func.isRequired,
    onChangeDetailWithLookup: PropTypes.func.isRequired,
    onLeaveVehicleReg: PropTypes.func.isRequired,  
    vehicleTypes: PropTypes.arrayOf(PropTypes.object),
    vehicleMakes: PropTypes.arrayOf(PropTypes.object),
    vehicleModels: PropTypes.arrayOf(PropTypes.object),
    vehicleFuelTypes: PropTypes.arrayOf(PropTypes.object),
    vehicleEngineSizes: PropTypes.arrayOf(PropTypes.object),
    vehicleModelTrims: PropTypes.arrayOf(PropTypes.object),
    bearerToken: PropTypes.string,
};