const ProductReducer = (state, action) => {
    switch (action.type) {

        case 'update-duration-lookup':
            return {
                ...state,
                durationLookup: action.durationLookup
            };

        case 'update-claim-limit-lookup':
            return {
                ...state,
                claimLimitLookup: action.claimLimitLookup
            };

        case 'update-gap-type-lookup':
            return {
                ...state,
                gapTypeLookup: action.gapTypeLookup
            };

        case 'update-duration-selected':
            return {
                ...state,
                durationSelected: action.durationSelected
            };

        case 'update-claim-limit-selected':
            return {
                ...state,
                claimLimitSelected: action.claimLimitSelected
            };

        case 'update-gap-type-selected':
            return {
                ...state,
                gapTypeSelected: action.gapTypeSelected
            };

        case 'update-product-not-available':
            return {
                ...state,
                productNotAvailable: action.productNotAvailable
            };

        case 'update-price-details':
            return {
                ...state,
                priceDetails: action.priceDetails
            };

        case 'update-invoice-value':
            return {
                ...state,
                invoiceValue: action.invoiceValue
            };

        case 'update-loading':
            return {
                ...state,
                loading: action.loading
            };

        case 'update-product-loaded-via-invoice-value':
            return {
                ...state,
                productLoadedViaInvoiceValue: action.productLoadedViaInvoiceValue
            };

        case 'update-errors':
            return {
                ...state,
                errors: action.errors
            };

        default:
            return state;
    }
}

export default ProductReducer;
