import React from 'react'
import PropTypes from 'prop-types';
import DirectDebitLogo from '../../../images/directdebitlogoSm.png'
import './purchasePage.scss';

const DirectDebitConfirmation = ({ brandAdminText }) => {

    return (
        <div className="card card-direct-debit my-2">
            <div className="card-header card-header-direct-debit">
                The Direct Debit Guarantee
            </div>
            <div className="card-body">
                <div className="d-flex flex-row">
                    <div>
                        <div className="direct-debit-logo-container">
                            <img src={DirectDebitLogo} alt="direct debit logo" />
                        </div>
                        <ul className="bullet">
                            <li>This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits</li>
                            <li>If there are any changes to the amount, date or frequency of your Direct Debit
                                <span className="ml-1">{brandAdminText}</span> will notify you 10 working days in advance of your account being debited or as otherwise agreed. If you request
                                <span className="ml-1">{brandAdminText}</span> to collect a payment, confirmation of the amount and date will be given to you at the time of the request
                            </li>
                            <li>
                                If an error is made in the payment of your Direct Debit, by <span>{brandAdminText}</span> or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society
                                <ul>
                                    <li>If you receive a refund you are not entitled to, you must pay it back when <span>{brandAdminText}</span> asks you to.</li>
                                </ul>
                            </li>
                            <li>
                                You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify us.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

DirectDebitConfirmation.propTypes = {
    brandAdminText: PropTypes.string.isRequired
}

export default DirectDebitConfirmation;
