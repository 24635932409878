import { ApiHelper } from './apiHelper';

export class CustomerApi {

    loadAddresses(postcode, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var query = encodeURI(`?postcode=${postcode || ''}`);
        var dataUrl = '/api/customer/address' + query;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }

    loadAddress(moniker, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = `/api/customer/address/${moniker}`;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }

}