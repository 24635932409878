import React from 'react';
import PropTypes from 'prop-types';

const PriceTotalDisplay = ({ listContainerStyle, listElementStyle, totals, listEndSubtitle, decimalPlaces }) => {
    const totalListElements = totals.map(total => {
        return total.amount > 0 ? <li key={total.title} className={listElementStyle}><b>{total.title}: £{total.amount.toFixed(decimalPlaces)}</b></li> : <React.Fragment/>
    })

    return (
        <ul className={listContainerStyle}>
            {totalListElements}
            {listEndSubtitle != undefined && <li>{listEndSubtitle}</li>}
        </ul>
    );
}

export default PriceTotalDisplay;

PriceTotalDisplay.propTypes = {
    listContainerStyle: PropTypes.string,
    listElementStyle: PropTypes.string,
    totals: PropTypes.array,
    listEndSubtitle: PropTypes.string,
    decimalPlaces: PropTypes.number
}

PriceTotalDisplay.defaultProps = {
    listEndSubtitle: undefined,
    decimalPlaces: 2
}