import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

export const Button = (props) => {

    const [busy, setBusy] = useState(false);

    // add a handler to trim the values on leave ...
    const handleClick = (e, setBusyFunction) => {
        e.preventDefault();

        let callbackFunction = () => { };

        if (props.waitOnClick) {

            if (setBusyFunction != null)
                setBusyFunction(true);

            callbackFunction = () => {
                if (setBusyFunction != null)
                    setBusyFunction(false);
            };
        }

        if (typeof (props.onClick) == 'function')
            props.onClick(callbackFunction);
    };

    const {
        text,
        waitValue,
        waitText,
        waitOnClick,
        onClick,
        disabled,
        className,
        ...otherprops
    } = props;

    let buttonControl = null;
    let disabledClassName = className;
    if (disabled)
        disabledClassName += ' disabled';

    if (!disabled)
        buttonControl = (<button
            onClick={(e) => { handleClick(e, setBusy); }}
            className={className}
            {...otherprops}>

            {text}
        </button>);
    else
        buttonControl = (<button
            className={disabledClassName}
            disabled
            {...otherprops}>
            {text}
        </button>);

    const showWaiting = ((waitValue != undefined) && waitValue) || (waitOnClick && busy);
    if (showWaiting) {
        buttonControl =
            (<button
                className={className}
                {...otherprops}
                disabled={disabled}>
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
                { waitText || '' }
            </button >
            );
    }

return (
    <Fragment>
        {buttonControl}
    </Fragment>
);
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    waitOnClick: PropTypes.bool,
    text: PropTypes.string,
    waitText: PropTypes.string,
    waitValue: PropTypes.bool,
    disabled: PropTypes.bool,
};

