import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Layout } from './components/layout';
import LandingPage from './components/landing/landingPage';
import SagePayLoadingPage from './components/main/purchase/sagePayLoadingPage';
import ManageMainPage from './components/main/manageMainPage';
import { ThemeContext } from './context/themeContext';
import { customisationApi } from "./api/customisationApi";
import { BasketProvider } from './context/basketProvider';
import ErrorPage from './components/error/errorPage';
import { GoogleAnalytics } from './classes/googleAnalytics';
import './index.scss'
import { CacheBuster } from './cacheBuster';
import { useQuery } from './components/common/customHooks/useQuery';

const App = () => {
    const [customisations, setCustomisations] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [dealerCode, setDealerCode] = useState();
    const history = useHistory();
    const queryObject = useQuery();

    useEffect(() => {

        const successCallback = (data) => {
            setCustomisations(data);
            setIsLoading(false);
            setFavicon(data?.theme);
            GoogleAnalytics.initialiseGoogleAnalytics(data);
        }

        const failureCallback = (err) => {
            setIsLoading(false);
            console.log(err);
            history.push('/error');
        }

        const urlDestructured = window.location.pathname.split('/');

        let dealerCodeSegment = urlDestructured[urlDestructured.length - 1] === ""
            ? urlDestructured[urlDestructured.length - 2]
            : urlDestructured[urlDestructured.length - 1];

        setDealerCode(dealerCodeSegment);
        new customisationApi().getCustomisations(dealerCodeSegment, successCallback, failureCallback);
    }, []);

    const setFavicon = (theme) => {
        const favIcon = document.getElementById("favicon");
        favIcon.href = `${theme}-favicon.ico`;
    }

    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!isLatestVersion) {
                    refreshCacheAndReload();
                }

                return (
                    <React.Fragment>

                        {!isLoading && (
                            <ThemeContext.Provider value={{
                                theme: `theme-${customisations?.theme}`,
                                landingPageCustomisations: customisations?.landingPageCustomisations,
                                productPageCustomisations: customisations?.productPageCustomisations,
                                paymentPageCustomisations: customisations?.paymentPageCustomisations,
                                customerPageCustomisations: customisations?.customerDetailPageCustomisations,
                                themeRaw: customisations?.theme,
                                brandName: customisations?.brandName,
                                businessPartnerId: customisations?.businessPartnerId,
                                businessPartnerName: customisations?.businessPartnerName,
                                dealerCode,
                                captchaKey: customisations?.captchaKey,
                                captchaDisable: customisations?.captchaDisable,
                                defactoVisible: customisations?.defactoVisible,
                                iconVisible: customisations?.iconVisible,
                                googleAnalyticsEnable: customisations?.googleAnalyticsEnable,
                                googleAnalyticsTrackingId: customisations?.googleAnalyticsTrackingId
                            }}>
                                <BasketProvider>
                                    <Layout>
                                        {queryObject.transaction != null
                                            ? <Redirect to='/sagepay' />
                                            : <Switch>
                                                <Route exact path='/error' component={ErrorPage} />
                                                <Route exact path='/sagepay' component={SagePayLoadingPage} />
                                                <Route exact path='/:brand?/main' component={ManageMainPage} />
                                                <Route exact path='/:brand?/:dealerCode' component={LandingPage} />
                                            </Switch>}
                                    </Layout>
                                </BasketProvider>

                            </ThemeContext.Provider >
                        )}
                    </React.Fragment>
                );
            }}
        </CacheBuster>
    );

}

export default App;
