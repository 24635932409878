import { ApiHelper } from './apiHelper';

export class ProductApi {

    loadProducts(productFilter, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var url = '/api/product/products';
        new ApiHelper().postDataWithResponse(url, productFilter, callback, failureCallback, headers);
    }
}