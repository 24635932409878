import React from 'react';
import _ from 'lodash';
import '../validation.scss';
import {CommonValidation} from '../commonValidation/commonValidation'

export class SelectInput extends React.Component
{
    static NullValue() {
        return '(null)';
    }

    static isNullValue(value)
    {
        let isNullValue = (value == SelectInput.NullValue()) || (value == null);
        return isNullValue;
    }

    static isEmptyValue(value) {
        let isEmptyValue = (value === '');
        return isEmptyValue;
    }

    static isValidValue(value) {
        var isValid = !SelectInput.isNullValue(value);
        return isValid;
    }

    componentDidMount() {
        const valueOptions = this.props.options;
        if ((valueOptions == null) || (valueOptions.length === 0)) {
            return;
        }

        this.setToDefaultWhenValueMissing(valueOptions);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.options == null)) {
            return;
        }

        // changing options ..
        var optionsChange = this.optionsHaveChanged(prevProps.options, this.props.options);
        if (optionsChange) {
            this.setToDefaultWhenValueMissing(this.props.options);
        }
    }    

    optionsHaveChanged = (previousOptions, currentOptions) => {
        if (previousOptions == null)
            return true;

        if (previousOptions.length !== currentOptions.length)
            return true;

        let hasDifference = false;
        let i = 0;
        for (i = 0; i < currentOptions.length; i++) {
            if (previousOptions[i].value !== currentOptions[i].value) {
                hasDifference = true;
                break;
            }
        }

        return hasDifference;
    }

    setToDefaultWhenValueMissing = (valueOptions) => {
        if (this.props.forceDefault === true) {

            const readOnly = this.props.readonly === true;
            if (!readOnly) {
                const controlValue = this.props.value;

                let defaultIndex = _.findIndex(valueOptions, o => o.value == controlValue);

                if (defaultIndex === -1) {
                    const nullValue = SelectInput.NullValue();

                    let noDefaultValueOptions = _.filter(
                        valueOptions,
                        (o) => o.value !== nullValue
                    );

                    if (this.props.defaultIfOneOption === true && noDefaultValueOptions.length === 1) {
                        this.props.onChange({ target: { value: noDefaultValueOptions[0].value } });
                    }
                    else {
                        this.props.onChange({ target: { value: nullValue } });
                    }
                }
            }
        }
    }

    getOptgroupTags = (groups) =>
    {
        var optgroups = groups.map((group) => {
            var optionsByGroup = this.props.options.filter((v) => { return v.group === group.id; });
            var children = this.getOptionTags(optionsByGroup);

            let optgroupTag = '';
            if (children.length > 0)
                optgroupTag = (
                    <optgroup disabled={group.disabled} key={group.id} label={group.name}>
                        {children}
                    </optgroup>
                );

            return optgroupTag;
        });

        return optgroups;
    }

    getOptionTags = (options) => {
        let optionValues = [];
        if (this.props.addDefaultOption) {
            let defaultText = CommonValidation.isNotEmpty(this.props.defaultText) ? this.props.defaultText : 'Select...';
            optionValues.push((<option key={-1} value={SelectInput.NullValue()}>{defaultText}</option>));

        }


        for (var index = 0; index < options.length; index++) {
            var opt = options[index];
            const optionMarkup = <option disabled={opt.disabled} key={opt.id} value={opt.value}>{opt.text}</option>;
            optionValues.push(optionMarkup);
        }

        return optionValues;
    }

    getOptionValues = () => {
        let loadingText = this.props.loadingText || 'Loading ...';

        let selectOptions = '';
        if (this.props.optionGroups && this.props.optionGroups.length > 0)
            selectOptions = this.getOptgroupTags(this.props.optionGroups);
        else
            if (this.props.options != null && this.props.options.length > 0)
                selectOptions = this.getOptionTags(this.props.options);
            else {
                selectOptions =
                    [
                        <option key={1} value={SelectInput.NullValue()}>{loadingText}</option>
                    ];
            }

        return selectOptions;
    }

    render = () =>
    {
        var wrapperClass = 'form-group';
        if (this.props.error && this.props.error.length > 0)
           wrapperClass += " " + 'has-error';

        let selectOptions = this.getOptionValues();

        let selectControl = (
            <select 
                value={this.props.value}
                className="form-control"
                multiple={this.props.multiselect}
                name={this.props.name}
                onChange={this.props.onChange}
            >
                {selectOptions}
            </select>
        );

        const readOnly = this.props.readonly === true;
        if (readOnly) {
            selectControl = (
                <select disabled
                    value={this.props.value}
                    className="form-control"
                    multiple={this.props.multiselect}
                    name={this.props.name}
                    onChange={this.props.onChange}
                >
                    {selectOptions}
                </select>
            );
        }

        let classes = 'field';
        if ((this.props.className != null) && (this.props.className != undefined))
            classes += (' ' + this.props.className);

        var labelHtml = '';
        if (this.props.haslabel && this.props.haslabel === true)
            labelHtml = (
                <label htmlFor={this.props.name}>{this.props.label}:</label>
            );

        return (
            <div className={wrapperClass}>
                {labelHtml}
                <div className={classes} >
                   {selectControl}
                </div>
                <div className="input">
                    {this.props.error}
                </div>
            </div>
       );
    }
}

