import { SelectInput } from '../../common/selectInput/selectInput';

const VehicleDetailsReducer = (state, action) => {
    switch (action.type) {
        case 'update-errors':
            return {
                ...state,
                vehicleDetailErrors: action.vehicleDetailErrors
            }

        case 'update-finding':
            return {
                ...state,
                finding: action.finding
            }
        case 'set-vehicle-types':
            return {
                ...state,
                vehicleTypes: action.vehicleTypes
            }
        case 'set-makes':
            return {
                ...state,
                vehicleMakes: action.vehicleMakes
            }
        case 'set-models':
            return {
                ...state,
                vehicleModels: action.vehicleModels
            }
        case 'set-fuel-types':
            return {
                ...state,
                vehicleFuelTypes: action.vehicleFuelTypes
            }
        case 'set-engine-sizes':
            return {
                ...state,
                vehicleEngineSizes: action.vehicleEngineSizes
            }
        case 'set-model-trims':
            return {
                ...state,
                vehicleModelTrims: action.vehicleModelTrims
            }
        case 'vehicle-found':
            return {
                ...state,
                FoundVehicleOnSearch: action.FoundVehicleOnSearch
            }
        case 'update-manual-query':
            return {
                ...state,
                manualQuerySelections: {
                    ...state.manualQuerySelections,
                    [action.property]: action.value
                }
            }
        case 'clear-manual-lookups':
            return {
                ...state,
                vehicleTypes: [],
                vehicleMakes: [],
                vehicleModels: [],
                vehicleFuelTypes: [],
                vehicleEngineSizes: [],
                vehicleModelTrims: [],
            }
        case 'reset-type-neutral':
            return {
                ...state,
                manualQuerySelections: {
                    ...state.manualQuerySelections,
                    vehicleTypeId: SelectInput.NullValue(),
                    vehicleMakeId: SelectInput.NullValue(),
                    vehicleModelId: SelectInput.NullValue(),
                    vehicleFuelTypeId: SelectInput.NullValue(),
                    vehicleEngineSize: SelectInput.NullValue(),
                    vehicleModelTrimId: SelectInput.NullValue()
                }
            }
        case 'reset-make-neutral':
            return {
                ...state,
                manualQuerySelections: {
                    ...state.manualQuerySelections,
                    vehicleMakeId: SelectInput.NullValue(),
                    vehicleModelId: SelectInput.NullValue(),
                    vehicleFuelTypeId: SelectInput.NullValue(),
                    vehicleEngineSize: SelectInput.NullValue(),
                    vehicleModelTrimId: SelectInput.NullValue()
                }
            }
        case 'reset-model-neutral':
            return {
                ...state,
                manualQuerySelections: {
                    ...state.manualQuerySelections,
                    vehicleModelId: SelectInput.NullValue(),
                    vehicleFuelTypeId: SelectInput.NullValue(),
                    vehicleEngineSize: SelectInput.NullValue(),
                    vehicleModelTrimId: SelectInput.NullValue()
                }
            }
        case 'reset-fuel-type-neutral':
            return {
                ...state,
                manualQuerySelections: {
                    ...state.manualQuerySelections,
                    vehicleFuelTypeId: SelectInput.NullValue(),
                    vehicleEngineSize: SelectInput.NullValue(),
                    vehicleModelTrimId: SelectInput.NullValue()
                }
            }
        case 'reset-engine-size-neutral':
            return {
                ...state,
                manualQuerySelections: {
                    ...state.manualQuerySelections,
                    vehicleEngineSize: SelectInput.NullValue(),
                    vehicleModelTrimId: SelectInput.NullValue()
                }
            }
        case 'reset-model-type-neutral':
            return {
                ...state,
                manualQuerySelections: {
                    ...state.manualQuerySelections,
                    vehicleModelTrimId: SelectInput.NullValue()
                }
            }
        default:
            return state;
    }
}

export default VehicleDetailsReducer;
