import React, { Component } from 'react';
import '../validation.scss';
import { CommonValidation } from '../commonValidation/commonValidation';

const ValidationTriggerType = {
    TriggerFieldChange: 1,
    TriggerFieldExit: 2,
    TriggerManual: 3,
}
export class TextInput extends React.Component {
    static isValidValue(value) {
        var isValid = !((value == null) || (value == '') || (value.length == 0));
        return isValid;
    }

    componentDidUpdate(prevProps) {
        this.handleComponentDidUpdate(prevProps, this.props);
    }

    handleComponentDidUpdate(prevProps, currentProps) {
        var validateManualTrigger = this.props.validateManual && !prevProps.validateManual;
        if (validateManualTrigger) {
            let textValue = this.props.value;
            this.handleValidate(textValue, ValidationTriggerType.TriggerManual);
        }
    }

    onChange = (e) => {
        let value = e.target.value;
        this.handleValidate(value, ValidationTriggerType.TriggerFieldChange);

        this.callOnChange(e);
    }

    // add a handler to trim the values on leave ...
    onLeave = (e) => {
        let value = e.target.value;

        this.handleValidate(value, ValidationTriggerType.TriggerFieldExit);

        if (value != undefined) {
            value = value.trim();
            e.target.value = value;

            this.onChange(e);
        }

        if (typeof (this.props.onLeave) == 'function') {
            const key = this.props.datakey || '';
            this.props.onLeave(e, key);
        }
    }

    onClick = (e) => {
        if (this.props.stopPropagationOnClick) {
            e.stopPropagation();
        }
    }

    callOnChange = (e) => {
        const key = this.props.datakey || '';
        if (typeof (this.props.onChange) == 'function')
            this.props.onChange(e, key);
    }

    onKeyPress = (e) => {

        if (this.props.handleReturn) {
            if (e.key == 'Enter') {
                var blurEvent = { target: { value: null } };
                this.onLeave(blurEvent);
            }
        }

        if (typeof (this.props.onKeyPress) == 'function')
            this.props.onKeyPress(e);
    }

    handleValidate = (value, triggerType) => {
        let errors = [];

        if (this.props.validateRequired == true) {
            if (triggerType == ValidationTriggerType.TriggerFieldChange) {
                const controlid = this.props.validateKey || this.props.name;
                const isRequiredValid = !CommonValidation.isEmpty(value);

                errors.push(
                    {
                        id: controlid,
                        text: `${this.props.label} is required!`,
                        valid: isRequiredValid
                    });
            }
        }

        if (errors.length > 0)
            if (this.props.handleValidate)
                this.props.handleValidate(errors);
    }

    getControlValue = () => {
        const { datakey, value } = this.props;

        let controlValue = null;
        if (datakey != null)
            controlValue = value[datakey];
        else
            controlValue = value;

        return controlValue;
    }

    render() {
        var wrapperClass = 'form-group';
        if (this.props.error && this.props.error.length > 0) {
            wrapperClass += ' has-error';
        }

        var labelHtml = '';
        if (this.props.haslabel && this.props.haslabel == true) {
            labelHtml = (
                <label htmlFor={this.props.name}>{this.props.label}:</label>
            );
        }

        const controlValue = this.getControlValue();

        var inputControl = (
            <input type="text"
                autoComplete="off"
                className="form-control"
                placeholder={this.props.placeholder || this.props.label}
                autoFocus={this.props.autoFocus}
                name={this.props.name}
                value={controlValue}
                onKeyPress={this.onKeyPress}
                onChange={this.onChange}
                onFocus={this.props.onFocus}
                onBlur={this.onLeave}
                onClick={this.onClick}
                maxLength={this.props.maxLength || 524288}
                ref={input => input && this.props.focus && input.focus()}
            />
        );

        const readOnly = this.props.readonly == true;
        if (readOnly) {
            inputControl = (
                <input type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder={this.props.placeholder || this.props.label}
                    name={this.props.name}
                    value={controlValue}
                    readOnly
                    ref={input => input && this.props.focus && input.focus()} />
            );
        }

        let classes = 'field';
        if (this.props.className)
            classes += (' ' + this.props.className);

        return (
            <div className={wrapperClass}>
                {labelHtml}
                <div className={classes}>
                    {inputControl}
                </div>
                <div className="input">
                    {this.props.error}
                </div>
            </div>
        );
    }
}

