import React from 'react';
import PropTypes from 'prop-types';
import './loader.scss'

export const LoadingContainer = (props) => {
    let loaderPanel = null;
    const colourStyle = props.colour || undefined;
    const colourClass = props.colourClass || '';
    const loadingText = props.message || 'Loading';

    if (props.loading == true) {
        loaderPanel = (
            <div className="text-center loading-anim">
                <div style={{ "color": colourStyle }} className={`spinner-border ${colourClass}`} role="status">
                    <span className="sr-only">Loading</span>
                </div>
                <span className="ml-2">{loadingText}</span>
            </div>);
    }
    else {
        loaderPanel = props.children;
    }

    const centerClass = props.centered ? "loading-container" : '';

    return (
        <div className={centerClass}>
            {loaderPanel}
        </div>
    );
}

LoadingContainer.propTypes = {
    colour: PropTypes.string,
    colourClass: PropTypes.string,
    message: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    centered: PropTypes.bool,
}

