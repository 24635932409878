import React, { Fragment } from 'react';
import './vehicleDetails.scss';

const ReCaptchaBlurb = () => {

    return (
        <Fragment>
            <p className="disclaimer">This site is protected by reCAPTCHA and the Google
                        <a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'> Privacy Policy </a>
                            and
                        <a href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'> Terms of Service </a>
                            apply.
            </p>
        </Fragment>
    );
};

export default ReCaptchaBlurb;