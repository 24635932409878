import React from 'react';
import { loadPopovers } from '../../../classes/popOver';
import '../validation.scss';
import { CommonValidation } from '../commonValidation/commonValidation';
import { getCurrencySymbol } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

export class PriceInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false
        };
    }

    componentDidMount() {
        loadPopovers();
    }

    setEditing = (editing) => {
        this.setState({ editing });
    }


    onFocus = (e) => {
        this.setEditing(true);
    }

    onLeave = (e) => {
        let value = e.target.value;

        if (!CommonValidation.isEmpty(value)) {
            value = this.updateValue(value);
            this.callChangeValue(value);
            this.setEditing(false);
        }

        if (typeof (this.props.onLeave) == 'function') {
            const key = this.props.datakey || '';
            this.props.onLeave(e, key);
        }
    }

    callChangeValue = (val) => {
        let e = { target: { value: val } };
        this.onChange(e);
    }

    onChange = (e) => {
            this.callOnChange(e);
    }
    callOnChange = (e) => {
        const key = this.props.datakey || '';
        if (typeof (this.props.onChange) == 'function')
            this.props.onChange(e, key);
    }

    add_thousands_separators = (num) => {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    }

    updateValue = (textValue) => {
        let controlValue = textValue;

        const isValid = !CommonValidation.isEmpty(controlValue) && CommonValidation.isValidDecimal(controlValue);
        if (isValid) {
            controlValue = controlValue.trim();
            const decimalFixedValue = parseFloat(controlValue).toFixed(2);
            const decimalValue = parseFloat(controlValue);

            if (decimalFixedValue != decimalValue)
                controlValue = decimalFixedValue.toString();
            else
                controlValue = decimalValue.toString();
        }

        return controlValue;
    }

    render() {
        var wrapperClass = 'form-group';
        if (this.props.error && this.props.error.length > 0)
            wrapperClass += " " + 'has-error';

        var labelHtml = '';
        if (this.props.haslabel && this.props.haslabel == true)
            labelHtml = (
                <label htmlFor={this.props.name}>{this.props.label}:</label>
            );

        const { datakey, value, readonly, currency } = this.props;

        let controlValue = null;
        if (datakey != null)
            controlValue = value[datakey];
        else
            controlValue = value;


        const editing = this.state.editing;
        const readOnly = readonly == true;

        if (!editing) {
            if (CommonValidation.isValidDecimal(controlValue)) {
                let textInputValue = controlValue || '0.00';
                let decimalValue = parseFloat(textInputValue).toFixed(2);
                controlValue = this.add_thousands_separators(decimalValue);
            }
        }

        let classes = "field small";
        if (this.props.value < 0)
            classes += " warning";

        let currencySymbol = '';
        if (currency)
            currencySymbol = getCurrencySymbol(currency);

        let inputControl = (
            <input type="text"
                autoComplete="off"
                className="form-control"
                autoFocus={this.props.autoFocus}
                placeholder={this.props.label}
                name={this.props.name}
                value={controlValue}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onLeave}
            />
        );

        if (readOnly) {
            inputControl = (
                <input type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder={this.props.label}
                    name={this.props.name}
                    value={controlValue}
                    readOnly />
            );
        }

        let appendBlock = '';
        if (this.props.help) {
            var content = this.props.helpContent();
            appendBlock = (<div className="input-group-append">
                <button id="popoverButton" type="button"
                    className="btn btn-sm btn-primary"
                    title={this.props.label}
                    data-toggle="popover"
                    data-html="true"
                    data-trigger="focus"
                    data-content={content}>
                    <FontAwesomeIcon icon={faQuestion} />
                </button>
            </div>);
        }

        return (
            <div className={wrapperClass}>
                {labelHtml}
                <div className={classes}>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{currencySymbol}</span>
                            {inputControl}
                        </div>
                        {appendBlock}
                    </div>
                </div>
                <div className="input">
                    {this.props.error}
                </div>
            </div>
        );
    }
}

