import React from 'react';
import './shoppingBasket.scss'
import { useBasketDispatch, useBasketState, removeProduct, setBasketExpanded } from '../../../context/basketProvider';
import PriceDisplay from '../../common/priceDisplay/priceDisplay';
import PriceTotalDisplay from '../../common/priceTotalDisplay/priceTotalDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const ShoppingBasket = () => {
    const { productsDistinct, basketOpen } = useBasketState();
    const basketDispatch = useBasketDispatch();

    const clickedBasket = () => {
        setBasketExpanded(basketDispatch, !basketOpen);
    }

    let singlePayTotal = 0;
    let ddPayTotal = 0;

    const shoppingCartItems = productsDistinct.length > 0 ? productsDistinct.map(product => {
        singlePayTotal += product.singlePaymentPrice || 0;
        ddPayTotal += product.directDebitTotalPrice || 0;

        const renderPriceDetails = () => {
            return (
                <ul>
                    <PriceDisplay
                        priceDetails={product.priceDetails}
                        singlePaymentPrice={product.singlePaymentPrice}/>
                </ul>
            );
        }

        return (
            <React.Fragment key={product.Id}>
                <div className="row mb-2 w-100">
                    <div className="col-10">
                        <ul className="basket-list">
                            <li><h4 style={{ marginBottom: "0.1rem" }} className="text-uppercase">{product.name}</h4></li>
                            {renderPriceDetails()}
                        </ul>
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-center">
                        <span className="basket-icon-bin" onClick={() => { removeProduct(basketDispatch, product.Id) }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </span>
                    </div>
                </div>
                <hr style={{ width: '100%' }} />
            </React.Fragment>
        )
    }) : <div className="basket-empty">
            Your basket is empty
         </div>

    const displaySwitchStyle = productsDistinct.length > 0 ? { display: "block" } : { display: "flex" };

    const totalsToDisplay = [
        {
            title: "Instalments total",
            amount: ddPayTotal
        },
        {
            title: "Single payment total",
            amount: singlePayTotal
        }
    ];

    const shoppingCartDisplay = <div className="shoppingBasketContainerMain">
        <h4 className="basket-title">Basket</h4>
        <div className="item-container-items" style={displaySwitchStyle}>
            {shoppingCartItems}
        </div>
        <div className="shoppingFooterContent">
            <PriceTotalDisplay
                listContainerStyle="footerContent"
                listElementStyle="mb-1"
                totals={totalsToDisplay}
                listEndSubtitle="All prices include Insurance Premium Tax at the appropriate rate"
            />
        </div>
    </div>

    const pillVisibility = productsDistinct.length > 0 ? '' : 'hide-pill';

    return (
        <div className="float-right shoppingBasketContainer">
            <span className="shopping-basket-icon" onClick={clickedBasket}>
                <FontAwesomeIcon icon={faShoppingCart} />
            </span>
            <span className={`badge badge-pill shopping-basket-pill ${pillVisibility}`}>{productsDistinct.length}</span>
            {basketOpen && shoppingCartDisplay}
        </div>
    );
}

export default ShoppingBasket