import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../common/button/button';
import { DateInput } from '../../common/dateInput';
import { TextInput } from '../../common/textInput/textInput';
import { TextButtonInput } from '../../common/textButtonInput';
import { SelectInput } from '../../common/selectInput/selectInput';
import './customerDetails.scss';
import ContinueBackNavigation from '../navigation/continueBackNavigation';
import { tabletResponsiveResolutionWidth } from '../../../classes/constants';

const CustomerDetails = ({ customerDetails, customerDetailErrors, startDateLimitNote, minimumPolicyStartDate, onChangeDetail, continueClicked, backClicked,
    onChangeSearchPostcode, onFindClick, finding, addressList, onSelectAddress,
    selectedAddress, showAddressList, showAddressForm, onShowAddressFormClick, manualGapIntroductionDate }) => {

    const getAddressList = () => {

        let listPanel = '';

        if (showAddressList === true) {
            listPanel = (
                <div className="col-auto">
                    <SelectInput label="Select your address from this list"
                        name="address-list"
                        haslabel
                        forceDefault
                        options={addressList}
                        onChange={onSelectAddress}
                        value={selectedAddress}
                        error={customerDetailErrors.addressList}
                    />
                </div>
            );
        }
        return listPanel;
    }

    const getAddressForm = () => {

        let addressSection = '';

        if (showAddressForm === true) {
            addressSection = (
                <Fragment>
                    <div className="row">
                        <div className="col-auto">
                            <TextInput
                                haslabel
                                name="addressLine1"
                                label="Address line 1"
                                error={customerDetailErrors.addressLine1}
                                value={customerDetails.addressLine1}
                                onChange={(e) => onChangeDetail(e, 'addressLine1')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <TextInput
                                haslabel
                                name="addressLine2"
                                label="Address line 2"
                                error={customerDetailErrors.addressLine2}
                                value={customerDetails.addressLine2}
                                onChange={(e) => onChangeDetail(e, 'addressLine2')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextInput
                                haslabel
                                name="townOrCity"
                                label="Town/City"
                                error={customerDetailErrors.townOrCity}
                                value={customerDetails.townOrCity}
                                onChange={(e) => onChangeDetail(e, 'townOrCity')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <TextInput
                                haslabel
                                name="country"
                                label="Country"
                                error={customerDetailErrors.country}
                                value={customerDetails.country}
                                onChange={(e) => onChangeDetail(e, 'country')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextInput
                                haslabel
                                name="postcode"
                                label="Postcode"
                                className="small"
                                error={customerDetailErrors.postCode}
                                value={customerDetails.postCode}
                                onChange={(e) => onChangeDetail(e, 'postCode')} />
                        </div>
                    </div>
                </Fragment>
            );
        }
        return addressSection;
    }

    const getManualAddressButton = () => {
        let buttonPanel = '';

        if (!showAddressForm) {
            buttonPanel = (
                <div className="row">
                    <div className="col">
                        <Button
                            onClick={onShowAddressFormClick}
                            text='Enter address manually'
                            name='show-address-form'
                            className='btn btn-primary button-theme-secondary'
                        />
                    </div>
                </div>
            );
        }

        return buttonPanel;
    }

    const addressListPanel = getAddressList();
    const addressPanel = getAddressForm();
    const manualAddressButtonPanel = getManualAddressButton();

    return (
        <form className="customer-detail-panel">
            <div className="row">
                <div className="col">
                    <TextInput
                        haslabel
                        name="title"
                        label="Title"
                        className="small"
                        error={customerDetailErrors.title}
                        value={customerDetails.title}
                        onChange={(e) => onChangeDetail(e, 'title')} />
                </div>
            </div>

            <div className="row">
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="firstName"
                        label="First name"
                        error={customerDetailErrors.firstName}
                        value={customerDetails.firstName}
                        onChange={(e) => onChangeDetail(e, 'firstName')} />
                </div>
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="surName"
                        label="Surname"
                        error={customerDetailErrors.surName}
                        value={customerDetails.surName}
                        onChange={(e) => onChangeDetail(e, 'surName')} />
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="emailAddress"
                        label="Email address"
                        error={customerDetailErrors.emailAddress}
                        value={customerDetails.emailAddress}
                        onChange={(e) => onChangeDetail(e, 'emailAddress')} />
                </div>
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="emailAddressConfirm"
                        label="Re-enter Email address"
                        error={customerDetailErrors.emailAddressConfirm}
                        value={customerDetails.emailAddressConfirm}
                        onChange={(e) => onChangeDetail(e, 'emailAddressConfirm')} />
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="telephoneNumber"
                        label="Telephone number"
                        error={customerDetailErrors.telephoneNumber}
                        value={customerDetails.telephoneNumber}
                        onChange={(e) => onChangeDetail(e, 'telephoneNumber')} />
                </div>
                {manualGapIntroductionDate && (
                    <div className="col-auto">
                        <DateInput
                            haslabel
                            themeClass="button-theme-x-small"
                            label="Gap introduction date"
                            name="introductionDate"
                            placeholder="Select Date..."
                            useJson
                            maximumDate={new Date()}
                            forceTimeReset
                            error={customerDetailErrors.introductionDate}
                            value={customerDetails.introductionDate}
                            displayMinWidth={tabletResponsiveResolutionWidth}
                            onDateChange={(e) => onChangeDetail(e, 'introductionDate')} />
                    </div>)}
            </div>
            <div className="row">
                <div className="col-auto">
                    <DateInput
                        haslabel
                        themeClass="button-theme-x-small"
                        label="Policy start date *"
                        name="policyStartDate"
                        placeholder="Select Date..."
                        useJson
                        minimumDate={minimumPolicyStartDate}
                        forceTimeReset
                        error={customerDetailErrors.policyStartDate}
                        value={customerDetails.policyStartDate}
                        displayMinWidth={tabletResponsiveResolutionWidth}
                        onDateChange={(e) => onChangeDetail(e, 'policyStartDate')} />
                    <p className="field-text-explanation">{startDateLimitNote}</p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-auto">
                    <TextButtonInput
                        haslabel
                        themeClass="button-theme-find-address-small"
                        name="find-address"
                        label="Find your address"
                        error={customerDetailErrors.searchPostcode}
                        value={customerDetails.searchPostcode}
                        onChange={onChangeSearchPostcode}
                        onButtonClick={onFindClick}
                        buttonText="Find address"
                        waitValue={finding}
                        placeholder="Enter postcode"
                        className="postcode-finder"
                        buttonDisabled={finding}
                    />
                </div>
                {addressListPanel}
            </div>
            {manualAddressButtonPanel}
            <hr />
            {addressPanel}

            <ContinueBackNavigation
                onContinueClick={continueClicked}
                onBackClick={backClicked}
                displayMinWidth={420}
            />
        </form>
    );
}

CustomerDetails.propTypes = {
    customerDetails: PropTypes.object.isRequired,
    customerDetailErrors: PropTypes.object,
    onChangeDetail: PropTypes.func.isRequired,
    continueClicked: PropTypes.func.isRequired,
    backClicked: PropTypes.func.isRequired,
    finding: PropTypes.bool.isRequired,
    onFindClick: PropTypes.func.isRequired,
    onChangeSearchPostcode: PropTypes.func.isRequired,
    addressList: PropTypes.array.isRequired,
    onSelectAddress: PropTypes.func.isRequired,
    selectedAddress: PropTypes.string.isRequired,
    showAddressList: PropTypes.bool.isRequired,
    showAddressForm: PropTypes.bool.isRequired,
    onShowAddressFormClick: PropTypes.func.isRequired
}

export default CustomerDetails;
