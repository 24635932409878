
export class ClientDateHandler
{
    constructor() {
        this.dateValue = null;
        this.dateIsNull = false;
        this.isUTC = false;
    }

    resetDate = () => {
        this.dateValue = new Date();
    }

    setDateValue = (dateValue, isUTC) => {
        this.dateValue = dateValue;
        this.isUTC = isUTC;
    }

    getLocalDate = () => {
        var date = this.getDate(false);
        return date;
    }

    getUTCDate = () => {
        var date = this.getDate(true);
        return date;
    }

    getDate = (isUtc) => {
        if (this.dateIsNull)
            return null;

        var years = this.getYear(isUtc);
        var month = this.getMonth(isUtc) - 1;
        var day = this.getDay(isUtc);
        var hours = this.getHours(isUtc);
        var minutes = this.getMinutes(isUtc);
        var seconds = this.getSeconds(isUtc);

        var date = new Date(years, month, day, hours, minutes, seconds);
        return date;
    }

    getJSON = () => {
        const dateObject = {
            value: this.formatDate(),
            utc: this.getUTC() ? '1' : '0'
        };

        const json = JSON.stringify(dateObject);
        return json;
    }

    formatDate = () => {

        if (this.dateValue == null)
            return '!null';

        var years = this.getYear();
        var month = this.getMonth();
        var day = this.getDay();
        var hours = this.getHours();
        var minutes = this.getMinutes();
        var seconds = this.getSeconds();

        const isInvalidDate = isNaN(day) || isNaN(month) || isNaN(years);
        if  (isInvalidDate)
             return '!invalid';

        const dateText = `${years}-${this.leftPad(month)}-${this.leftPad(day)}`;
        const timeText = `T${this.leftPad(hours)}:${this.leftPad(minutes)}:${this.leftPad(seconds)}`;

        let formattedText = dateText + timeText;
        return formattedText;
    }

    parseJSON = (dateJson) => {

        if (dateJson == null) {
            this.resetDate();
            this.dateIsNull = true;
            return;
        }

        var jsonDate = JSON.parse(dateJson);
        const isValid = (jsonDate.value != null && jsonDate.utc != null);
        if (isValid) {

            if (jsonDate.value == "!null") {
                this.resetDate();
                this.dateIsNull = true;
            }
            else {
                const dateTimeParts = jsonDate.value.split('T');

                const dateText = dateTimeParts[0];
                const dateValues = this.parseDateValues(dateText);

                const timeText = dateTimeParts[1];
                const timeValues = this.parseTimeValues(timeText);

                this.resetDate();
                this.setUTC(jsonDate.utc == '1');

                if (dateValues != null) {
                    this.setYear(dateValues.year);
                    this.setMonth(dateValues.month);
                    this.setDay(dateValues.day);
                }

                if (timeValues != null) {
                    this.setHours(timeValues.hours);
                    this.setMinutes(timeValues.minutes);
                    this.setSeconds(timeValues.seconds);
                }
            }
        }
    }

    parseDateValues = (dateText) => {
        const [yyyy, mm, dd] = dateText.split('-');
        if (!isNaN(yyyy) && !isNaN(mm) && !isNaN(dd))
            return {
                year: parseInt(yyyy, 10),
                month: parseInt(mm, 10),
                day: parseInt(dd, 10)
            };
        else
            return null;
    }

    parseTimeValues = (timeText) => {
        const [hh, mm, ss] = timeText.split(':');
        if (!isNaN(hh) && !isNaN(mm) && !isNaN(ss))
            return {
                hours: parseInt(hh, 10),
                minutes: parseInt(mm, 10),
                seconds: parseInt(ss, 10)
            };
        else
            return null;
    }

    setUTC = (isUTC) => {
        this.isUTC = isUTC;
    }

    getUTC = () => {
        return this.isUTC;
    }

    getUTCValue = (isUtc) => {
        const utcValue = isUtc != null ? isUtc : this.isUTC;
        return utcValue;
    }

    getYear = (isUtc = null) => {
        let year = -1;

        if (this.getUTCValue(isUtc))
            year = this.dateValue.getUTCFullYear();
        else
            year = this.dateValue.getFullYear();

        return year;
    }

    setYear = (year, isUtc = null) => {

        if (this.getUTCValue(isUtc))
            this.dateValue.setUTCFullYear(year);
        else
            this.dateValue.setFullYear(year);
    }

    getMonth = (isUtc = null) => {
        let month = -1;

        if (this.getUTCValue(isUtc))
            month = this.dateValue.getUTCMonth();
        else
            month = this.dateValue.getMonth();

        var adjustedMonth = month + 1;
        return adjustedMonth;
    }

    setMonth = (month, isUtc = null) => {

        var adjustedMonth = month - 1;
        if (this.getUTCValue(isUtc))
            this.dateValue.setUTCMonth(adjustedMonth);
        else
            this.dateValue.setMonth(adjustedMonth);
    }

    getDay = (isUtc = null) => {
        let day = -1;

        if (this.getUTCValue(isUtc))
            day = this.dateValue.getUTCDate();
        else
            day = this.dateValue.getDate();

        return day;
    }

    setDay = (day, isUtc = null) => {

        if (this.getUTCValue(isUtc))
            this.dateValue.setUTCDate(day);
        else
            this.dateValue.setDate(day);
    }

    getHours = (isUtc = null) => {
        var hours = -1;

        if (this.getUTCValue(isUtc))
            hours = this.dateValue.getUTCHours();
        else
            hours = this.dateValue.getHours();

        return hours;
    }

    setHours = (hours, isUtc = null) => {

        if (this.getUTCValue(isUtc))
            this.dateValue.setUTCHours(hours);
        else
            this.dateValue.setHours(hours);
    }

    getMinutes = (isUtc = null) => {
        var minutes = -1;

        if (this.getUTCValue(isUtc))
            minutes = this.dateValue.getUTCMinutes();
        else
            minutes = this.dateValue.getMinutes();

        return minutes;
    }

    setMinutes = (minutes, isUtc = null) => {

        if (this.getUTCValue(isUtc))
            this.dateValue.setUTCMinutes(minutes);
        else
            this.dateValue.setMinutes(minutes);
    }

    getSeconds = (isUtc = null) => {
        var seconds = -1;

        if (this.getUTCValue(isUtc))
            seconds = this.dateValue.getUTCSeconds();
        else
            seconds = this.dateValue.getSeconds();

        return seconds;
    }

    setSeconds = (seconds, isUtc = null) => {

        if (this.getUTCValue(isUtc))
            this.dateValue.setUTCSeconds(seconds);
        else
            this.dateValue.setSeconds(seconds);
    }

    leftPad = (value) => {
        if (value < 10)
            return '0' + value;
        else
            return value;
    }
}


