import React, { Component } from 'react';

export class SecurityContainer extends Component {
    displayName = SecurityContainer.name

    render() {
        let containerResult = null;
        let isContained = false;
        if (this.props.clickJacking == true) {
            isContained = window.self == window.top;
            if (isContained) {
                containerResult = this.props.children;
            }
            else
                containerResult = (<p>The application is not allowed to run in this context</p>);
        }
        else
            containerResult = this.props.children;

        return (
            <div>
                {containerResult}
            </div>
        );
  }
}
