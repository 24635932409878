import React from 'react';
import PropTypes from 'prop-types';
import './confirmation.scss';
import { CommonValidation } from '../../common/commonValidation/commonValidation';

const Confirmation = ({ registrations, brand, businessPartner, failureMessage }) => {

    const regIds = registrations.map(regId => {
        return <div>{`Your ${regId.name} policy number is: `}
            <span className="font-weight-bold">{regId.number}</span>
        </div>
    });

    //Autotrust want to use the BP and not the brand
    const company = brand === "AutoTrust" ? businessPartner : brand;

    const contactTelephoneNumber = "0344 573 8005";

    const renderView = CommonValidation.isEmpty(failureMessage) ?
        <React.Fragment>
            <h3>{`Thank you for taking out insurance with ${company}`}</h3>
            <div className="policy-info-container">
                {regIds}
            </div>
            <p>You should receive an email confirming your cover shortly.</p>
            <p>{`If you do not receive an email, please contact our customer services team on ${contactTelephoneNumber}.`}</p>
        </React.Fragment>
        :
        <React.Fragment>
            <h3>Failed to register your policy.</h3>
            <p>{failureMessage}</p>
            <p>{`Please contact our Customer Services team on ${contactTelephoneNumber}.`}</p>
        </React.Fragment>
    return (
        <div className="main-container">
            {renderView}
        </div>
    );
}

export default Confirmation;

Confirmation.propTypes = {
    registrations: PropTypes
}