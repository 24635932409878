import React from 'react';
import { LoadingContainer } from '../../common/loader/loader';

const SagePayLoadingPage = () => {

    return (
        <div>
            <LoadingContainer
                colour='#41A940'
                loading={true}
            />
        </div>
    );
}

export default SagePayLoadingPage;


