import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '../../common/textInput/textInput';
import { DateInput } from '../../common/dateInput';
import { tabletResponsiveResolutionWidth } from '../../../classes/constants';

const VehicleFoundForm = ({ vehicleDetails, vehicleDetailErrors, onChangeDetail }) => {
    return (
        <Fragment>
            <div className="row mt-3">
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="vehicle_make"
                        label="Vehicle make"
                        error={vehicleDetailErrors.make}
                        value={vehicleDetails.make}
                        onChange={(e) => onChangeDetail(e, 'make')}
                        readonly
                    />
                </div>
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="vehicle-model"
                        label="Vehicle model"
                        error={vehicleDetailErrors.model}
                        value={vehicleDetails.model}
                        onChange={(e) => onChangeDetail(e, 'model')}
                        readonly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="model-type"
                        label="Model type"
                        error={vehicleDetailErrors.modelType}
                        value={vehicleDetails.modelType}
                        onChange={(e) => onChangeDetail(e, 'modelType')}
                        readonly
                    />
                </div>
                <div className="col-auto">
                    <TextInput
                        haslabel
                        name="fuel-type"
                        label="Fuel type"
                        error={vehicleDetailErrors.fuelType}
                        value={vehicleDetails.fuelType}
                        onChange={(e) => onChangeDetail(e, 'fuelType')}
                        readonly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-auto" >
                    <TextInput
                        haslabel
                        name="engine-size"
                        label="Engine size"
                        error={vehicleDetailErrors.engineSize}
                        value={vehicleDetails.engineSize}
                        onChange={(e) => onChangeDetail(e, 'engineSize')}
                        readonly
                    />
                </div>
                <div className="col-auto">
                    <DateInput
                        haslabel
                        label="First registration date"
                        name="first-registration-date"
                        placeholder="Select Date..."
                        useJson
                        forceTimeReset
                        error={vehicleDetailErrors.firstRegistrationDate}
                        value={vehicleDetails.firstRegistrationDate}
                        displayMinWidth={tabletResponsiveResolutionWidth}
                        onDateChange={(e) => { onChangeDetail(e, 'firstRegistrationDate') }}
                        readonly
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default VehicleFoundForm;

VehicleFoundForm.propTypes = {
    vehicleDetails: PropTypes.object.isRequired,
    vehicleDetailErrors: PropTypes.object,
    onChangeDetail: PropTypes.func.isRequired
};