export class PriceHelper {

    getPriceDetailsDirectDebit(price, noOfInstallments) {

        let priceDetails = {
            noOfInstallments: 0,
            totalAmount: 0,
            installmentAmount: 0,
            firstInstallmentAmount: 0,
            hasEqualInstallments: false
        };

        if (price == 0) return priceDetails;

        priceDetails.noOfInstallments = noOfInstallments;
        priceDetails.totalAmount = price.toFixed(2);

        priceDetails.installmentAmount = (priceDetails.noOfInstallments == 0)
            ? priceDetails.totalAmount
            : (priceDetails.totalAmount / priceDetails.noOfInstallments).toFixed(2);

        priceDetails.firstInstallmentAmount = (priceDetails.noOfInstallments == 0 || priceDetails.noOfInstallments == 1)
            ? priceDetails.installmentAmount
            : (priceDetails.totalAmount - (priceDetails.installmentAmount * (priceDetails.noOfInstallments - 1))).toFixed(2);

       priceDetails.hasEqualInstallments = (priceDetails.installmentAmount == priceDetails.firstInstallmentAmount);

        return priceDetails;
    }
}
