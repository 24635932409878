import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { Button } from '../../common/button/button';
import './purchasePage.scss';
import _ from 'lodash';
import DirectDebitForm from './directDebitForm';
import DirectDebitConfirmation from './directDebitConfirmation';
import ContinueBackNavigation from '../navigation/continueBackNavigation';
import { LoadingContainer } from '../../common/loader/loader';
import { CommonValidation } from '../../common/commonValidation/commonValidation';
import PriceDisplay from '../../common/priceDisplay/priceDisplay';
import PriceTotalDisplay from '../../common/priceTotalDisplay/priceTotalDisplay';

const PurchasePage = (props) => {

    const { brandAdminText, statementCompany, loading, onSinglePaymentClick, onDirectDebitPaymentClick, showSingleForm, showDirectDebitForm, onSubmitDirectDebit,
        onChangeDetail, directDebitDetails, directDebitErrors, basketProductsDistinct, backClicked, sagePayTransaction, sagePayRedirection,
        paymentInProgress, resetPayment, selectedPreferredPaymentDay, onPaymentDayChange, lookUpDays, preferedPaymentDay } = props;

    const renderPriceSummary = () => {

        const noOfInstallmentsQuery = basketProductsDistinct.find(product => product.directDebitDuration !== undefined);
        let noOfInstallments = noOfInstallmentsQuery.directDebitDuration;
        let unequalInstallmentsProducts = _.filter(basketProductsDistinct, p => !p.priceDetails.hasEqualInstallments);
        let equalInstallmentsProducts = _.filter(basketProductsDistinct, p => p.priceDetails.hasEqualInstallments);

        let firstInstallmentTotal = _.sumBy(unequalInstallmentsProducts, p => Number(p.priceDetails.firstInstallmentAmount));
        let subsequentInstallmentTotal = _.sumBy(unequalInstallmentsProducts, p => Number(p.priceDetails.installmentAmount));

        let equalInstallmentTotal = _.sumBy(equalInstallmentsProducts, p => Number(p.priceDetails.installmentAmount));

        firstInstallmentTotal = equalInstallmentTotal + firstInstallmentTotal;
        subsequentInstallmentTotal = equalInstallmentTotal + subsequentInstallmentTotal;


        if (CommonValidation.isEmpty(unequalInstallmentsProducts) || unequalInstallmentsProducts.length < 1) {
            return (
                <ul className="price-summary text-center">
                    <li>Pay <b>£{equalInstallmentTotal.toFixed(2)}</b> monthly over <b>{noOfInstallments}</b> months</li>
                </ul>
            );
        }
        return (
            <ul className="price-summary text-center">
                <li>Instalments to be paid per month over <b>{noOfInstallments}</b> months</li>
                <li>First payment: <b>£{firstInstallmentTotal.toFixed(2)}</b></li>
                <li>Subsequent payments over <b>{noOfInstallments - 1}</b> months: <b>£{subsequentInstallmentTotal.toFixed(2)}</b></li>
            </ul>
        );
    }

    const getProductItem = (product) => {
        return (
            <li className="list-group-item product-summary-item justify-content-between text-body" key={product.Id}>
                <span className="product-name-spacing text-uppercase">{product.name}</span>
                <ul>
                    <PriceDisplay
                        listStyle="price-items"
                        priceDetails={product.priceDetails}
                        singlePaymentPrice={product.singlePaymentPrice} />
                </ul>
            </li>
        );
    }

    const showDirectDebitOption = _.some(basketProductsDistinct, { hasDirectDebit: true });
    const showSinglePaymentOption = _.some(basketProductsDistinct, { hasSinglePayment: true });
    const singlePaymentTotalPrice = _.sumBy(basketProductsDistinct, product => Number(product.singlePaymentPrice || 0));

    const getBasketProductsPanel = () => {
        let panel = '';

        const totalsToDisplay = [
            {
                title: "Instalments total",
                amount: _.sumBy(basketProductsDistinct, p => Number(p.directDebitTotalPrice || 0))
            },
            {
                title: "Single payment total",
                amount: singlePaymentTotalPrice
            }
        ];

        panel = (
            <ul className="list-group">
                {basketProductsDistinct.map(p => getProductItem(p))}
                <PriceTotalDisplay
                    listElementStyle="price-total"
                    totals={totalsToDisplay} />
            </ul>
        );

        return panel;
    }

    const getPaymentOptionsPanel = () => {

        let panel = '';
        let ddPanel = '';
        let spPanel = '';

        if (paymentInProgress !== true) {
            if (showDirectDebitOption) {

                ddPanel = (
                    <div className="payment-option align-content-stretch p-2">
                        <div className="border border-3 p-2 payment-option-container">
                            <h5 className="card-title card-title-payment">Pay by Direct Debit</h5>
                            {renderPriceSummary()}
                            <div className="payment-select-container">
                                <Button
                                    onClick={onDirectDebitPaymentClick}
                                    text='Select'
                                    name='direct-debit-button'
                                    className='btn button-theme-payment'
                                />
                            </div>
                        </div>
                    </div>
                );
            }

            if (showSinglePaymentOption) {
                spPanel = (
                    <div className="d-flex payment-option align-content-stretch p-2">
                        <div className="border border-3 p-2 payment-option-container">
                            <h5 className="card-title card-title-payment">Pay by Debit/Credit Card</h5>
                            <ul className="price-summary text-center">
                                <li>Pay <b>£{singlePaymentTotalPrice.toFixed(2)}</b></li>
                            </ul>
                            <div className="payment-select-container">
                                <Button
                                    onClick={onSinglePaymentClick}
                                    text='Select'
                                    name='single-button'
                                    className='btn button-theme-payment'
                                />
                            </div>
                        </div>
                    </div>
                );
            }
        }

        if (!showDirectDebitOption && !showSinglePaymentOption) {
            panel = (
                <div className="alert alert-danger mt-2 w-100">No products have been added to the shopping basket!</div>
            );
        }
        else {
            const basketProductsPanel = getBasketProductsPanel();

            panel = (
                <div className="card card-payment my-2">
                    <div className="card-body card-body-payment pb-1">
                        <h5 className="card-title card-title-payment">SUMMARY</h5>
                        {basketProductsPanel}
                        <div className="payment-panels-container mt-2">
                            {ddPanel}
                            {spPanel}
                        </div>
                        <div className="row">
                            <p className="tax-inclusive-text">All prices include Insurance Premium Tax at the appropriate rate</p>
                        </div>
                    </div>
                </div>
            );
        }

        return panel;
    }

    const onIFrameLoad = (e) => {
        var target = e.target;
        var contentDoc = target.contentDocument;
        console.log(`IFrame load event contentDocument: ${contentDoc}`);

        if (contentDoc != null) {
            sagePayRedirection();
        }
    }

    const getSinglePaymentPanel = () => {
        let panel = '';

        if (showSingleForm === true && CommonValidation.isNotEmpty(sagePayTransaction.status)) {            
            if (sagePayTransaction.status === "OK") {
                panel = (
                    <div className="row">
                        <div className="col iframe-style">
                            <iframe src={sagePayTransaction.nextUrl} onLoad={onIFrameLoad} width="100%" height="600px" sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation" />
                        </div>
                    </div>
                );
            }
            else {
                panel = (
                    <div className="row">
                        <div className="col">
                            <p>{`Unable to take payment. Error: ${sagePayTransaction.statusDetail}`}</p>
                            <p>Click Reset in order to start the payment process again, failing this please contact our Customer Services team on 0344 573 8005</p>
                            <Button
                                onClick={resetPayment}
                                text='Reset'
                                name='reset-button'
                                className='btn button-theme-payment'
                            />
                        </div>
                    </div>
                );
            }            
        }

        return panel;
    }

    const getDirectDebitPaymentPanel = () => {
        let panel = '';

        if (showDirectDebitForm === true) {
            panel = (
                <Fragment>
                    <DirectDebitForm
                        brandAdminText={brandAdminText}
                        statementCompany={statementCompany}
                        loading={loading}
                        onSubmitDirectDebit={onSubmitDirectDebit}
                        onChangeDetail={onChangeDetail}
                        directDebitDetails={directDebitDetails}
                        directDebitErrors={directDebitErrors}
                        selectedPreferredPaymentDay={selectedPreferredPaymentDay}
                        onPaymentDayChange={onPaymentDayChange}
                        lookUpDays={lookUpDays}
                        preferedPaymentDay={preferedPaymentDay}
                    />
                    <DirectDebitConfirmation
                        brandAdminText={brandAdminText}
                    />
                </Fragment>
            );
        }

        return panel;
    }

    const paymentOptionsPanel = getPaymentOptionsPanel();
    const singlePanel = getSinglePaymentPanel();
    const directDebitPanel = getDirectDebitPaymentPanel();
    const backButton = (paymentInProgress === false && <ContinueBackNavigation onBackClick={backClicked} displayMinWidth={420} />);

    return (
        <LoadingContainer loading={loading} colourClass="loading-spinner-themed" message="Your products are being registered, please do not refresh the page">
            <div>
                {paymentOptionsPanel}
                {singlePanel}
                {directDebitPanel}
                {backButton}
            </div >
        </LoadingContainer >
    );
}

PurchasePage.propTypes = {
    brandAdminText: PropTypes.string.isRequired,
    statementCompany: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    onSinglePaymentClick: PropTypes.func.isRequired,
    onDirectDebitPaymentClick: PropTypes.func.isRequired,
    showSingleForm: PropTypes.bool.isRequired,
    showDirectDebitForm: PropTypes.bool.isRequired,
    onSubmitDirectDebit: PropTypes.func.isRequired,
    onChangeDetail: PropTypes.func.isRequired,
    directDebitDetails: PropTypes.object.isRequired,
    directDebitErrors: PropTypes.object.isRequired,
    basketProductsDistinct: PropTypes.array.isRequired,
    backClicked: PropTypes.func.isRequired,
    sagePayTransaction: PropTypes.object.isRequired,
    paymentInProgress: PropTypes.bool.isRequired,
    selectedPreferredPaymentDay: PropTypes.bool.isRequired,
    onPaymentDayChange: PropTypes.bool.isRequired,
    lookUpDays: PropTypes.array.isRequired
}

export default PurchasePage;