import { ApiHelper } from './apiHelper';

export class CustomerJourneyApi {

    save(purchaseProgress, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var url = '/api/customerJourney/save';
        new ApiHelper().postData(url, purchaseProgress, callback, failureCallback, headers);
    }

}