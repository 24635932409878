import React, { useContext } from 'react';
import { ThemeContext } from '../context/themeContext';
import { SecurityContainer } from './security/securityContainer';

export const Layout = ({ children }) => {

    const { theme } = useContext(ThemeContext);

    return (
        <SecurityContainer
            clickJack>
            <div className={`${theme} site-wrapper`}>
                {children}
            </div>
        </SecurityContainer>
    );
}
