import 'util';

export class ApiHelper {
    getData = (url, onSuccess, onFailure) => {
        var fetchResponse = fetch(url)
            .then(this.handleResponseStatus)
            .then(response => response.json())
            .then(data => this.handleCallback(data, onSuccess))
            .catch(error => this.handleError(error, url, onFailure));

        return fetchResponse;
    }

    getDataWithHeaders = (url, headers, onSuccess, onFailure) => {

        const getOptions =
        {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: headers
        }

        const fetchResponse = fetch(url, getOptions)
            .then(this.handleResponseStatus)
            .then(response => response.json())
            .then(data => this.handleCallback(data, onSuccess))
            .catch(error => this.handleError(error, url, onFailure));

        return fetchResponse;
    }

    getWithOptions = (url, options) => {
        const defaultError = (error) => {
            console.log(`ApiHelper:getWithOptions - ${JSON.stringify(error)}`);
        }

        const defaultSuccess = (data) => { }

        var onSuccess = options.onSuccess || defaultSuccess;
        var onFailure = options.onFailure || defaultError;

        var fetchResponse = fetch(url)
            .then(this.handleResponseStatus)
            .then(response => response.json())
            .then(data => this.handleCallback(data, onSuccess))
            .catch(error => this.handleError(error, url, onFailure));

        return fetchResponse;
    }
   
    getPostOptions(data, headers = null) {
        if (headers === null) {
            headers = {
                "Content-Type": "application/json; charset=utf-8;",
                "x-content-type-options": "nosniff;",
                "X-XSS-Protection": "1;",
                "Strict-Transport-Security": "max-age=600;",
                "X-Frame-Options": "DENY",
                "Content-Security-Policy": "frame-ancestors self"
            };
        }
        else {
            headers = {
                ...headers,
                "Content-Type": "application/json; charset=utf-8;",
                "x-content-type-options": "nosniff;",
                "X-XSS-Protection": "1;",
                "Strict-Transport-Security": "max-age=600;",
                "X-Frame-Options": "DENY",
                "Content-Security-Policy": "frame-ancestors self"
            };
        }

        const postOptions =
        {
            method: "POST",
            mode: "same-origin",
            cache: "no-cache",
            headers: headers,
            body: JSON.stringify(data)
        };

        return postOptions;
    }

    postDataWithResponse(url, data, onSuccess, onFailure, headers = null) {
        var postOptions = this.getPostOptions(data, headers);

        var postResponse = fetch(url, postOptions)
            .then(this.handleResponseStatus)
            .then(response => response.json())
            .then(responseData => this.handleCallback(responseData, onSuccess))
            .catch(error => this.handleError(error, url, onFailure));

        return postResponse;
    }

    postData = (url, data, onSuccess, onFailure, headers = null) => {
        var postOptions = this.getPostOptions(data, headers);

        var postResponse = fetch(url, postOptions)
            .then(this.handleResponseStatus)
            .then(function (response) {
                if (response.bodyUsed)
                    response.json()
            })
            .then(data => this.handleCallback(data, onSuccess))
            .catch(error => this.handleError(error, url, onFailure));

        return postResponse;
    }

    handleResponseStatus(response) {
        if (!response.ok) {
            console.log('response: ' + JSON.stringify(response));
            throw Error(response.statusText);
        }
        return response;
    }

    handleError(error, url, callback) {
        console.error(`Fetch url: ${url}, error: ${error}`);
        this.handleCallback(error, callback);
        return error;
    }

    handleCallback(data, callback) {
        if (callback != null)
            callback(data);
    }

}

