import { ApiHelper } from './apiHelper';

export class PolicyApi {

    register(policy, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var url = '/api/policy/register';
        new ApiHelper().postDataWithResponse(url, policy, callback, failureCallback, headers);
    }
}