import React from 'react'
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Button } from '../../common/button/button';

const ContinueBackNavigation = ({ onBackClick, onContinueClick, displayMinWidth }) => {

    const backButton = (
        onBackClick ?
            <Button
                className="btn btn-secondary button-theme-completed"
                onClick={onBackClick}
                text='Back'
            /> : ''
    );

    const continueButton = (
        onContinueClick ?
            <Button
                className="btn btn-primary button-theme continue-button"
                onClick={onContinueClick}
                text='Continue'
            /> : ''
    );

    return (
        <div className="navigation-button-container">
            <MediaQuery maxWidth={displayMinWidth}>
                {continueButton}
                {backButton}
            </MediaQuery>
            <MediaQuery minWidth={displayMinWidth + 1}>
                {backButton}
                {continueButton}
            </MediaQuery>
        </div>
    );
}

ContinueBackNavigation.propTypes = {
    onBackClick: PropTypes.func,
    onContinueClick: PropTypes.func,
    displayMinWidth: PropTypes.number
}

export default ContinueBackNavigation;