import { useLocation } from 'react-router-dom';
import { UrlHelper } from '../../../classes/urlHelper';

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery =() => {
    const url = useLocation().search;
    const queryObject = new UrlHelper().getAllUrlParams(url);
    return queryObject;
}
