export const pages = {
    VEHICLE: 0,
    ALLOY: 1,
    COSMETIC: 2,
    GAP: 3,
    TYRE: 4,
    PERSONAL: 5,
    PURCHASE: 6,
    WARRANTY: 7
};

export const productType = {
    ALLOY: 1,
    COSMETIC: 2,
    GAP: 3,
    TYRE: 4,
    WARRANTY: 5
}

export const pageType = {
    LANDING: 0,
    PRODUCT: 1,
    DRIVER: 2,
    PAYMENT: 3,
}

export const customisationElement = {
    TITLE: 1,
    SUBTITLE: 2,
    STRAPLINE: 3,
    BULLETPOINT: 4,
}

export const pageNames = {
    ALLOY: 'Alloy page',
    COSMETIC: 'Cosmetic page',
    GAP: 'Gap page',
    TYRE: 'Tyre page',
    WARRANTY: 'Warranty page',
    PERSONAL: 'Personal details page',
    PURCHASE: 'Purchase payment page',
    VEHICLE: 'Vehicle page',
    LANDING: 'Landing page',
    SUCCESS: 'Registration success page',
    FAIL: 'Registration failed page'
};

export const paymentMethod = {
    DIRECTDEBIT: 'DD Installments',
    CREDITCARD: 'Credit Card'
};


export const vehicleLookup = {
    TYPES: 1,
    MAKES: 2,
    MODEL: 3,
    FUELTYPE: 4,
    ENGINESIZE: 5,
    MODELTRIM: 6,
    FINISHED: 7
};

export const gapTypes =
{
    GAP: 'Gap Insurance',
    GAPPLUS: 'Gap Insurance plus'

};