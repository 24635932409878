import { ApiHelper } from './apiHelper';

export class AuthApi {

    authenticate(token, callback = null, failCallback = null) {
        var dataUrl = `api/auth/authenticate`;
        var data = { 'token': token };
        new ApiHelper().postDataWithResponse(dataUrl, data, callback, failCallback);
    }
    
}