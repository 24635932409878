import { ClientDateHandler } from '../../../classes/clientDateHandler';

export class CommonValidation {
    static isValidValue(value) {
        var isValid = !((value === null) || (value === undefined) || (value === ''));
        return isValid;
    }

    static isNotEmpty(value) {
        var notEmpty = !CommonValidation.isEmpty(value);
        return notEmpty;
    }

    static isEmpty(value) {
        var isEmpty = ((value === null) || (value === undefined) || (value === ''));
        return isEmpty;
    }

    static isValidDecimal(value) {
        var decimalExpression = /^-{0,1}\d*\.{0,1}\d+$/;
        var isValid = decimalExpression.test(value);
        return isValid;
    }

    static isDecimalWithinRange(valueText) {
        let withinRange = true;

        const value = parseFloat(valueText)
        if(isNaN(value)){
            return false;
        }

        if ((value > 99999999.0) || (value < 0))
            withinRange = false;

        return withinRange;
    }

    static isInteger(value) {
        if (value == null)
            return false;

        var noDecimals = value.toString().split('.').length == 1;
        var isValid = CommonValidation.isValidNumeric(value) && noDecimals;

        return isValid;
    }

    static isValidNumeric(value) {
        var isValid = !isNaN(value);
        return isValid;
    }

    static isValidAlphabetic(value) {
        var alphabeticExpression = /^[ a-zA-Z\.]*$/;
        var isValid = alphabeticExpression.test(value);
        return isValid;
    }

    static isValidAlphaNumeric(value) {
        var alphaNumericExpression = /^[ a-zA-Z0-9\.]*$/;
        var isValid = alphaNumericExpression.test(value);
        return isValid;
    }

    static isValidModel(value) {
        var alphaNumericExpression = /^[ a-zA-Z0-9()\.]*$/;
        var isValid = alphaNumericExpression.test(value);
        return isValid;
    }


    static isValidEmail(value) {
        var emailExpression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        //var emailExpression = /^[ @a-zA-Z0-9\.]*$/;

        var isValid = emailExpression.test(value);
        return isValid;
    }

    static isValidPhone(value) {
        var phoneExpression = /^[\+]?[(]?[0-9]{2,5}[)]?[-\s\.]?[0-9]{6,7}$/;
        var isValid = phoneExpression.test(value);
        return isValid;
    }

    static isStartDateEqualOrPriorToEndDate(startDate, endDate, equalDateValid = false) {
        if (startDate == null || endDate == null)
            return false;

        return equalDateValid ? startDate <= endDate : startDate < endDate;
    }

    static isValidLength(value, maxLength) {
        if (CommonValidation.isEmpty(value))
            return true;

        var isValid = (value.length <= maxLength);        
        return isValid;
    }

    static getJsonDate(jsonDate) {
        if (CommonValidation.isEmpty(jsonDate))
            return null;

        let cdh = new ClientDateHandler();
        cdh.parseJSON(jsonDate);
        let dateValue = cdh.getLocalDate();
        return dateValue;
    }

    static getNullDateJson(isUtc = false) {
        let cdh = new ClientDateHandler();
        cdh.setDateValue(null, isUtc);
        let nullDateJson = cdh.getJSON();
        return nullDateJson;
    }

    static isDateNull(value, isJson) {
        if (isJson) {
            let nullDateJson = this.getNullDateJson();
            let nullDateJsonUtc = this.getNullDateJson(true);
            let dateIsNull = value === nullDateJson || value === nullDateJsonUtc;
            return dateIsNull;
        } else {
            return CommonValidation.isEmpty(value);
        }
    }

    static isValidDate = (value, isJson) => {
        //null dates are valid dates - should use isDateNull in the validation if checking for mandatory dates
        if (this.isDateNull(value, isJson)) {
            return true;
        }

         let dateValue = value;
        if (isJson) {
            dateValue = this.getJsonDate(dateValue);
        }   
        const sqlMinDate = new Date('1000-01-01');
        const sqlMaxDate = new Date('9999-12-31');
        const isValidDate = ((dateValue >= sqlMinDate) && (dateValue <= sqlMaxDate));
        return isValidDate;
    }
}

