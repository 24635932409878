export const FormMode =
{
    Create: 1,
    Edit: 2,
    List: 3
};

export const ComplaintUrl =
{
    Export: 'export'
};

export const Currency =
{
    Unknown: 0,
    Pound: 1,
    Euro: 2,
    Dollar: 3,
    KoreanWon: 4,
    ChineseYen: 5,
    BrazilianLira: 6,
}