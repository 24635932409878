import React from 'react';
import { BasketStateContext, BasketDispatchContext } from './basketContext';
import { CommonValidation } from '../components/common/commonValidation/commonValidation';
import { PriceHelper } from '../classes/priceHelper';
import { paymentMethod } from "../classes/enums";
import uuid from 'uuid';

function basketReducer(state, action) {
    switch (action.type) {
        case 'addProductsDistinct': {
            return {
                ...state,
                productsDistinct: [...state.productsDistinct, action.product]
            }
        }
        case 'addProductsAll': {
            return {
                ...state,
                productsAll: state.productsAll.concat(action.productsAll)
            }
        }
        case 'removeProduct': {
            return {
                ...state,
                productsDistinct: state.productsDistinct.filter(product => product.Id !== action.id),
                productsAll: state.productsAll.filter(product => product.productTypeId !== action.id)
            }
        }
        case 'clearProducts': {
            return {
                ...state,
                productsDistinct: [],
                productsAll: [],
                basketOpen: false,
            }
        }
        case 'hideBasket': {
            return {
                ...state,
                finishedJourney: true,
            }
        }
        case 'showBasket': {
            return {
                ...state,
                finishedJourney: false,
            }
        }
        case 'setBasketExpanded': {
            return {
                ...state,
                basketOpen: action.setOpen,
            }
        }
        case 'setBearerToken': {
            return {
                ...state,
                bearerToken: action.bearerToken,
                setBearerTokenSuccess: true
            }
        }
        case 'resetSetBearerTokenSuccess': {
            return {
                ...state,
                setBearerTokenSuccess: false
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

const initialState = {
    productsDistinct: [],
    productsAll: [],
    basketOpen: false,
    bearerToken: null,
    setBearerTokenSuccess: false,
    customerJourneyId: uuid()
}

const BasketProvider = ({ children }) => {

    const [state, dispatch] = React.useReducer(basketReducer, initialState);

    return (
        <BasketStateContext.Provider value={state}>
            <BasketDispatchContext.Provider value={dispatch}>
                {children}
            </BasketDispatchContext.Provider>
        </BasketStateContext.Provider>
    )
}

function useBasketState() {
    const context = React.useContext(BasketStateContext)
    if (context === undefined) {
        throw new Error('useBasketState must be used within a BasketProvider')
    }
    return context
}

function useBasketDispatch() {
    const context = React.useContext(BasketDispatchContext)
    if (context === undefined) {
        throw new Error('useBasketDispatch must be used within a BasketProvider')
    }
    return context
}

const mapProduct = (directDebitProduct, singlePaymentProduct, productName) => {
    let Id = undefined;
    let name = undefined;
    let currency = undefined;
    let singleClaimLimit = undefined;
    let singlePaymentPrice = undefined;   
    let directDebitTotalPrice = undefined;
    let directDebitDuration = undefined;
    let directDebitDividedPrice = undefined;
    let effectiveDate = undefined;
    let warrantyVariantId = undefined;
    let warrantyTierId = undefined;
    let policyDuration = 0;

    let hasDirectDebit = false;
    let hasSinglePayment = false;

    let priceDetails = {
        totalAmount: 0,
        noOfInstallments: 0,
        installmentAmount: 0,
        firstInstallmentAmount: 0,
        hasEqualInstallments: false
    };

    if (singlePaymentProduct) {
        hasSinglePayment = true;
        Id = singlePaymentProduct.productTypeId;
        name = productName === undefined ? singlePaymentProduct.productType : productName;
        currency = singlePaymentProduct.currency;
        singleClaimLimit = singlePaymentProduct.singleClaimLimit;
        singlePaymentPrice = singlePaymentProduct.price;
        effectiveDate = singlePaymentProduct.effectiveDate;
        warrantyVariantId = singlePaymentProduct.warrantyVariantId;
        warrantyTierId = singlePaymentProduct.warrantyTierId;
        policyDuration = singlePaymentProduct.duration;
    }

    if (directDebitProduct) {
        hasDirectDebit = true;
        Id = directDebitProduct.productTypeId;
        name = productName === undefined ? directDebitProduct.productType : productName;
        currency = directDebitProduct.currency;
        singleClaimLimit = directDebitProduct.singleClaimLimit;
        directDebitTotalPrice = directDebitProduct.price;
        directDebitDuration = directDebitProduct.noOfInstallments;
        directDebitDividedPrice = directDebitDuration !== 0 ? (directDebitTotalPrice / directDebitDuration).toFixed(2) : undefined;
        effectiveDate = directDebitProduct.effectiveDate;
        warrantyVariantId = directDebitProduct.warrantyVariantId;
        warrantyTierId = directDebitProduct.warrantyTierId;
        priceDetails = new PriceHelper().getPriceDetailsDirectDebit(directDebitProduct.price, directDebitProduct.noOfInstallments);
        policyDuration = directDebitProduct.duration;
    }    

    const mappedProduct = {
        Id,
        name,
        currency,
        singleClaimLimit,
        directDebitTotalPrice,
        directDebitDividedPrice,
        directDebitDuration,
        singlePaymentPrice,
        hasDirectDebit,
        hasSinglePayment,
        effectiveDate,
        warrantyVariantId,
        warrantyTierId,
        priceDetails,
        policyDuration
    }

    return mappedProduct;
}

const addProductSuccess = (dispatch, currentProducts, newProducts, productName) => {

    const directDebitProduct = newProducts.filter(p => CommonValidation.isNotEmpty(p.paymentMethods)
        && p.paymentMethods.includes(paymentMethod.DIRECTDEBIT))[0];

    const singlePaymentProduct = newProducts.filter(p => CommonValidation.isNotEmpty(p.paymentMethods)
        && p.paymentMethods.includes(paymentMethod.CREDITCARD))[0];

    const productId = directDebitProduct ? directDebitProduct.productTypeId : singlePaymentProduct.productTypeId;

    const hasProduct = currentProducts.find(product => {return product.Id == productId });

    if (hasProduct){
        console.log("Already has item");
        return false;
    }

    const mappedProduct = mapProduct(directDebitProduct, singlePaymentProduct, productName);
    dispatch({ type: 'addProductsDistinct', product: mappedProduct });
    dispatch({ type: 'addProductsAll', productsAll: newProducts });
    
    return true;
}

const removeProduct = (dispatch, productId) => {
    dispatch({ type: 'removeProduct', id: productId });
}

const clearProducts = (dispatch) => {
    dispatch({ type: 'clearProducts', products: [] })
}

const hideBasket = (dispatch) => {
    dispatch({ type: 'hideBasket' });
}

const showBasket = (dispatch) => {
    dispatch({ type: 'showBasket' });
}

const setBasketExpanded = (dispatch, setOpen) => {
    dispatch({ type: 'setBasketExpanded', setOpen })
}

const setBearerToken = (dispatch, bearerToken) => {
    dispatch({ type: 'setBearerToken', bearerToken });
}

const resetSetBearerTokenSuccess = (dispatch) => {
    dispatch({ type: 'resetSetBearerTokenSuccess' });
}

export {
    BasketProvider, useBasketState, useBasketDispatch, addProductSuccess, removeProduct, clearProducts, hideBasket, showBasket, setBasketExpanded,
    setBearerToken, resetSetBearerTokenSuccess
};
