import "./toastr.scss"
import toastr from 'toastr';


function setOptions(timeout, styleClass) {
    toastr.options = {
        "toastClass": styleClass,
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": timeout,
        "extendedTimeOut": "0",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
    };
}

function showToast(context, message, title = null, timeout = null, theme = null) {

    switch (context) {
        case 'error':
            setOptions(timeout || "0", "alert-error");
            toastr.error(message, title);
            break;
        case 'info':
            setOptions(timeout || "0", "alert-info");
            toastr.info(message, title);
            break;
        case 'warning':
            setOptions(timeout || "0", "alert-warning");
            toastr.warning(message, title);
            break;
        case 'success':
            setOptions(timeout || "0", "alert-success");
            toastr.success(message, title);
            break;
        case 'custom':
            setOptions(timeout || "0", theme);
            toastr.success(message, title);
            break;
        default:
            break;
    }
}

export function showErrorToast(message, title = null, timeout = null) {
    showToast('error', message, title, timeout);
}

export function showInfoToast(message, title = null, timeout = null) {
    showToast('info', message, title, timeout);
}

export function showWarningToast(message, title = null, timeout = null) {
    showToast('warning', message, title, timeout);
}

export function showSuccessToast(message, title = null, timeout = null) {
    showToast('success', message, title, timeout);
}

export function showCustomToast(message, theme, title = null, timeout = null) {
    showToast('custom', message, title, timeout, theme);
}

