import {Currency} from '../enums';

export class CurrencySymbols{
    static pound = "\u00A3"
    static euro = "\u20AC"
    static dollar = "\u0024"
    static koreanWon = "\u20A9"
    static chineseYen = "\u00A5"
    static brazilianLira = "R\u0024"
}

export const getCurrencySymbol = (currency) => {
        let currencySymbol = '';

        switch (currency) {

            case Currency.Pound:
                currencySymbol = CurrencySymbols.pound;
                break;
            case Currency.Euro:
                currencySymbol = CurrencySymbols.euro;
                break;
            case Currency.Dollar:
                currencySymbol = CurrencySymbols.dollar;
                break;
            case Currency.KoreanWon:
                currencySymbol = CurrencySymbols.koreanWon;
                break;
            case Currency.ChineseYen:
                currencySymbol = CurrencySymbols.chineseYen;
                break;
            case Currency.BrazilianLira:
                currencySymbol = CurrencySymbols.brazilianLira;
                break;
            default:
                break;
        }

        return currencySymbol;
    }

