import React from 'react'
import PropTypes from 'prop-types';
import './progressNavigation.scss';
import _ from 'lodash';
import { pages } from '../../../classes/enums'
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faUser, faCreditCard } from '@fortawesome/free-solid-svg-icons';

const ProgressNavigation = ({ navigationItems, onNavigationItemClick, hideNavBar, iconVisible }) => {

    const getImageClass = (page) => {
        let imageName = null;

        switch (page) {
            case parseInt(pages.ALLOY, 10):
                imageName = 'alloy-image';
                break;
            case parseInt(pages.COSMETIC, 10):
                imageName = 'cosmetic-image';
                break;
            case parseInt(pages.GAP, 10):
                imageName = 'gap-image';
                break;
            case parseInt(pages.TYRE, 10):
                imageName = 'tyre-image';
                break;
            case parseInt(pages.WARRANTY, 10):
                imageName = 'warranty-image';
                break;
            default:
                return imageName;
        }

        return imageName;
    }

    const getImagePanel = (page) => {
        let imagePanel = '';
        let imageClass = getImageClass(page);
        let gapOffset = '';
        let gapScaleUp = '';

        if (imageClass != null) {
            imagePanel = (
                <div className={`navigationImage ${imageClass} ${gapOffset} ${gapScaleUp}`}>
                </div>
            );
        }

        return imagePanel;
    }

    const getIcon = (page) => {
        switch (page) {
            case parseInt(pages.VEHICLE, 10):
                return <FontAwesomeIcon icon={faCar} size="3x"/>;
                break;
            case parseInt(pages.PERSONAL, 10):
                return <FontAwesomeIcon icon={faUser} size="3x" />;
                break;
            case parseInt(pages.PURCHASE, 10):
                return <FontAwesomeIcon icon={faCreditCard} size="3x" />;
                break;
            default:
                return null;
        }
    }

    const getIconPanel = (page) => {
        let iconPanel = '';
        let icon = getIcon(page);

        if (icon != null) {
            iconPanel = (
                <div className="navigationImage text-right">
                    {icon}
                </div>
            );
        }
        return iconPanel;
    }

    const getLinkClasses = (disabled, active) => {
        let linkClasses = "nav-link navigationLink";

        if (disabled === true) {
            linkClasses = linkClasses + " next";
        }
        else {
            if (active === true) {
                linkClasses = linkClasses + " active";
            } else {
                linkClasses = linkClasses + " previous";
            }
        }

        return linkClasses;
    }

    const renderNavigationItem = (index, page, text, active, disabled, order, isMobile = false) => {

        let itemClasses = `nav-item order-${order} navigation-item`

        if (active) {
            itemClasses = itemClasses + " navigation-item-active";
        }

        if (disabled) {
            itemClasses = itemClasses + " disabled";
        }

        let linkClasses = getLinkClasses(disabled, active);

        let imagePanel = iconVisible ? getImagePanel(page) : <React.Fragment/>;

        let iconPanel =  iconVisible ? getIconPanel(page) : <React.Fragment/>;

        let navItem = '';

        let navItemWidthDesktop = (100 / navigationItems.filter(ni => ni.visible).length) + '%';

        if (isMobile) {
            navItem = (
                <li key={index} className={itemClasses}>
                    <a className={linkClasses} data-toggle="pill" href="#" onClick={e => onItemClick(e, order)}>
                        <div className="navigationNumber">{index + 1}</div>
                        <div className="navigationText">{active && text}</div>
                    </a>
                </li>
            );
        }
        else {
            navItem = (
                <li key={index} className={itemClasses} style={{ width: navItemWidthDesktop, maxWidth: '14.28%' }}>
                    <a className={linkClasses} data-toggle="pill" href="#" onClick={e => onItemClick(e, order)}>
                        <div className="navigationText">{text}</div>
                        {imagePanel}
                        {iconPanel}
                    </a>
                </li>
            );
        }

        return navItem;
    }

    const renderShortNavigation = () => {
        let panel = '';

        //as numbers are shown in the short navigation we need to make sure that they are ordered and sequential
        const visibleItems = _.filter(navigationItems, 'visible');
        const orderedVisibleItems = _.sortBy(visibleItems, ['order']);

        panel = (
            <ul className="nav nav-pills mobile-navigation">
                {
                    orderedVisibleItems.map((item, index) => renderNavigationItem(index, item.page, item.text, item.active, item.disabled, item.order, true))
                }
            </ul>
        );

        return panel;
    }

    const renderWideNavigation = () => {
        return (
            <ul className="nav nav-pills navigation">
                {
                    navigationItems.filter(ni => ni.visible)
                        .map((item, index) => renderNavigationItem(index, item.page, item.text, item.active, item.disabled, item.order))
                }
            </ul>
        );
    }

    const onItemClick = (e, order) => {
        e.preventDefault();
        const clickedItem = _.find(navigationItems, ['order', order]);
        if (clickedItem) {
            onNavigationItemClick(clickedItem.page);
        }
    }

    return (
        <React.Fragment>
            {!hideNavBar && (< div >
                <MediaQuery maxWidth='574.99px'>
                    {renderShortNavigation()}
                </MediaQuery>
                <MediaQuery minWidth='575px'>
                    {renderWideNavigation()}
                </MediaQuery>
            </div>)}
        </React.Fragment>
    );
}

ProgressNavigation.propTypes = {
    navigationItems: PropTypes.array.isRequired,
    onNavigationItemClick: PropTypes.func.isRequired,
    hideNavBar: PropTypes.bool
}

export default ProgressNavigation;

