import ReactGa from 'react-ga';
import { CommonValidation } from '../components/common/commonValidation/commonValidation';
import { pages, pageNames } from './enums';

export class GoogleAnalytics {

    static initialiseGoogleAnalytics(customisations) {

        if (CommonValidation.isNotEmpty(customisations) && customisations.googleAnalyticsEnable) {
            ReactGa.initialize(customisations.googleAnalyticsTrackingId);
        }
    }

    static trackPageViewGoogleAnalytics(selectedPage, googleAnalyticsEnable, brandName) {

        switch (selectedPage) {
            case pages.ALLOY:
                this.setPageViewGoogleAnalytics(pageNames.ALLOY, googleAnalyticsEnable, brandName);
                break;

            case pages.COSMETIC:
                this.setPageViewGoogleAnalytics(pageNames.COSMETIC, googleAnalyticsEnable, brandName);
                break;

            case pages.GAP:
                this.setPageViewGoogleAnalytics(pageNames.GAP, googleAnalyticsEnable, brandName);
                break;

            case pages.TYRE:
                this.setPageViewGoogleAnalytics(pageNames.TYRE, googleAnalyticsEnable, brandName);
                break;

            case pages.WARRANTY:
                this.setPageViewGoogleAnalytics(pageNames.WARRANTY, googleAnalyticsEnable, brandName);
                break;

            case pages.PERSONAL:
                this.setPageViewGoogleAnalytics(pageNames.PERSONAL, googleAnalyticsEnable, brandName);
                break;

            case pages.PURCHASE:
                this.setPageViewGoogleAnalytics(pageNames.PURCHASE, googleAnalyticsEnable, brandName);
                break;

            case pages.VEHICLE:
                this.setPageViewGoogleAnalytics(pageNames.VEHICLE, googleAnalyticsEnable, brandName);
                break;
        }
    }

    static setPageViewGoogleAnalytics(pageName, googleAnalyticsEnable, brandName) {

        if (googleAnalyticsEnable) {

            const pageNameWithBrand = `${brandName}-${pageName}`;

            ReactGa.ga('set', 'location', pageNameWithBrand);

            ReactGa.pageview(pageNameWithBrand);
        }
    }
    static trackEventsGoogleAnalytics(category, action, label, googleAnalyticsEnable) {

        if (googleAnalyticsEnable) {
            ReactGa.event({
                category: category,
                action: action,
                label: label
            });
        }
    }
}

