import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./landingPage.scss";
import { productType } from "../../classes/enums";
import DefaqtoAlloy from "../../images/Defaqto_Alloy.png";
import DefaqtoDamage from "../../images/Defaqto_Damage.png";
import DefaqtoGap from "../../images/Defaqto_Gap.png";
import DefaqtoTyre from "../../images/Defaqto_Tyre.png";
import { CommonValidation } from "../common/commonValidation/commonValidation";

const ProductCard = ({
  product,
  onFindOutMore,
  defactoVisible,
  iconVisible,
}) => {
  let productImageClass = "";
  let defaqtoImage;
  let _defactoVisible = defactoVisible;
  let _iconVisible = iconVisible === undefined ? true : iconVisible;

  const productImage = () => {
    if (
      CommonValidation.isNotEmpty(product) &&
      CommonValidation.isNotEmpty(product.type)
    ) {
      switch (product.type) {
        case productType.ALLOY:
          productImageClass = "alloy-image-landing";
          defaqtoImage = DefaqtoAlloy;
          break;
        case productType.COSMETIC:
          productImageClass = "cosmetic-image-landing";
          defaqtoImage = DefaqtoDamage;
          break;
        case productType.GAP:
          productImageClass = "gap-image-landing";
          defaqtoImage = DefaqtoGap;
          break;
        case productType.TYRE:
          productImageClass = "tyre-image-landing";
          defaqtoImage = DefaqtoTyre;
          break;
        case productType.WARRANTY:
          productImageClass = "warranty-image-landing";
          break;
        default:
          _defactoVisible = false;
          _iconVisible = false;
          break;
      }
    } else {
      _defactoVisible = false;
      _iconVisible = false;
    }
  };

  productImage();

  const mapBulletPoints = (bulletPoints) => {
    let panel = CommonValidation.isEmpty(bulletPoints) ? (
      <Fragment />
    ) : (
      bulletPoints.map((bullet, i) => <li key={i}>{bullet}</li>)
    );

    return panel;
  };

  const hasSubTitle =
    product != undefined &&
    product.subTitle != null &&
    product.subTitle.length > 0;
  const subtitle = hasSubTitle && (
    <p data-testid="subtitle">{product.subTitle}</p>
  );

  const straplineTwo = CommonValidation.isEmpty(product.straplineTwo) ? (
    <Fragment />
  ) : (
    <p data-testid="strapline2" className="card-text card-text-product">
      {product.straplineTwo}
    </p>
  );

  const secondaryBulletPoints = CommonValidation.isEmpty(
    product.bulletPointsTwo
  ) ? (
    <Fragment />
  ) : (
    <ul className="card-bullet-list card-bullet-list-product">
      {mapBulletPoints(product.bulletPointsTwo)}
    </ul>
  );

    const straplineThree = CommonValidation.isEmpty(
        product.straplineThree
    ) ? (
        <Fragment />
    ) : (
      <p className="card-text card-text-product">
        {product.straplineThree}
      </p>
    ); 

  return (
    <div className="card card-product">
      <div className="card-header card-header-product">
        {_iconVisible && (
          <div
            data-testid="product-icon"
            className={`top-image-container ${productImageClass}`}
          ></div>
        )}
        <div className="title">{product.title}</div>
        {subtitle}
      </div>
      <div className="card-body card-body-product">
        <div className="card-min-height">
          <p className="card-text card-text-product">{product.strapline}</p>
          <ul className="card-bullet-list card-bullet-list-product">
            {mapBulletPoints(product.bulletPoints)}
          </ul>
          {straplineTwo}
          {secondaryBulletPoints}
          {straplineThree}
        </div>
      </div>
      <div className="card-footer card-footer-container">
        <div className="defacto-container">
          {_defactoVisible && (
            <img
              className="defacto-image"
              src={defaqtoImage}
              alt="defacto rating"
            />
          )}
        </div>
        <button
          className="btn find-out-more-button"
          onClick={() => onFindOutMore(product.type)}
        >
          FIND OUT MORE
        </button>
      </div>
    </div>
  );
};

export default ProductCard;

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  onFindOutMore: PropTypes.func.isRequired,
  defactoVisible: PropTypes.bool.isRequired,
  iconVisible: PropTypes.bool,
};
