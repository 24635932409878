import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { LoadingContainer } from '../common/loader/loader';
import ManageCustomerDetails from '../main/customer/manageCustomerDetails';
import ManageVehicleDetails from '../main/vehicle/manageVehicleDetails';
import ProgressNavigation from '../main/navigation/progressNavigation';
import ManageProductPage from '../main/product/manageProductPage';
import ManagePurchasePage from '../main/purchase/managePurchasePage';
import { pages } from '../../classes/enums'
import { productType } from "../../classes/enums";
import ShoppingBasket from '../main/shoppingBasket/shoppingBasket';
import DismissBackdrop from '../common/dismissBackdrop/dismissBackdrop';
import { useBasketState } from '../../context/basketProvider';
import './mainPage.scss';

const MainPage = ({ vehicleDetails, products, customerDetails, page, loading, onVehicleDetailsChange, onBulkVehicleDetailChange, addProductToBasket,
    onCustomerDetailsChange, nextPage, previousPage, purchasePageLinks, updateNavigation, onAddressFound, showAmendVehicleWarning,
    businessPartnerName, brandName, loadProducts, updateProducts, basketProductsDistinct, basketProductsAll,
    hideNavBar, updatePolicies, iconVisible }) => {

    const { finishedJourney, basketOpen } = useBasketState();
    
    const renderPage = () => {

        switch (page) {
            case pages.ALLOY:
                return (<ManageProductPage
                    productType={productType.ALLOY}
                    productsAll={products}
                    addProductToBasket={addProductToBasket}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    loadProducts={loadProducts}
                    updateProducts={updateProducts}
                />);
                break

            case pages.GAP:
                return (<ManageProductPage
                    productType={productType.GAP}
                    productsAll={products}
                    addProductToBasket={addProductToBasket}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    loadProducts={loadProducts}
                    updateProducts={updateProducts}
                />);
                break

            case pages.TYRE:
                return (<ManageProductPage
                    productType={productType.TYRE}
                    productsAll={products}
                    addProductToBasket={addProductToBasket}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    loadProducts={loadProducts}
                    updateProducts={updateProducts}
                />);
                break;

            case pages.COSMETIC:
                return (<ManageProductPage
                    productType={productType.COSMETIC}
                    productsAll={products}
                    addProductToBasket={addProductToBasket}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    loadProducts={loadProducts}
                    updateProducts={updateProducts}
                />);
                break;

            case pages.WARRANTY:
                return (<ManageProductPage
                    productType={productType.WARRANTY}
                    productsAll={products}
                    addProductToBasket={addProductToBasket}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    loadProducts={loadProducts}
                    updateProducts={updateProducts}
                />);
                break;

            case pages.PERSONAL:
                return (<ManageCustomerDetails
                    customerDetails={customerDetails}
                    onCustomerDetailsChange={onCustomerDetailsChange}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    onAddressFound={onAddressFound}
                />);
                break;

            case pages.PURCHASE:
                return (<ManagePurchasePage
                    vehicleDetails={vehicleDetails}
                    basketProductsDistinct={basketProductsDistinct}
                    basketProductsAll={basketProductsAll}
                    customerDetails={customerDetails}
                    businessPartnerName={businessPartnerName}
                    brandName={brandName}
                    previousPage={previousPage}
                    updatePolicies={updatePolicies}
                />);
                break;
            case pages.VEHICLE:
            default:
                return (<ManageVehicleDetails
                    vehicleDetails={vehicleDetails}
                    onVehicleDetailsChange={onVehicleDetailsChange}
                    onBulkVehicleDetailChange={onBulkVehicleDetailChange}
                    nextPage={nextPage}
                    showAmendVehicleWarning={showAmendVehicleWarning}
                />);
        }
    }

    return (
        <Fragment>
            {basketOpen && <DismissBackdrop />}
            <div className="header-fixed">
                <header>
                    <div className="header-logo-container">
                        <div className="header-logo"></div>
                    </div> 
                    <div className="basket-icon">
                        { finishedJourney !== true && <ShoppingBasket />}
                    </div>
                </header>
                <ProgressNavigation
                    navigationItems={purchasePageLinks}
                    onNavigationItemClick={newPage => updateNavigation(newPage)}
                    products={products}
                    hideNavBar={hideNavBar}
                    iconVisible={iconVisible}
                    />
            </div>
            <main className="main-positioning">
                <LoadingContainer loading={loading} colourClass="loading-spinner-themed">
                    <div className="container raise-above-footer">
                        {renderPage()}
                    </div>
                </LoadingContainer>
            </main>
            <footer>
                <a href="http://www.view-privacy-policy.co.uk/Privacy-Policy" target="_blank" rel="noopener noreferrer">Privacy policy</a>
                <a href="../html/termsAndConditions.html" target="_blank" rel="noopener noreferrer" >Terms & conditions</a>
            </footer>            
        </Fragment>
    );
}

MainPage.propTypes = {
    vehicleDetails: PropTypes.object.isRequired,
    products: PropTypes.array,
    customerDetails: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    onVehicleDetailsChange: PropTypes.func.isRequired,
    onBulkVehicleDetailChange: PropTypes.func.isRequired,
    addProductToBasket: PropTypes.func.isRequired,
    onCustomerDetailsChange: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    purchasePageLinks: PropTypes.array.isRequired,
    businessPartnerName: PropTypes.string.isRequired,
    brandName: PropTypes.string.isRequired,
    loadProducts: PropTypes.func.isRequired,
    updateProducts: PropTypes.func.isRequired,
    basketProductsDistinct: PropTypes.array.isRequired,
    basketProductsAll: PropTypes.array.isRequired,
    updatePolicies: PropTypes.func.isRequired
}

export default MainPage;