import React, { Fragment } from 'react';
import './vehicleDetails.scss';

const Disclaimer = () => {

    return (
        <Fragment>
            <p className="disclaimer">We will process the data you provide in line with our
                        <a href='http://www.view-privacy-policy.co.uk/Privacy-Policy' target='_blank' rel='noopener noreferrer'> Privacy Policy</a>.
            </p>
        </Fragment>
    );
};

export default Disclaimer;