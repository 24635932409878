import React, { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NumberInput } from '../../common/numberInput/numberInput';
import { DateInput } from '../../common/dateInput';
import { DangerAlert } from '../../common/alerts/DangerAlert';
import { TextButtonInput } from './../../common/textButtonInput';
import './vehicleDetails.scss';
import ReCAPTCHA from "react-google-recaptcha";
import { ThemeContext } from '../../../context/themeContext';
import ContinueBackNavigation from '../navigation/continueBackNavigation';
import { AuthApi } from '../../../api/authApi';
import { useBasketState, useBasketDispatch, setBearerToken, resetSetBearerTokenSuccess } from '../../../context/basketProvider';
import { tabletResponsiveResolutionWidth } from '../../../classes/constants';
import VehicleFoundForm from './vehicleFoundForm';
import VehicleSelectForm from './vehicleSelectForm';
import ReCaptchaBlurb from './reCaptchaBlurb';
import { CheckBoxInput } from '../../common/checkBoxInput/checkBoxInput';
import Disclaimer from './disclaimer';

const VehicleDetails = ({ vehicleDetails, vehicleDetailErrors, onChangeDetail, onChangeDetailWithLookup, onToggleVehicleReg, continueClicked, onFindClick, finding,
    showAmendVehicleWarning, vehicleTypes, vehicleMakes, vehicleModels, vehicleFuelTypes, vehicleEngineSizes, vehicleModelTrims, FoundVehicleOnSearch
    , manualQuerySelections, onLeftManualRegistration }) => {

    const { setBearerTokenSuccess, bearerToken } = useBasketState();
    const dispatchToken = useBasketDispatch();
    const { captchaKey, captchaDisable } = useContext(ThemeContext);

    useEffect(() => {
        if (setBearerTokenSuccess) {
            if (!vehicleDetails.isManualMode) {
                onFindClick();
            }

            resetSetBearerTokenSuccess(dispatchToken);
        }
    }, [setBearerTokenSuccess])

    const recaptchaRef = React.createRef();

    const executeCaptcha = () => {
        if (bearerToken != null && vehicleDetails.isManualMode) {
            return;
        }

        if (captchaDisable == true) {
            authenticate('');
        }
        else {
            recaptchaRef.current.reset();
            recaptchaRef.current.execute();
        }
    };

    const renderAdditionalFields = () => {
        let additionalFieldView;

        if (vehicleDetails.showVehicleControls) {
            additionalFieldView = vehicleDetails.vehicleDetailsFound ?
                <VehicleFoundForm vehicleDetails={vehicleDetails} vehicleDetailErrors={vehicleDetailErrors} onChangeDetail={onChangeDetail} />
                : <VehicleSelectForm vehicleDetails={vehicleDetails} vehicleDetailErrors={vehicleDetailErrors}
                    onChangeDetailWithLookup={onChangeDetailWithLookup} onChangeDetail={onChangeDetail} vehicleTypes={vehicleTypes} vehicleMakes={vehicleMakes}
                    vehicleModels={vehicleModels} vehicleFuelTypes={vehicleFuelTypes} vehicleEngineSizes={vehicleEngineSizes} vehicleModelTrims={vehicleModelTrims}
                    onLeaveVehicleReg={executeCaptcha} manualQuerySelections={manualQuerySelections} bearerToken={bearerToken} />

            return additionalFieldView;
        }
        return;
    }

    const renderWarning = () => {
        let panel = '';

        if (showAmendVehicleWarning) {
            panel = (
                <div className="alert alert-warning mt-2" role="alert">
                    Changing the vehicle detail will reset your product selection.
                </div>
            );
        }

        return panel;
    }

    const additionalFields = renderAdditionalFields();
    const warningPanel = renderWarning();

    const authenticate = (response) => {
        const successCallback = (data) => {
            setBearerToken(dispatchToken, data.token);
        }

        const failureCallback = (err) => {
            console.log(err);
        }

        new AuthApi().authenticate(response, successCallback, failureCallback);
    }

    const onCaptchaDone = (response) => {
        if (response) {
            authenticate(response);
        }
    };

    const renderVRegSearchUI = () => {
        if (vehicleDetails.isManualMode) {
            return <Fragment />
        } else {
            return (<div className="row">
                <div className="col">
                    <TextButtonInput
                        className="registration"
                        themeClass="button-theme-find-vehicle-small"
                        haslabel
                        name="vehicle-registration"
                        label="Vehicle registration"
                        error={vehicleDetailErrors.registration}
                        value={vehicleDetails.registration}
                        onChange={(e) => onChangeDetail(e, 'registration')}
                        onButtonClick={executeCaptcha}
                        readonly={vehicleDetails.isManualMode}
                        buttonText="Find vehicle"
                        waitValue={finding}
                        buttonDisabled={finding}
                    />
                    <ReCaptchaBlurb />
                    <Disclaimer />
                </div>

            </div>)
        }
    }

    const manualOrAutomaticUI = renderVRegSearchUI();

    const registrationPageError = FoundVehicleOnSearch ? <Fragment /> :
        <DangerAlert
            animated
            includeImage
            message="Sorry, we have not been able to confirm all of the vehicle details.  Please enter the correct vehicle details manually."
    />

    return (
        <Fragment>
            {warningPanel}
            <ReCAPTCHA
                sitekey={captchaKey}
                ref={recaptchaRef}
                size="invisible"
                onChange={onCaptchaDone}
            />
            <form className="vehicle-detail-panel">
                <div className="row">
                    <div className="col">
                        <p>To ensure that we direct you to the correct product for your vehicle, we require some more information. Please complete the following fields to enable us to provide your quote</p>
                    </div>
                </div>
                {registrationPageError}
                <div className="row mt-3">
                    <div className="col-auto">
                        <CheckBoxInput
                            haslabel
                            label="Manually enter details"
                            name="manual-registration-toggle"
                            value={vehicleDetails.isManualMode}
                            onCheckboxChange={(e) => onToggleVehicleReg(e)}
                            horizontalLayout
                        />
                    </div>
                </div>
                {manualOrAutomaticUI}
                {additionalFields}
                <hr />
                <div className="row mt-3">
                    <div className="col-auto">
                        <DateInput
                            haslabel
                            label="Vehicle purchase date"
                            placeholder="Select date..."
                            name="vehicle-purchase-date"
                            useJson
                            forceTimeReset
                            error={vehicleDetailErrors.purchaseDate}
                            value={vehicleDetails.purchaseDate || null}
                            displayMinWidth={tabletResponsiveResolutionWidth}
                            onDateChange={(e) => { onChangeDetail(e, 'purchaseDate') }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <NumberInput
                            haslabel
                            name="current-mileage"
                            label="Current mileage"
                            onChange={(e) => onChangeDetail(e, 'currentMileage')}
                            error={vehicleDetailErrors.currentMileage}
                            value={vehicleDetails.currentMileage}
                            decimalPlaces={0} />
                    </div>
                </div>
                <ContinueBackNavigation onContinueClick={continueClicked} displayMinWidth={420} />
            </form >
        </Fragment >
    );
}

VehicleDetails.propTypes = {
    vehicleDetails: PropTypes.object.isRequired,
    vehicleDetailErrors: PropTypes.object,
    finding: PropTypes.bool.isRequired,
    FoundVehicleOnSearch: PropTypes.bool,
    onFindClick: PropTypes.func.isRequired,
    onChangeDetail: PropTypes.func.isRequired,
    onToggleVehicleReg: PropTypes.func.isRequired,
    continueClicked: PropTypes.func.isRequired,
    onChangeDetailWithLookup: PropTypes.func,
    vehicleTypes: PropTypes.arrayOf(PropTypes.object),
    vehicleMakes: PropTypes.arrayOf(PropTypes.object),
    vehicleModels: PropTypes.arrayOf(PropTypes.object),
    vehicleFuelTypes: PropTypes.arrayOf(PropTypes.object),
    vehicleEngineSizes: PropTypes.arrayOf(PropTypes.object),
    vehicleModelTrims: PropTypes.arrayOf(PropTypes.object),
    manualQuerySelections: PropTypes.object
}

export default VehicleDetails;

