const PurchaseReducer = (state, action) => {
    switch (action.type) {
        case 'show-single-form':
            return {
                ...state,
                showSingleForm: true,
                showDirectDebitForm: false,
                paymentInProgress: true
            };

        case 'show-direct-debit-form':
            return {
                ...state,
                showSingleForm: false,
                showDirectDebitForm: true,
                paymentInProgress: false //if DD we can allow the user to go back a page
            };

        case 'update-direct-debit':
            return {
                ...state,
                directDebit: {
                    ...state.directDebit,
                    [action.key]: action.value
                }
            };

        case 'update-direct-debit-errors':
            return {
                ...state,
                directDebitErrors: action.directDebitErrors
            };

        case 'update-show-confirmation':
            return {
                ...state,
                loading: false,
                showConfirmation: action.showConfirmation,
                paymentError: action.paymentError
            };

        case 'update-loading':
            return {
                ...state,
                loading: action.value
            };

        case 'update-policies':
            return {
                ...state,
                policies: action.policies
            };
        case 'update-sagepay-transaction':

            return {
                ...state,
                sagePayTransaction: {
                    id: action.sagePayTransactionId,
                    nextUrl: action.nextUrl,
                    status: action.status,
                    statusDetail: action.statusDetail
                }                
            };
        case 'reset-payment-forms':
            return {
                ...state,
                showSingleForm: false,
                showDirectDebitForm: false,
                paymentInProgress: false,
                sagePayTransaction: {
                    id: "",
                    nextUrl: "",
                    status: "",
                    statusDetail: ""
                },
            };
        case 'selected-preferred-payment-day':
            return {
                ...state,
                selectedPreferredPaymentDay: action.selectedPreferredPaymentDay
            };
        
        default:
            return state;
    }
}

export default PurchaseReducer;
