const CustomerReducer = (state, action) => {
    switch (action.type) {
        case 'update-errors':
            return {
                ...state,
                customerDetailErrors: action.customerDetailErrors
            };

        case 'update-search-postcode':
            return {
                ...state,
                searchPostcode: action.searchPostcode
            };

        case 'set-addresses':
            return {
                ...state,
                addressList: action.addressList
            };

        case 'update-selected-address':
            return {
                ...state,
                selectedAddress: action.selectedAddress
            };

        case 'show-address-list':
            return {
                ...state,
                showAddressList: action.show
            };

        case 'show-address-form':
            return {
                ...state,
                showAddressForm: action.show
            };

        case 'update-last-searched-postcode':
            return {
                ...state,
                lastSearchedPostcode: action.lastSearchedPostcode
            };

        case 'update-finding':
            return {
                ...state,
                finding: action.finding
            };

        default:
            return state;
    }
}

export default CustomerReducer;
