
export class CssClass {
    static FromString(cssClasses) {
        const classes = cssClasses.split(' ');
        return classes;
    }

    static FromArray(cssClassesArray) {
        const classes = cssClassesArray;
        return classes;
    }

    constructor() {
        this.classes = [];
    }

    reset = () => {
        this.classes = [];
    }

    empty = () => {
        return this.classes.length == 0;
    }

    asString = () => {
        var classesText = this.classes.join(' ');
        return classesText;
    }

    load = (classes) => {
        this.classes = classes;
    }

    addClass = (className) => {
        this.addClasses([className]);
    }

    addClasses = (classesToAdd) => {
        for (var index = 0; index < classesToAdd.length; index++) {
            var classItem = classesToAdd[index];

            const exists = this.containsClass(classItem);
            if (!exists)
                this.classes.push(classItem);
        }
    }

    removeClass = (className) => {
        const index = this.containsIndex(className);
        if (index != -1) {
            this.classes.splice(index, 1);
        }
    }

    removeClasses = (classesToRemove) => {
        for (var index = 0; index < classesToRemove.length; index++) {
            var classItem = classesToRemove[index];
            this.removeClass(classItem);
        }
    }

    containsClass = (className) => {
        var findIndex = this.containsIndex(className);
        var exists = findIndex !== -1;
        return exists;
    }

    containsIndex = (className) => {
        for (var index = 0; index < this.classes.length; index++) {
            var classItem = this.classes[index];
            if (classItem == className)
                return index;
        }

        return -1;
    }
}


