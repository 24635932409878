import React from 'react';

export class CheckBoxInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: this.props.value
        };
    }

    getChecked = () => {
        return this.state.isChecked;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ isChecked: this.props.value });
        }
    }

    toggleCheckboxChange = () => {

        var isChecked = this.state.isChecked;
        var newValue = !isChecked;
        this.setState({ isChecked: newValue });

        var onChange = this.props.onCheckboxChange;
        onChange(newValue);
    }

    render() {
        const name = this.props.name;
        const value = this.props.valueName;
        const isChecked = this.state.isChecked;

        var wrapperClass = 'form-group';
        if (this.props.error && this.props.error.length > 0)
            wrapperClass += " " + 'has-error';

        var labelHtml = '';
        if (this.props.haslabel)
            labelHtml = (
                <label htmlFor={this.props.name}>{this.props.label}:</label>
            );

        const isHorizontalLayout = this.props.horizontalLayout
        const horizontalLayout = isHorizontalLayout ? 'horizontal-form-group' : '';
        const checkboxHorizontalPadding = isHorizontalLayout ? 'horizontal-check-margin' : '';

        let checkBoxControl =
            (
                <input className={checkboxHorizontalPadding} type="checkbox"
                    name={name}
                    value={value}
                    checked={isChecked}
                    onChange={this.toggleCheckboxChange}
                />
            );


        return (
            <div className={wrapperClass}>
                {!horizontalLayout && labelHtml}
                <div className={`field checkbox ${horizontalLayout}`}>
                    {horizontalLayout && <div className="mr-2">{labelHtml}</div>}
                    {checkBoxControl}
                </div>
                <div className="input">
                    {this.props.error}
                </div>
            </div>
        );
    }
}

