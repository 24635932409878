import React, { useState, useEffect, Fragment } from 'react'
import createPersistedState from 'use-persisted-state';

export const CacheBuster = ({ children }) => {
    const STORAGE_KEY = 'APP_VERSION';

    const [loading, setLoading] = useState(true);
    const useAppVersionState = createPersistedState(STORAGE_KEY);
    const [appVersion, setAppVersion] = useAppVersionState('');
    const [isLatestVersion, setIsLatestVersion] = useState(true);

    async function setVersion(version) {
        await setAppVersion(version);
    }

    const refreshCacheAndReload = async (version) => {
        if ('caches' in window) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(names => {
                for (const name of names) caches.delete(name);
            });
        }

        // clear browser cache and reload page (also update the version in the meta.json file)
        await setVersion(version).then(() =>
            window.location.reload(true)
        );
    };

    const metaUrl = `/meta.json?${new Date().getTime()}`;

    useEffect(() => {
        fetch(metaUrl, {
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    console.log('response: ' + JSON.stringify(response));
                    throw Error(response.statusText);
                }
                return response.json()
            })
            .then(meta => {
                const serverVersion = meta.version;
                const versionMatch = serverVersion === appVersion;
                
                if (!versionMatch) {
                    refreshCacheAndReload(serverVersion);
                } else {
                    setIsLatestVersion(true);
                    setLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
            })
    }, []);

    return (
        <Fragment>
            {children({ loading, isLatestVersion, refreshCacheAndReload })}
        </Fragment>
    );
}