import React, { Fragment, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import PurchaseReducer from "./purchaseReducer";
import PurchasePage from "./purchasePage";
import { PaymentApi } from "../../../api/paymentApi";
import {
    useBasketDispatch,
    hideBasket,
    showBasket,
    useBasketState
} from "../../../context/basketProvider";
import { CommonValidation } from "../../common/commonValidation/commonValidation";
import { ClientDateHandler } from "../../../classes/clientDateHandler";
import {
    productType as productTypeEnum,
    pageNames,
    paymentMethod as paymentMethodEnum
} from "../../../classes/enums";
import _ from "lodash";
import { ThemeContext } from "../../../context/themeContext";
import Confirmation from "./confirmation";
import { PolicyApi } from "../../../api/policyApi";
import { GoogleAnalytics } from "../../../classes/googleAnalytics";
import { showErrorToast } from "../../../classes/toast";
import { PriceHelper } from "../../../classes/priceHelper";
import { SelectInput } from '../../common/selectInput/selectInput';

const preferedPaymentDay = '31';

const initialState = {
    showSingleForm: false,
    showDirectDebitForm: false,
    showConfirmation: false,
    directDebit: {
        isAuthorised: false,
        accountHolderName: "",
        bankName: "",
        sortCode1: "",
        sortCode2: "",
        sortCode3: "",
        accountNumber: ""
    },
    directDebitErrors: {},
    loading: false,
    paymentError: "",
    policies: [],
    sagePayTransaction: {
        id: "",
        nextUrl: "",
        status: "",
        statusDetail: ""
    },
    paymentInProgress: false,
    selectedPreferredPaymentDay: preferedPaymentDay,
};

const ManagePurchasePage = ({
    vehicleDetails,
    basketProductsDistinct,
    basketProductsAll,
    customerDetails,
    businessPartnerName,
    brandName,
    previousPage,
    updatePolicies
}) => {
    const { dealerCode, googleAnalyticsEnable } = useContext(ThemeContext);

    const { paymentPageCustomisations } = useContext(ThemeContext);

    const basketDispatch = useBasketDispatch();
    const basketState = useBasketState();

    const [state, dispatch] = useReducer(PurchaseReducer, initialState);

    const {
        showSingleForm,
        showDirectDebitForm,
        directDebit,
        directDebitErrors,
        showConfirmation,
        loading,
        policies,
        paymentError,
        sagePayTransaction,
        paymentInProgress,
        selectedPreferredPaymentDay
    } = state;

    const onSinglePaymentClick = () => {
        dispatch({ type: "show-single-form", showSingleForm: true });
        setupSagePay();
    };

    const onDirectDebitPaymentClick = () => {
        dispatch({ type: "show-direct-debit-form", showDirectDebitForm: true });
    };

    const onDirectDebitChange = (e, key) => {
        const value = e.target.value;

        dispatch({ type: "update-direct-debit", key: key, value: value });
    };

    const onPaymentDayChange = (e) => {
        dispatch({ type: 'selected-preferred-payment-day', selectedPreferredPaymentDay: e.target.value });
    }

    const onSubmitDirectDebit = () => {
        var successCallback = data => {
            if (data.isSuccess) {
                registerPolicy(paymentMethodEnum.DIRECTDEBIT);
            } else {
                dispatch({ type: "update-loading", value: false });
                showErrorToast(data.text, "Direct Debit Validation", 3000);
            }
        };

        var failureCallback = error => {
            dispatch({ type: "update-loading", value: false });
            showErrorToast(error, "Direct Debit Validation", 3000);
        };

        if (validateDirectDebit() === true) {
            hideBasket(basketDispatch);
            dispatch({ type: "update-loading", value: true });
            new PaymentApi().validateDirectDebit(
                directDebit,
                basketState.bearerToken,
                successCallback,
                failureCallback
            );            
        }
    };

    const validateDirectDebit = () => {
        var isValid = true;
        var errors = {};

        if (!directDebit.isAuthorised) {
            errors.isAuthorised = "Please confirm that you are authorised to proceed";
            isValid = false;
        }

        if (CommonValidation.isEmpty(directDebit.accountHolderName)) {
            errors.accountHolderName = "Account holder name is required";
            isValid = false;
        }

        if (CommonValidation.isEmpty(directDebit.bankName)) {
            errors.bankName = "Bank or building society name is required";
            isValid = false;
        }

        if (
            CommonValidation.isEmpty(directDebit.sortCode1) ||
            CommonValidation.isEmpty(directDebit.sortCode2) ||
            CommonValidation.isEmpty(directDebit.sortCode3)
        ) {
            errors.sortCode = "Sort code is required";
            isValid = false;
        } else {
            const sortCodeLength = 6;
            const sortCode = directDebit.sortCode1.concat(
                directDebit.sortCode2,
                directDebit.sortCode3
            );

            if (!CommonValidation.isValidNumeric(sortCode)) {
                errors.sortCode = "Sort code is invalid";
                isValid = false;
            } else if (sortCode.length !== sortCodeLength) {
                errors.sortCode = `Sort codes must be ${sortCodeLength} digits long`;
                isValid = false;
            }
        }

        if (!CommonValidation.isEmpty(directDebit.accountNumber)) {
            if (!CommonValidation.isValidNumeric(directDebit.accountNumber)) {
                errors.accountNumber = "Account number is invalid";
                isValid = false;
            }
        } else {
            errors.accountNumber = "Account number is required";
            isValid = false;
        }
        if (CommonValidation.isEmpty(selectedPreferredPaymentDay) || SelectInput.isNullValue(selectedPreferredPaymentDay)) {
            isValid = false;
            errors.preferredPaymentDay = 'Preferred payment day is required';
        }

        dispatch({ type: "update-direct-debit-errors", directDebitErrors: errors });

        return isValid;
    };

    const policyRegistered = (data) => {

        if (data.errorMessage === "ABORT") {
            console.log("SAGEPAY - abort");
            resetPayment();
            return;
        }

        let errorMessage = undefined;

        if (data.isValid) {
            console.log("SAGEPAY - data is valid, policy registered");

            GoogleAnalytics.trackEventsGoogleAnalytics(
                "Button click",
                "Registered policy",
                `${brandName} - policy registered`,
                googleAnalyticsEnable
            );

            dispatch({ type: "update-policies", policies: data.products });

            const registeredPolicies = data.products;

            updatePolicies(registeredPolicies);

            GoogleAnalytics.setPageViewGoogleAnalytics(
                pageNames.SUCCESS,
                googleAnalyticsEnable,
                brandName
            );
        } else {
            console.log(`SAGEPAY - data is NOT valid, policy may not have registered`);
            if (CommonValidation.isEmpty(data.errorMessage)) {
                errorMessage = "An error has occurred, unable to register policy.";
            }
            else {
                errorMessage = data.errorMessage;
            }                

            updatePolicies([]);

            GoogleAnalytics.setPageViewGoogleAnalytics(
                pageNames.FAIL,
                googleAnalyticsEnable,
                brandName
            );
        }

        dispatch({
            type: "update-show-confirmation",
            showConfirmation: true,
            paymentError: errorMessage
        });
    };

    const registerPolicy = paymentMethod => {
        let errorMessage = undefined;

        var failureCallback = error => {
            console.log(`Failed to register policy: ${error}`);
            errorMessage = "An error has occurred, unable to register policy.";
            updatePolicies([]);

            dispatch({
                type: "update-show-confirmation",
                showConfirmation: true,
                paymentError: errorMessage
            });

            GoogleAnalytics.setPageViewGoogleAnalytics(
                pageNames.FAIL,
                googleAnalyticsEnable,
                brandName
            );
        };

        const policy = getPolicyDetails(paymentMethod);

        new PolicyApi().register(
            policy,
            basketState.bearerToken,
            policyRegistered,
            failureCallback
        );
    };

    const getPolicyDetails = paymentMethod => {
        let policy = {
            products: getProducts(paymentMethod),

            dealerNumber: dealerCode,

            vrn: vehicleDetails.registration,
            vin: vehicleDetails.vin,
            purchaseDistance: parseInt(vehicleDetails.currentMileage),
            vehiclePurchasePrice: getVehiclePurchasePrice(),
            dateFirstRegistration: mapJsonDateToServer(
                vehicleDetails.firstRegistrationDate
            ),
            vehiclePurchaseDate: mapJsonDateToServer(vehicleDetails.purchaseDate),
            glassModelID: vehicleDetails.glassModelID,
            glassQualID: vehicleDetails.glassQualID,

            title: customerDetails.title,
            firstName: customerDetails.firstName,
            lastName: customerDetails.surName,
            email: customerDetails.emailAddress,
            phoneHome: customerDetails.telephoneNumber,
            phoneMobile: customerDetails.telephoneNumber,
            phoneWork: customerDetails.telephoneNumber,
            addressLine1: customerDetails.addressLine1,
            addressLine2: customerDetails.addressLine2,
            cityTown: customerDetails.townOrCity,
            postalCode: customerDetails.postCode,
            policyStartDate: mapJsonDateToServer(customerDetails.policyStartDate),
            gapIntroductionDate:  mapJsonDateToServer(customerDetails.introductionDate),

            paymentMethod: paymentMethod,
            bankSortCode: isDirectDebit(paymentMethod)
                ? directDebit.sortCode1 + directDebit.sortCode2 + directDebit.sortCode3
                : "",
            accHolderName: isDirectDebit(paymentMethod)
                ? directDebit.accountHolderName
                : "",
            bankAccountNumber: isDirectDebit(paymentMethod)
                ? directDebit.accountNumber
                : "",
            bankName: isDirectDebit(paymentMethod) ? directDebit.bankName : "",
            PaymentReference: "",
            brand: brandName,
            customerJourneyId: basketState.customerJourneyId,
            preferredPaymentDay: parseInt(selectedPreferredPaymentDay)
        };

        return policy;
    };

    const getVehiclePurchasePrice = () => {
        let gapProducts = _.find(
            basketProductsAll,
            p => p.productTypeId === productTypeEnum.GAP
        );

        let vehiclePurchasePrice = CommonValidation.isNotEmpty(gapProducts)
            ? parseFloat(vehicleDetails.purchasePrice)
            : null;

        return vehiclePurchasePrice;
    };

    const getDirectDebitProducts = () => {
        let productsSelected = basketProductsAll.filter(p =>
            p.paymentMethods.includes(paymentMethodEnum.DIRECTDEBIT)
        );

        if (CommonValidation.isEmpty(productsSelected)) return [];

        let products = [];
        let priceHelper = new PriceHelper();
        let priceDetailsDirectDebit = {};

        for (var index = 0; index < productsSelected.length; index++) {
            priceDetailsDirectDebit = priceHelper.getPriceDetailsDirectDebit(
                productsSelected[index].price,
                productsSelected[index].noOfInstallments
            );

            var product = {
                customerCharge: parseFloat(productsSelected[index].price),
                inceptionDate: mapJsonDateToServer(customerDetails.policyStartDate),
                variantCode: productsSelected[index].warrantyVariantId,
                warrantyTierID: parseInt(productsSelected[index].warrantyTierId),
                duration: productsSelected[index].duration,
                totalPrice: parseFloat(priceDetailsDirectDebit.totalAmount),
                firstPayment: parseFloat(
                    priceDetailsDirectDebit.firstInstallmentAmount
                ),
                subsequentPayments: parseFloat(
                    priceDetailsDirectDebit.installmentAmount
                ),
                noOfInstallments: priceDetailsDirectDebit.noOfInstallments,
                websiteProductName: _.find(
                    basketProductsDistinct,
                    p => p.Id === productsSelected[index].productTypeId
                ).name
            };

            products.push(product);
        }

        return products;
    };

    const getSinglePaymentProducts = () => {
        let productsSelected = basketProductsAll.filter(p =>
            p.paymentMethods.includes(paymentMethodEnum.CREDITCARD)
        );

        if (CommonValidation.isEmpty(productsSelected)) return [];

        let products = [];

        for (var index = 0; index < productsSelected.length; index++) {
            var product = {
                customerCharge: parseFloat(productsSelected[index].price),
                inceptionDate: mapJsonDateToServer(customerDetails.policyStartDate),
                variantCode: productsSelected[index].warrantyVariantId,
                warrantyTierID: parseInt(productsSelected[index].warrantyTierId),
                duration: productsSelected[index].duration,
                totalPrice: parseFloat(productsSelected[index].price),
                firstPayment: parseFloat(0),
                subsequentPayments: parseFloat(0),
                noOfInstallments: parseInt(0),
                websiteProductName: _.find(
                    basketProductsDistinct,
                    p => p.Id === productsSelected[index].productTypeId
                ).name
            };

            products.push(product);
        }

        return products;
    };

    const getProducts = paymentMethod => {
        if (isDirectDebit(paymentMethod)) {
            return getDirectDebitProducts();
        } else {
            return getSinglePaymentProducts();
        }
    };

    const isDirectDebit = paymentMethod => {
        let paymentIsDirectDebit = paymentMethod === paymentMethodEnum.DIRECTDEBIT;
        return paymentIsDirectDebit;
    };

    const mapJsonDateToServer = dateJson => {
        var cdh = new ClientDateHandler();
        cdh.parseJSON(dateJson);

        var localDate = cdh.getLocalDate();
        return localDate;
    };

    const backClicked = () => {
        previousPage();
    };

    const setupSagePay = () => {
        const policy = getPolicyDetails(paymentMethodEnum.CREDITCARD);

        const successCallback = (data) => {

            console.log(`SagePay transaction ${JSON.stringify(data)}`);

            dispatch({
                type: "update-sagepay-transaction",
                sagePayTransactionId: data.vpsTxId,
                nextUrl: data.nextURL,
                status: data.status,
                statusDetail: data.statusDetail
            });

            hideBasket(basketDispatch);
            
        };

        const failureCallback = (error) => {
            console.log(`setupSagePay failureCallback hit error: ${JSON.stringify(error)}`);

            showErrorToast(
                "Failed to create a SagePay transaction",
                "SagePay Error",
                5000
            );

            resetPayment();
        };

        new PaymentApi().singlePayment(
            policy,
            basketState.bearerToken,
            successCallback,
            failureCallback
        );
    };

    const sagePayConfirm = () => {

        const failureCallback = (error) => {
            console.error(`SAGEPAY - confirm fail error: ${error}`);

            dispatch({
                type: "update-show-confirmation",
                showConfirmation: true,
                paymentError: `Failed to find the payment transaction id: ${sagePayTransaction.id}.`
            });
        };

        console.log(`SAGEPAY - confirm`);

        new PaymentApi().getSinglePaymentDetails(
            sagePayTransaction.id,
            basketState.bearerToken,
            policyRegistered,
            failureCallback
        );
    };

    const resetPayment = () => {
        showBasket(basketDispatch);
        dispatch({ type: "reset-payment-forms" });
    }

    const lookUpDays = [
        { "id": 1, "value": 1, "text": 1, "disabled": false },
        { "id": 2, "value": 2, "text": 2, "disabled": false },
        { "id": 3, "value": 3, "text": 3, "disabled": false },
        { "id": 4, "value": 4, "text": 4, "disabled": false },
        { "id": 5, "value": 5, "text": 5, "disabled": false },
        { "id": 6, "value": 6, "text": 6, "disabled": false },
        { "id": 7, "value": 7, "text": 7, "disabled": false },
        { "id": 8, "value": 8, "text": 8, "disabled": false },
        { "id": 9, "value": 9, "text": 9, "disabled": false },
        { "id": 10, "value": 10, "text": 10, "disabled": false },
        { "id": 11, "value": 11, "text": 11, "disabled": false },
        { "id": 12, "value": 12, "text": 12, "disabled": false },
        { "id": 13, "value": 13, "text": 13, "disabled": false },
        { "id": 14, "value": 14, "text": 14, "disabled": false },
        { "id": 15, "value": 15, "text": 15, "disabled": false },
        { "id": 16, "value": 16, "text": 16, "disabled": false },
        { "id": 17, "value": 17, "text": 17, "disabled": false },
        { "id": 18, "value": 18, "text": 18, "disabled": false },
        { "id": 19, "value": 19, "text": 19, "disabled": false },
        { "id": 20, "value": 20, "text": 20, "disabled": false },
        { "id": 21, "value": 21, "text": 21, "disabled": false },
        { "id": 22, "value": 22, "text": 22, "disabled": false },
        { "id": 23, "value": 23, "text": 23, "disabled": false },
        { "id": 24, "value": 24, "text": 24, "disabled": false },
        { "id": 25, "value": 25, "text": 25, "disabled": false },
        { "id": 26, "value": 26, "text": 26, "disabled": false },
        { "id": 27, "value": 27, "text": 27, "disabled": false },
        { "id": 28, "value": 28, "text": 28, "disabled": false },
        { "id": 29, "value": 29, "text": 29, "disabled": false },
        { "id": 30, "value": 30, "text": 30, "disabled": false },
        { "id": 31, "value": 31, "text": 31, "disabled": false }
    ];

    const viewPoint =
        showConfirmation && !loading ? (
            <Confirmation
                registrations={policies}
                brand={brandName}
                businessPartner={businessPartnerName}
                failureMessage={paymentError}
            />
        ) : (
                <PurchasePage
                    onSinglePaymentClick={onSinglePaymentClick}
                    onDirectDebitPaymentClick={onDirectDebitPaymentClick}
                    showSingleForm={showSingleForm}
                    showDirectDebitForm={showDirectDebitForm}
                    onSubmitDirectDebit={onSubmitDirectDebit}
                    onChangeDetail={onDirectDebitChange}
                    directDebitDetails={directDebit}
                    directDebitErrors={directDebitErrors}
                    basketProductsDistinct={basketProductsDistinct}
                    brandAdminText={paymentPageCustomisations.brandAdminText}
                    statementCompany={paymentPageCustomisations.statementCompany}
                    loading={loading}
                    backClicked={backClicked}
                    sagePayTransaction={sagePayTransaction}
                    sagePayRedirection={sagePayConfirm}
                    paymentInProgress={paymentInProgress}
                    resetPayment={resetPayment}
                    selectedPreferredPaymentDay={selectedPreferredPaymentDay}
                    onPaymentDayChange={onPaymentDayChange}
                    lookUpDays={lookUpDays}
                    preferedPaymentDay={preferedPaymentDay}
                />
            );

    return <Fragment>{viewPoint}</Fragment>;
};

export default ManagePurchasePage;

ManagePurchasePage.propTypes = {
    vehicleDetails: PropTypes.object.isRequired,
    basketProductDistinct: PropTypes.array.isRequired,
    customerDetails: PropTypes.object.isRequired,
    businessPartnerName: PropTypes.string.isRequired,
    brandName: PropTypes.string.isRequired,
    previousPage: PropTypes.func.isRequired,
    updatePolicies: PropTypes.func.isRequired
};
