import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { TextInput } from '../common/textInput/textInput';
import './sortCodeInput.scss';

export const SortCodeInput = ({ label, value1, value2, value3, error, onChange}) => {

    const [focusedElement, setFocusedElement] = useState(null);

    const getSortCodeLabelPanel = () => {
        let panel = '';

        if (label && label.length > 0) {
            panel = (
                <div className="d-block">
                    {label}
                </div>
            );
        }

        return panel;
    }


    const onChangeValue = (e, value) => {
        onChange(e, value);

        //Don't want to check for new focus if the section is not complete
        if (e.target.value.length < 2) return;

        switch (e.target.name) {
            case "sort-code-1":
                setFocusedElement("sort-code-2");
                break;
            case "sort-code-2":
                setFocusedElement("sort-code-3");
                break;
            default:
                setFocusedElement(null);
                break;
        }
    }

    const onLeave = (e, key) => {
        setFocusedElement(null);
    }

    const onFocus = (e) => {
        e.target.select();
    }

    const getSortCodeInputPanel = () => {

        let inputClasses = '';
        if (error && error.length > 0) {
            inputClasses = 'has-error'
        }

        return (
            <div className={inputClasses}>
                <div className="d-inline-block mr-1 sortcode">
                    <TextInput
                        placeholder="00"
                        name="sort-code-1"
                        onChange={(e) => onChangeValue(e, 'sortCode1')}
                        value={value1}
                        maxLength={2}
                        focus={focusedElement === 'sort-code-1'}
                        onLeave={onLeave}
                        onFocus={onFocus}
                    />
                </div>
                <div className="d-inline-block mr-1 sortcode">
                    <TextInput
                        placeholder="00"
                        name="sort-code-2"
                        onChange={(e) => onChangeValue(e, 'sortCode2')}
                        value={value2}
                        maxLength={2}
                        focus={focusedElement === 'sort-code-2'}
                        onLeave={onLeave}
                        onFocus={onFocus}
                    />
                </div>
                <div className="d-inline-block sortcode">
                    <TextInput
                        placeholder="00"
                        name="sort-code-3"
                        onChange={(e) => onChangeValue(e, 'sortCode3')}
                        value={value3}
                        maxLength={2}
                        focus={focusedElement === 'sort-code-3'}
                        onLeave={onLeave}
                        onFocus={onFocus}
                    />
                </div>
            </div>
        );
    }

    const getSortCodeErrorPanel = () => {
        let panel = '';

        if (error && error.length > 0) {
            panel = (
                <div className="has-error">
                    <div className="input">
                        {error}
                    </div>
                </div>
            );
        }

        return panel;
    }

    const labelPanel = getSortCodeLabelPanel();
    const inputPanel = getSortCodeInputPanel();
    const errorPanel = getSortCodeErrorPanel();

    return (
        <div className="mb-2">
            {labelPanel}
            {inputPanel}
            {errorPanel}
        </div>
    );
}

SortCodeInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    value1: PropTypes.string,
    value2: PropTypes.string,
    value3: PropTypes.string,
    error: PropTypes.string
}
