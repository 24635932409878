import { ApiHelper } from './apiHelper';

export class VehicleApi {

    loadVehicle(vehicleRegistrationNumber, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var query = encodeURI(`?vrn=${vehicleRegistrationNumber || ''}`);
        var dataUrl = '/api/vehicle/vehicle' + query;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }

    getVehicleTypes(firstRegistrationDate, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = `/api/vehicle/vehicle/${firstRegistrationDate}`;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }

    getVehicleMakes(firstRegistrationDate, vehicleTypeId, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = `/api/vehicle/vehicle/${firstRegistrationDate}/${vehicleTypeId}`;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }

    getVehicleModels(firstRegistrationDate, vehicleTypeId, vehicleMakeId, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = `/api/vehicle/vehicle/${firstRegistrationDate}/${vehicleTypeId}/${vehicleMakeId}`;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }

    getFuelTypes(firstRegistrationDate, vehicleTypeId, vehicleMakeId, vehicleModelId, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = `/api/vehicle/vehicle/${firstRegistrationDate}/${vehicleTypeId}/${vehicleMakeId}/${vehicleModelId}`;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }

    getEngineSizes(firstRegistrationDate, vehicleTypeId, vehicleMakeId, vehicleModelId, fuelTypeId, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = `/api/vehicle/vehicle/${firstRegistrationDate}/${vehicleTypeId}/${vehicleMakeId}/${vehicleModelId}/${fuelTypeId}`;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }

    getModelTrims(firstRegistrationDate, vehicleTypeId, vehicleMakeId, vehicleModelId, fuelTypeId, engineSize, bearerToken, callback = null, failureCallback = null) {
        const headers = {
            'Authorization': 'Bearer ' + bearerToken
        };

        var dataUrl = `/api/vehicle/vehicle/${firstRegistrationDate}/${vehicleTypeId}/${vehicleMakeId}/${vehicleModelId}/${fuelTypeId}/${engineSize}`;
        new ApiHelper().getDataWithHeaders(dataUrl, headers, callback, failureCallback);
    }
}