import React from "react";
import DatePicker from "react-datepicker";
import MediaQuery from 'react-responsive';
import { loadPopovers } from '../../classes/popOver';
import "react-datepicker/dist/react-datepicker.css"
import { CssClass } from './../../classes/cssClass';
import { ClientDateHandler } from '../../classes/clientDateHandler';
import { Portal } from 'react-overlays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal');

    return (
        <Portal container={el}>
            {children}
        </Portal>
    );
}

export class DateInput extends React.Component {

    componentDidMount() {
        loadPopovers();

        if (this.props.readonly)
            return;

        if (this.props.forceTimeReset) {

            let localDate = null;
            if ((this.props.useJson == true) && (typeof (this.props.value) == 'string'))
                localDate = this.getDateFromJSON(this.props.value);
            else
                localDate = this.props.value;

            const updatedDate = this.updateTime(localDate);

            const shouldUpdate = ((updatedDate != localDate) || (localDate == null));
            if (shouldUpdate) {
                this.callOnChange(updatedDate);
            }
        }
    }

    updateTime = (date) => {

        if ((date != null) && (typeof (date) == 'object')) {
            var hours = date.getHours();
            if (hours == 0) {
                date.setHours(12); // set to midday ..
            }
        }

        return date;
    }

    callOnChange = (date) => {

        let changeEvent = null;
        if (this.props.useJson == true) {
            var dateJson = this.setDateToJSON(date);
            changeEvent = { "target": { "value": dateJson } };
        }
        else {
            changeEvent = { "target": { "value": date } };
        }

        this.props.onDateChange(changeEvent);
    }

    onDateChange = (date) => {
        if (typeof (this.props.onDateChange) != 'function')
            return;

        if (this.props.forceTimeReset) {
            date = this.updateTime(date);
        }

        this.callOnChange(date);
    }

    getDateFromJSON = (jsonText) => {

        var cdh = new ClientDateHandler();
        cdh.parseJSON(jsonText);

        const date = cdh.getLocalDate();
        return date;
    }

    setDateToJSON = (date) => {
        var cdh = new ClientDateHandler();
        cdh.setDateValue(date, false);

        var json = cdh.getJSON();
        return json;
    }

    render() {
        let date = null;
        if (this.props.useJson == true) {
            const jsonDate = this.props.value;
            date = this.getDateFromJSON(jsonDate);
        }
        else {
            date = this.props.value;
        }            

        let wrapperClass = new CssClass();
        wrapperClass.load(CssClass.FromString('form-group'));

        if (this.props.error && this.props.error.length > 0)
            wrapperClass.addClass('has-error');

        var labelHtml = '';
        if (this.props.haslabel && this.props.haslabel == true)
            labelHtml = <label htmlFor={this.props.name}>{this.props.label}:</label>;



        let dateControl = (
            <div className="date-flex-width">
                <MediaQuery maxWidth={this.props.displayMinWidth}>
                    <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        todayButton={"Today"}
                        placeholderText={this.props.placeholder}
                        selected={date}
                        minDate={this.props.minimumDate}
                        maxDate={this.props.maximumDate}
                        onChange={this.onDateChange}
                        readOnly={this.props.readonly}
                        withPortal
                    />
                </MediaQuery>
                <MediaQuery minWidth={this.props.displayMinWidth + 1}>
                    <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        todayButton={"Today"}
                        placeholderText={this.props.placeholder}
                        selected={date}
                        minDate={this.props.minimumDate}
                        maxDate={this.props.maximumDate}
                        onChange={this.onDateChange}
                        readOnly={this.props.readonly}
                        popperContainer={CalendarContainer}
                    />
                </MediaQuery>
            </div>
        );

        let controlClasses = new CssClass();
        controlClasses.load(CssClass.FromArray(["field", "date"]));
        if (this.props.readonly)
            controlClasses.addClass("readonly");

        let appendBlock = '';
        if (this.props.help) {
            appendBlock = (<div className="input-group-append">
                <button id="popoverButton" type="button"
                    className={`btn btn-sm btn-primary input-height-fixed ${this.props.themeClass}`}
                    title={this.props.label}
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content={this.props.helpContent}>
                    <FontAwesomeIcon icon={faQuestion} />
                </button>
            </div>);
        }

        return (
            <div className={wrapperClass.asString()}>
                {labelHtml}
                <div className={controlClasses.asString()}>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            {dateControl}
                        </div>
                        {appendBlock}
                    </div>
                </div>
                <div className="input">
                    {this.props.error}
                </div>
            </div>
        );
    }
}

DateInput.defaultProps = {
    displayMinWidth: 400,
    minimumDate: Date.minDate,
    maximumDate: Date.maxDate,
};

